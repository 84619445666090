import { NotificationsActions, NotificationsActionTypes } from '../actions/notifications.actions';
import { Action } from '@ngrx/store';
import { Notification } from '../models/Notification.model';

export interface State {
  pullDate: Date,
  viewedDate: Date,
  notifications: Array<Notification>
}

export const initialState: State = {
  pullDate: null,
  viewedDate: null,
  notifications: null
};

export function reducer(state = initialState, action: NotificationsActions): State {
  switch (action.type) {
    case NotificationsActionTypes.PullNotificationsSuccessfull:
      return {
        ...state,
        pullDate: new Date(),
        notifications: action.payload
      };
    case NotificationsActionTypes.ViewedNotifications:
      return {
        ...state,
        viewedDate: new Date()
      };
    default:
      return state;
  }
}

export const selectNotifications = (state: State) => state;