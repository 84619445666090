import { Action } from '@ngrx/store';
import { Book } from '../models/Book.model';

export enum BooksActionTypes {
  PullBooksSuccessfull = 'PULL_BOOKS_SUCCESSFULL',
}

export class PullBooksSuccessfull implements Action {
  readonly type = BooksActionTypes.PullBooksSuccessfull;

  constructor(public payload: Array<Book>) { }
}

export type BooksActions =
  | PullBooksSuccessfull
