import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ApiResponse } from '../../../utilities/models/ApiResponse.model';
import { Book, PlatformBook } from './models/Book.model';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../state';
import { PullBooksSuccessfull } from './actions/books.actions';
import { Tag, TagOptions } from '../../../utilities/models/Filter.model';
import { SnackbarService } from '../../../utilities/snackbar.service';
import { take } from 'rxjs/operators';
import { REPLACEMENT_BOOK_TITLES } from './replacement-book-titles';
import { Expense } from './models/Expense.model';

@Injectable()
export class BookService {
  // API Routes
  private booksEndpoint = environment.apiUrl + 'books';
  private bookEndpoint = environment.apiUrl + 'book';
  private saveManyBookTagsEndpoint = environment.apiUrl + 'tags';
  // END API Routes

  constructor(
    private http: HttpClient,
    private store: Store<fromStore.State>,
    private snackbarService: SnackbarService
  ) { }

  /**
   * Returns promise with array of Books, also stores
   * this data in the store.
   */
  public getBooks(): Promise<Array<Book>> {
    return new Promise((resolve, reject) => {
      this.http
        .get<ApiResponse>(this.booksEndpoint)
        .subscribe((response) => {
          let books: Array<Book> = this.addCoverUrlsToBooks(response.data);

          if (localStorage.showReplacementBookTitles === 'true') {
            books = books.map((book: Book, index: number) => {
              return {
                ...book,
                title: REPLACEMENT_BOOK_TITLES[index % REPLACEMENT_BOOK_TITLES.length]
              }
            });
          }

          this.store.dispatch(new PullBooksSuccessfull(books));
          resolve(books);
        }, (err) => {
          reject(err);
        });
    });
  }

  public getBook(bookId: string): Promise<Book> {
    return new Promise((resolve, reject) => {
      this.http
        .get<ApiResponse>(this.bookEndpoint + '/' + bookId)
        .subscribe((response) => {
          resolve(response.data.book);
        }, (err) => {
          reject(err);
        });
    });
  }

    /**
   * Returns string array containing all unique titles from the array
   * of books.
   * @param books array of books to check
   */
  public getUniqueTitlesFromBooks(books: Array<Book>): Array<string> {
    let titles: Array<string> = [];

    for (let bookIndex = 0; bookIndex < books.length; bookIndex++) {
      let title = books[bookIndex].title;
      if (!titles.includes(title)) {
        titles.push(title);
      }
    }
    return titles;
  }

  /**
   * Returns string array containing all unique authors from the array
   * of books.
   * @param books array of books to check
   */
  public getUniqueAuthorsFromBooks(books: Array<Book>): Array<string> {
    let authors: Array<string> = [];

    for (let bookIndex = 0; bookIndex < books.length; bookIndex++) {
      let author = books[bookIndex].author;
      if (!authors.includes(author)) {
        authors.push(author);
      }
    }
    return authors;
  }

  public getUniqueSeriesFromBooks(books: Array<Book>): Array<string> {
    let uniqueSeries: Array<string> = [];

    for (let bookIndex = 0; bookIndex < books.length; bookIndex++) {
      let curBook:Book = books[bookIndex];
      let series:string;

      for (let tagIndex = 0; tagIndex < curBook.tags.length; tagIndex++) {
        let curTag: Tag = curBook.tags[tagIndex];
        if (curTag.name === 'series') {
          series = curTag.value;
          break;
        }
      }

      if (series && !uniqueSeries.includes(series)) {
        uniqueSeries.push(series);
      }
    }
    return uniqueSeries;
  }

  public getTagsFromBooks(books: Array<Book>): TagOptions {
    let tagOptions: TagOptions = {
      names: [],
      values: [],
      visible: []
    };

    for (let book of books) {
      for (let tag of book.tags) {
        if (tag.name !== 'series') {
          if (tagOptions.names.includes(tag.name)) {
            if (!tagOptions.values[tagOptions.names.indexOf(tag.name)].includes(tag.value)) {
              tagOptions.values[tagOptions.names.indexOf(tag.name)].push(tag.value);
            }
          } else {
            tagOptions.names.push(tag.name);
            tagOptions.values.push([tag.value]);
            tagOptions.visible.push(true);
          }
        }
      }
    }
    return tagOptions;
  }

  public getManualExpensesForBook(bookId: string): Promise<Array<Expense>> {
    return new Promise((resolve, reject) => {
      this.http
        .get<ApiResponse>(this.bookEndpoint + '/' + bookId + '/manualExpenses')
        .subscribe((response) => {
          resolve(response.data);
        }, (err) => {
          reject(err);
        });
    });
  }

  public getCoverUrlFromBook(book: Book): string {
    const sortPriority = {
      'amazon': 3,
      'applebooks': 2,
      'barnesandnoble': 1,
      'draft2digital': 0
    }
    // Set book cover
    const coverUrls = book.platformBooks.sort((a, b) => {
      return (sortPriority[b.platform] || 0) - (sortPriority[a.platform] || 0);
    }).map((platformBook) => {
      if (platformBook.platform === 'amazon') {
        return 'https://images-na.ssl-images-amazon.com/images/P/' + platformBook.platformIdentifier + '.jpg';
      } else if (platformBook.platform === 'barnesandnoble' || platformBook.platform == 'kobo') {
        return platformBook.coverUrl;
      } else if (platformBook.platform === 'applebooks') {
        return platformBook.coverUrl ? platformBook.coverUrl.replace('{w}', '200').replace('{h}', '200').replace('{f}', 'png') : undefined;
      } else if (platformBook.platform === 'draft2digital') {
        if (platformBook.subPlatforms.length == 0) {
          return undefined;
        }
        return platformBook.coverUrl;
      }
    }).filter((coverUrl) => {
      return coverUrl !== undefined;
    });
    return coverUrls[0] || this.getCoverFallback(book);
  }

  public getCoverFallback(book: Book): string {
    let charSum = 0;
    for (let char of book._id) {
      charSum += char.charCodeAt(0);
    }
    charSum = charSum % 5 + 1;
    return `/assets/book-covers/missing-cover-${charSum}.jpg`;
  }

  public addCoverUrlsToBooks(books: Array<Book>): Array<Book> {
    let outputBooks: Array<Book> = [];

    for (let bookIndex = 0; bookIndex < books.length; bookIndex++) {
      let book = {
        ...books[bookIndex]
      };

      book.coverUrl = this.getCoverUrlFromBook(book);

      outputBooks.push(book);
    }

    return outputBooks;
  }

  public updateBookTitleAndAuthor(updatedBook: Book): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http.post<ApiResponse>(`${this.bookEndpoint}/${updatedBook._id}`, {
        _id: updatedBook._id,
        title: updatedBook.title,
        author: updatedBook.author,
      }).subscribe((result) => {
        this.snackbarService.openSnackBar('Book updated', null, 5000);
        this.updateBookInStore(result.data.book);
        return resolve();
      }, (err) => {
        return reject();
      });
    })
  }

  public updateBookSeries(book: Book, newSeriesValue: string, newSeriesNumber: number): Promise<void> {
    return new Promise((resolve, reject) => {
      let seriesTag: Tag;
      let seriesNumberTag: Tag;
      for(let tagIndex = 0; tagIndex < book.tags.length; tagIndex++) {
        let curTag: Tag = book.tags[tagIndex];
        if (curTag.name === 'series') {
          curTag.value = newSeriesValue;
          seriesTag = curTag;
        } else if (curTag.name == 'series number') {
          curTag.value = `${newSeriesNumber}`;
          seriesNumberTag = curTag;
        }
      }
      if (seriesTag) {
        if (newSeriesValue && newSeriesValue.length > 0) {
          this.updateBookTag(seriesTag, book).then(() => {
            if (seriesNumberTag) {
              if (newSeriesNumber) {
                this.updateBookTag(seriesNumberTag, book).then(() => {
                  this.tagUpdatedSuccessfully('Series updated');
                  resolve();
                });
              } else {
                this.deleteBookTag(seriesNumberTag, book).then(() => {
                  this.tagUpdatedSuccessfully('Series updated');
                  resolve();
                });
              }
            } else {
              if (newSeriesNumber) {
                let newSeriesNumberTag: Tag = {
                  name: 'series number',
                  value: `${newSeriesNumber}`
                }
                this.saveBookTag(newSeriesNumberTag, book).then(() => {
                  this.tagUpdatedSuccessfully('Series updated');
                  resolve();
                });
              }
            }
          });
        } else {
          this.deleteBookTag(seriesTag, book).then(() => {
            if (seriesNumberTag) {
              this.deleteBookTag(seriesNumberTag, book).then(() => {
                this.tagUpdatedSuccessfully('Series updated');
                resolve();
              });
            } else {
              this.tagUpdatedSuccessfully('Series updated');
              resolve();
            }
          });
        }
      } else {
        if (newSeriesValue) {
          let newSeriesTag: Tag = {
            name: 'series',
            value: newSeriesValue
          }
          this.saveBookTag(newSeriesTag, book).then(() => {
            if (newSeriesNumber) {
              let newSeriesNumberTag: Tag = {
                name: 'series number',
                value: `${newSeriesNumber}`
              }
              this.saveBookTag(newSeriesNumberTag, book).then(() => {
                this.tagUpdatedSuccessfully('Series saved');
                resolve();
              });
            } else {
              this.tagUpdatedSuccessfully('Series saved');
              resolve();
            }
          });
        }
      }
    });
  }

  public saveBookTag(tag: Tag, book: Book, showSuccess?: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http.post<ApiResponse>(this.bookEndpoint + '/' + book._id + '/tag', tag).subscribe((result) => {
        if (showSuccess) {
          this.tagUpdatedSuccessfully('Tag saved');
        }
        this.updateBookInStore(result.data.book);
        resolve();
      });
    });
  }

  public updateBookTag(tag: Tag, book: Book, showSuccess?: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http.put<ApiResponse>(this.bookEndpoint + '/' + book._id + '/tag/' + tag._id, tag).subscribe((result) => {
        if (showSuccess) {
          this.tagUpdatedSuccessfully('Tag updated');
        }
        this.updateBookInStore(result.data.book);
        resolve();
      });
    });
  }

  public deleteBookTag(tag: Tag, book: Book, showSuccess?: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http.delete<ApiResponse>(this.bookEndpoint + '/' + book._id + '/tag/' + tag._id).subscribe((result) => {
        if (showSuccess) {
          this.tagUpdatedSuccessfully('Tag deleted');
        }
        this.updateBookInStore(result.data.book);
        resolve();
      });
    });
  }

  private tagUpdatedSuccessfully(message?: string) {
    this.snackbarService.openSnackBar(message, null, 5000);
  }

  private updateBookInStore(book: Book) {
    this.store.select(fromStore.selectBooks).pipe(take(1)).subscribe((state) => {
      let books: Array<Book> = state.books;

      for (let bookIndex = 0; bookIndex < books.length; bookIndex++) {
        if (book._id === books[bookIndex]._id) {

          // if api didn't return PlatformBook objects then use old ones
          if (book.platformBooks && typeof book.platformBooks[0] == 'string') {
            book.platformBooks = books[bookIndex].platformBooks;
          }

          books[bookIndex] = {
            ... book,
            coverUrl: this.getCoverUrlFromBook(book)
          };
        }
      }
      this.store.dispatch(new PullBooksSuccessfull(books));
    });
  }

  public saveManyBookTags(tagName: string, data): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http.post<ApiResponse>(this.saveManyBookTagsEndpoint + '/' + tagName, data).subscribe((result) => {
        return resolve();
      }, () => {
        return reject();
      });
    });
  }

  public saveBookExpense(expense: Expense, book: Book, showSuccess?: boolean): Promise<Array<Expense>> {
    return new Promise((resolve, reject) => {
      this.http.post<ApiResponse>(this.bookEndpoint + '/' + book._id + '/expense', expense).subscribe((result) => {
        if (showSuccess) {
          this.expenseUpdatedSuccessfully('Expense saved');
        }
        return resolve(result.data);
      }, () => {
        return reject();
      });
    });
  }

  public deleteBookExpense(expense: Expense, book: Book, showSuccess?: boolean): Promise<Array<Expense>> {
    return new Promise((resolve, reject) => {
      this.http.delete<ApiResponse>(this.bookEndpoint + '/' + book._id + '/expense/' + expense._id).subscribe((result) => {
        if (showSuccess) {
          this.tagUpdatedSuccessfully('Expense deleted');
        }
        return resolve(result.data);
      }, () => {
        return reject();
      });
    });
  }

  private expenseUpdatedSuccessfully(message?: string) {
    this.snackbarService.openSnackBar(message, null, 5000);
  }

  public matchPlatformBookWithBook(platformBook: PlatformBook, preventDataPull?: boolean): Promise<Book> {
    return new Promise((resolve) => {
      this.store.select(fromStore.selectBooks).pipe(take(1)).subscribe(async (state) => {
        let books = state.books;
        for (let book of books) {
          for (let platformBookCandidate of book.platformBooks) {
            if (platformBookCandidate._id === platformBook._id) {
              return resolve(book);
            }
          }
        }
        if (!preventDataPull) {
          await this.getBooks();
          return await this.matchPlatformBookWithBook(platformBook, true);
        }
      });
    })
  }

  public combineBooks(book1: Book, book2: Book): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http.post<ApiResponse>(this.bookEndpoint + '/' + book1._id + '/combine', {
        bookToCombine: book2._id
      }).subscribe((result) => {
        this.updateBookInStore(result.data.book);
        return resolve();
      }, () => {
        return reject();
      });
    })
  }
}
