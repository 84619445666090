<div class="banner subscription-banner" *ngIf="!hasSubscription">Your subscription has expired. <a class="button button-danger" routerLink="/account/settings">Update Here</a></div>
<div class="banner shared-access-banner" *ngIf="sharedAccessUser"><span>Viewing <b>{{ sharedAccessUser.email }}</b>&apos;s data</span> <button class="button" (click)="exitSharedAccess()">Exit</button></div>
<div class="nav">
  <div class="nav-container">
    <div class="nav-col brand">
      <a [routerLink]="reportsLink">PublishWide</a>
    </div>
    <div class="nav-col links links-center" *ngIf="isLimitedUser">
      <ul>
        <li>
          <a class="button button-no-contrast" [routerLink]="reportsLink" [routerLinkActive]="['active']">Platforms</a>
        </li>
      </ul>
    </div>
    <div class="nav-col links links-center" *ngIf="!isLimitedUser && (!isAgency || sharedAccessUser)">
      <ul>
        <li>
          <a class="button button-no-contrast" [routerLink]="reportsLink" [routerLinkActive]="['active']">Reports</a>
        </li>
        <li>
          <a class="button button-no-contrast" routerLink="/books/bookshelf" [routerLinkActive]="['active']">Bookshelf</a>
        </li>
        <li>
          <a class="button button-no-contrast" routerLink="/ads" [routerLinkActive]="['active']">Ads</a>
        </li>
      </ul>
    </div>
    <div class="nav-col links links-center" *ngIf="!isLimitedUser && isAgency && !sharedAccessUser">
      <ul>
        <li>
          <a class="button button-no-contrast" [routerLink]="reportsLink" [routerLinkActive]="['active']">Reports</a>
        </li>
        <li>
          <a class="button button-no-contrast" routerLink="/shared-access/overview" [routerLinkActive]="['active']">Shared Access</a>
        </li>
      </ul>
    </div>
    <div class="nav-col links links-right">
      <ul>
        <li class="user-dropdown-parent" *ngIf="isAgency">
          <button class="button button-no-contrast user-dropdown-toggle"
            (click)="toggleUserDropdown()"><i class="fas fa-users"></i></button>
          <div class="users-dropdown" *ngIf="userDropdownOpen">
            <input type="text" placeholder="User" [(ngModel)]="sharedAccessUserSearch" id="sharedAccessUserSearch" (keyup)="userSearchChange()" (change)="userSearchChange()" />
            <div class="user-list">
              <ng-container *ngIf="!sharedAccounts">
                <li class="no-users">
                  <div>Loading ...</div>
                </li>
              </ng-container>
              <ng-container *ngIf="sharedAccounts && sharedAccounts.length == 0">
                <li class="no-users">
                  <div>No Users</div>
                </li>
              </ng-container>
              <ng-container *ngIf="sharedAccounts && sharedAccounts.length > 0">
                <div class="user" *ngFor="let sharedAccount of sharedAccounts">
                  <div class="user-info">
                    <div class="name">{{ sharedAccount.email }}</div>
                    <div class="last-sync"*ngIf="sharedAccount.lastSalesSyncDate">Last Sync: {{ sharedAccount.lastSalesSyncDate | date:'short' }}</div>
                    <div class="last-sync"*ngIf="!sharedAccount.lastSalesSyncDate">No Recent Sync</div>
                  </div>
                  <button class="button" title="Switch User" (click)="quickSwitchUser(sharedAccount._id)">
                    <i class="fas fa-eye"></i>
                  </button>
                </div>
              </ng-container>
            </div>
            <div class="app-container-medium pagination-wrapper">
              <ng-container *ngIf="!(pageSizeSharedAccounts && pageSizeSharedAccounts == 10 && pageOptionsSharedAccounts && pageOptionsSharedAccounts.length == 1)">
                <div class="left"></div>
                <div class="pagination" *ngIf="pageOptionsSharedAccounts">
                  <button class="page" *ngFor="let page of pageOptionsSharedAccounts" [ngClass]="{'active': currentPageSharedAccounts == page, 'page-divider': page == '...'}"
                    (click)="setSharedAccountsPage(page)" [disabled]="showLoading || page == '...'">{{ page }}</button>
                  </div>
                <div class="right mode-toggle page-length">
                  <div class="option" *ngFor="let pageSizeOption of pageSizeOptions" (click)="setSharedAccountsPageSize(pageSizeOption)"
                    [ngClass]="{'active': pageSizeSharedAccounts == pageSizeOption}">{{ pageSizeOption }}</div>
                </div>
              </ng-container>
            </div>
          </div>
        </li>
        <li class="notification-dropdown-parent">
          <button class="button button-no-contrast notification-dropdown-toggle"
            (click)="toggleNotificationDropdown()"><i class="fas fa-bell"
              [ngClass]="{'animated tada': notifications.length > 0}"></i><span class="notification-count"
              *ngIf="notifications.length > 0">{{ notifications.length }}</span></button>
          <div class="notifications-dropdown" *ngIf="notificationDropdownOpen">
            <div class="dropdown-title">Notifications</div>
            <ul class="notifications">
              <ng-container *ngIf="notifications.length > 0">
                <li class="notification" *ngFor="let notification of notifications">
                  <div class="top">
                    <div class="title">{{ notification.title }}</div>
                    <div class="date">{{ notification.dateString }}</div>
                  </div>
                  <div class="middle">
                    <div class="message">{{ notification.message }}</div>
                  </div>
                  <div class="bottom">
                    <ng-container *ngIf="notification.link">
                      <ng-container *ngIf="notification.linkIsExternal">
                        <a class="button" target="{{ notification.target }}"
                          href="{{ notification.link }}">{{ notification.icon ? '<i class="'+notification.icon+'"></i>' : '' }}{{ notification.linkText }}</a>
                      </ng-container>
                      <ng-container *ngIf="!notification.linkIsExternal">
                        <a class="button" [ngClass]="{'has-icon': notification.icon}" target="{{ notification.target }}"
                          routerLink="{{ notification.link }}"><ng-container *ngIf="notification.icon"><i class="{{notification.icon}}"></i></ng-container>{{ notification.linkText }}</a>
                      </ng-container>
                    </ng-container>
                    <button class="button button-low-contrast" (click)="dismissNotification(notification._id)">Dismiss</button>
                  </div>
                </li>
              </ng-container>
              <ng-container *ngIf="notifications.length === 0">
                <li class="no-notification">
                  <div>No notifications</div>
                </li>
              </ng-container>
            </ul>
          </div>
        </li>
        <li class="dropdown-parent">
          <button class="button button-no-contrast" id="emailDropdown" (click)="toggleDropdown()"><span class="email">{{ user.name || user.email}}</span><span class="email-alternate"><i class="fas fa-user"></i></span>&nbsp;<i class="fas fa-angle-down"></i></button>
          <div class="dropdown" *ngIf="dropdownOpen">
            <ul>
              <li>
                <a routerLink="/account/settings">Settings</a>
              </li>
              <li *ngIf="isAgency">
                <a routerLink="/shared-access">Shared Access</a>
              </li>
              <li>
                <a routerLink="/account/install">Get the app</a>
              </li>
              <li>
                <a routerLink="/account/platforms" *ngIf="!isAgency">Platforms</a>
              </li>
              <li>
                <a routerLink="/income">Manual Income</a>
              </li>
              <li>
                <a [routerLink]="[ '/account/my-data' ]">My Data</a>
              </li>
              <li>
                <button (click)="logOut()">Log Out</button>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="dropdown-background" (click)="closeDropdowns()" *ngIf="dropdownOpen || notificationDropdownOpen || userDropdownOpen"></div>
<div class="private-router-outlet">
  <router-outlet></router-outlet>
</div>
<!-- <app-feedback></app-feedback> -->
<div class="footer">
  <div class="footer-container normal">
    <div class="footer-col">
      <div class="quick-stat" *ngIf="profit && hasSubscription && !isAgency && !sharedAccessUser">
        <div class="value">
          {{ profit }}
        </div>
        <div class="extra-info">
          profit - last sync: {{ lastSyncDateString }}
        </div>
      </div>
    </div>
    <div class="footer-col">
      
    </div>
    <div class="footer-col">
      <ul>
        <li>
          <a routerLink="/legal/terms">Terms</a>
        </li>
        <li>
          <a routerLink="/legal/privacy">Privacy</a>
        </li>
        <li>
          <div class="mode-toggle">
            <div class="option" [ngClass]="{'active': !lightMode}" (click)="setTheme(0)"><i class="fas fa-moon"></i>
            </div>
            <div class="option" [ngClass]="{'active': lightMode}" (click)="setTheme(1)"><i class="fas fa-sun"></i></div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="footer-container footer-nav">
    <div class="footer-col">
      <ul>
        <li>
          <a class="button button-no-contrast" [routerLink]="reportsLink" [routerLinkActive]="['active']">Reports</a>
        </li>
        <li>
          <a class="button button-no-contrast" routerLink="/books/bookshelf"
            [routerLinkActive]="['active']">Bookshelf</a>
        </li>
        <li>
          <a class="button button-no-contrast" routerLink="/ads" [routerLinkActive]="['active']">Ads</a>
        </li>
      </ul>
    </div>
  </div>
</div>