import { throwError as observableThrowError, Observable, interval, Subject, throwError, of, BehaviorSubject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { take, delay, tap, retryWhen, mergeMap, takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BookPerformance } from '../../../utilities/models/BookPerformance.model';
import { QuickStats } from '../quick-stats/models/quick-stats.model';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../state';
import { CurrencyService } from '../../../utilities/currency.service';
import { SnapshotData } from './snapshot/models/snapshot-data.model';
import { ApiResponse } from '../../../utilities/models/ApiResponse.model';
import { Book, PlatformBook } from '../books/models/Book.model';

import * as moment from 'moment';
import { UpdateQuickStats } from '../quick-stats/actions/quick-stats.actions';
import { ProfitReportData, DateTotal } from './profit/models/ProfitReportData.model';
import { resolve } from 'url';
import { isUndefined } from 'util';
import { DateService } from 'src/app/utilities/date.service';
import { BookExpense, SnapshotBookExpense, BOOK_EXPENSE_AD_TYPES, BookExpenseTypes } from 'src/app/utilities/models/BookExpense.model';
import { DateRange } from 'src/app/utilities/models/DateRange.model';
import { UserService } from '../user/user.service';
import { SnackbarService } from 'src/app/utilities/snackbar.service';
import { REPLACEMENT_BOOK_TITLES } from '../books/replacement-book-titles';
import { BookService } from '../books/book.service';
import { FacebookCampaign } from '../ads/facebook/models/FacebookCampaign.model';
import { AmazonCampaign, AmazonAd } from '../ads/amazon/models/amazonCampaign.model';
import { FacebookAd } from '../ads/facebook/models/FacebookAd.model';

interface CurrencyConversion {
  USDAUD?: Number;
  USDBRL?: Number;
  USDCAD?: Number;
  USDEUR?: Number;
  USDGBP?: Number;
  USDINR?: Number;
  USDJPY?: Number;
  USDMXN?: Number;
  USDPLN?: Number;
}

// ISO 3166 Alpha-2 codes and the ISO 4217 currency code
export enum MarketplaceCurrencies {
  US = 'USD',
  GB = 'GBP',
  UK = 'GBP',
  DE = 'EUR',
  FR = 'EUR',
  ES = 'EUR',
  IT = 'EUR',
  NL = 'EUR',
  JP = 'JPY',
  IN = 'INR',
  CA = 'CAD',
  BR = 'BRL',
  MX = 'MXN',
  AU = 'AUD',
  PL = 'PLN',
  CN = 'CNY',
  EG = 'EGP',
  SA = 'SAR',
  SG = 'SGD',
  SE = 'SEK',
  TR = 'TRY',
  AE = 'AED'
}

// ISO 3166 Alpha-2 codes and the respective amazon domain
export enum AmazonMarketplaces {
  US = 'Amazon.com',
  GB = 'Amazon.co.uk',
  DE = 'Amazon.de',
  FR = 'Amazon.fr',
  ES = 'Amazon.es',
  IT = 'Amazon.it',
  NL = 'Amazon.nl',
  JP = 'Amazon.co.jp',
  IN = 'Amazon.in',
  CA = 'Amazon.ca',
  BR = 'Amazon.com.br',
  MX = 'Amazon.com.mx',
  AU = 'Amazon.com.au',
  PL = 'Amazon.pl',
  CN = 'Amazon.cn',
  EG = 'Amazon.eg',
  SA = 'Amazon.sa',
  SG = 'Amazon.sg',
  SE = 'Amazon.se',
  TR = 'Amazon.tr',
  AE = 'Amazon.ae'
}

enum CountryCodesFromAmazonMarketplace {
  'Amazon.com' = 'US',
  'Amazon.co.uk' = 'GB',
  'Amazon.de' = 'DE',
  'Amazon.fr' = 'FR',
  'Amazon.es' = 'ES',
  'Amazon.it' = 'IT',
  'Amazon.nl' = 'NL',
  'Amazon.co.jp' = 'JP',
  'Amazon.in' = 'IN',
  'Amazon.ca' = 'CA',
  'Amazon.com.br' = 'BR',
  'Amazon.com.mx' = 'MX',
  'Amazon.com.au' = 'AU',
  'Amazon.pl' = 'PL',
  'Amazon.cn' = 'CN',
  'Amazon.eg' = 'EG',
  'Amazon.sa' = 'SA',
  'Amazon.sg' = 'SG',
  'Amazon.se' = 'SE',
  'Amazon.tr' = 'TR',
  'Amazon.ae' = 'AE'
}

@Injectable()
export class ReportsService implements OnDestroy {
  // API Routes
  private kdpReportsEndpoint = environment.apiUrl + 'reports/kdp';
  private profitReportEndpoint = environment.apiUrl + 'reports/profits';
  private booksEndpoint = environment.apiUrl + 'books';
  private adInsightsEndpoint = environment.apiUrl + 'ads/insights';
  private snapshotReportEndpoint = environment.apiUrl + 'reports/snapshot';
  private expensesEndpoint = environment.apiUrl + 'reports/expenses';

  private facebookReportEndpoint = environment.apiUrl + 'reports/facebook';
  private amazonReportEndpoint = environment.apiUrl + 'reports/amazon';

  private reportResultEndpoint = environment.apiUrl + 'reports/status';
  private kenpRateEndpoint = environment.apiUrl + 'kenpRate';
  // END API Routes

  private unsubscribe: Subject<void> = new Subject();

  private shouldRefreshDataSource = new BehaviorSubject<boolean>(false);

  public shouldRefreshData$ = this.shouldRefreshDataSource.asObservable();

  private kenpRateSettings: any;
  private targetCurrency: 'USD' | 'EUR' | 'CAD' | 'GBP' | 'INR' | 'JPY' | 'MXN' | 'BRL' | 'AUD' | 'PLN' | 'CNY' | 'EGP' | 'SAR' | 'SGD' | 'SEK' | 'TRY' | 'AED';
  private snapshotDateOption: 'today' | 'tomorrow' | 'yesterday';

  private currencyConversions: any;

  constructor(
    private http: HttpClient,
    private store: Store<fromStore.State>,
    private currency: CurrencyService,
    private dateService: DateService,
    private userService: UserService,
    private snackbarService: SnackbarService,
    private bookService: BookService
  ) {

    // default setting
    this.kenpRateSettings = {
      estimatedKenpRateType: 'MOST_RECENT',
    }

    this.currencyConversions = {};
    this.userService.getProfile().pipe(take(1)).subscribe((user) => {});
    this.userService.getUserStoreObservable().pipe(takeUntil(this.unsubscribe)).subscribe((user) => {
      if (user && user.settings.kenpRate) {
        this.kenpRateSettings = {
          estimatedKenpRateType: user.settings.kenpRate.estimatedKenpRateType,
          customGlobalRate: user.settings.kenpRate.customGlobalRate,
          customMarketplaceRates: user.settings.kenpRate.customMarketplaceRates,
          kenpAssumeIncreaseRate: user.settings.kenpRate.kenpAssumeIncreaseRate,
          kenpAssumeDecreaseRate: user.settings.kenpRate.kenpAssumeDecreaseRate
        }
      }
      this.targetCurrency = user.settings.currency || 'USD';
      localStorage.targetCurrency =  this.targetCurrency;
      this.snapshotDateOption = user.settings.snapshotDateOption;
    });
  }

  ngOnDestroy() {
    this.unsub();
  }

  /**
   * Unsubscribe to subscriptions
   */
  private unsub() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public getSnapshotData(query: any): Promise<SnapshotData> {
    return new Promise((resolve, reject) => {
      this.userService.getProfile().pipe(take(1)).subscribe((user) => {
        if (user && user.settings.kenpRate) {
          this.kenpRateSettings = {
            estimatedKenpRateType: user.settings.kenpRate.estimatedKenpRateType,
            customGlobalRate: user.settings.kenpRate.customGlobalRate,
            customMarketplaceRates: user.settings.kenpRate.customMarketplaceRates,
            kenpAssumeIncreaseRate: user.settings.kenpRate.kenpAssumeIncreaseRate,
            kenpAssumeDecreaseRate: user.settings.kenpRate.kenpAssumeDecreaseRate
          }
        }
        let dateOffset = 0;
        if (this.snapshotDateOption === 'yesterday') {
          dateOffset = -1;
        } else if (this.snapshotDateOption === 'tomorrow') {
          dateOffset = 1;
        }
        this.http
        .get<ApiResponse>(this.snapshotReportEndpoint, {
          params: {
            today: moment().add(dateOffset, 'days').format('YYYY-MM-DD'),
            yesterday: moment().subtract(1, 'days').add(dateOffset, 'days').format('YYYY-MM-DD')
          }
        }).pipe(take(1)).subscribe( async (result) => {
          if (!result.jobId) {
            reject('No jobId');
          }
          if (result.doingFbUpdate) {
            this.checkForFbSyncComplete();
          }
          let data;
          if (result.data) {
            data = result.data;
          } else {
            let queuedResult = await this.handleQueuedReportJob(result.jobId, 'snapshot');
            data = queuedResult.data;
          }

          this.prepareSnapshotData(data).then((result) => {

            result = this.roundKenpReadSnapshot(result);

            let today = {
              royalty: result.today.totals.totalRoyalty,
              spend: result.today.totals.spend,
              profit: result.today.totals.totalRoyalty - result.today.totals.spend,
              pages: result.today.totals.kenpcRead,
              units: result.today.totals.totalUnits
            }

            let yesterday = {
              royalty: result.yesterday.totals.totalRoyalty,
              spend: result.yesterday.totals.spend,
              profit: result.yesterday.totals.totalRoyalty - result.yesterday.totals.spend,
              pages: result.yesterday.totals.kenpcRead,
              units: result.yesterday.totals.totalUnits
            }

            this.updateQuickStats({
              syncDate: new Date(),
              today: {
                royalty: today.royalty,
                profit: today.profit,
                spend: today.spend,
                pages: today.pages,
                units: today.units
              },
              yesterday: {
                royalty: yesterday.royalty,
                profit: yesterday.profit,
                spend: yesterday.spend,
                pages: yesterday.pages,
                units: yesterday.units
              }
            });

            resolve(result);
          });
        }, (err) => {
          reject(err);
        });
      })
    });
  }

  private checkForFbSyncComplete() {
    this.snackbarService.openSnackBar('Facebook sync in progress. This page will refresh automatically.', null, 10000);
    this.userService.checkForFbSyncLockResolved().then(() => {
      this.userService.checkForFbSyncComplete().then(() => {
        this.snackbarService.openSnackBar('Facebook sync complete, refreshing data.', null, 10000);
        this.shouldRefreshDataSource.next(true);
      });
    });
  }

  public getProfitReportData(filters: any): Promise<any> {
    let filtersToSend = {
      ...filters
    }
    if (filtersToSend.dateRange) {
      filtersToSend.dateRange = filtersToSend.dateRange.toJSON()
    }
    return new Promise((resolve, reject) => {
      this.userService.getProfile().pipe(take(1)).subscribe((user) => {
        if (user && user.settings.kenpRate) {
          this.kenpRateSettings = {
            estimatedKenpRateType: user.settings.kenpRate.estimatedKenpRateType,
            customGlobalRate: user.settings.kenpRate.customGlobalRate,
            customMarketplaceRates: user.settings.kenpRate.customMarketplaceRates,
            kenpAssumeIncreaseRate: user.settings.kenpRate.kenpAssumeIncreaseRate,
            kenpAssumeDecreaseRate: user.settings.kenpRate.kenpAssumeDecreaseRate
          }
        }
        this.http
        .get<ApiResponse>(this.profitReportEndpoint, {
          params: {
            filters: JSON.stringify(filtersToSend)
          }
        }).pipe(take(1)).subscribe( async (result) => {
          if (!result.jobId) {
            reject('No jobId');
          }
          if (result.doingFbUpdate) {
            this.checkForFbSyncComplete();
          }
          let data;
          if (result.data) {
            data = result.data;
          } else {
            let queuedResult = await this.handleQueuedReportJob(result.jobId, 'profit');
            data = queuedResult.data;
          }
          this.prepareProfitReportData(data).then((result) => {
            result = this.roundProfitVariables(result);
            resolve(result);
          });
        }, (err) => {
          reject(err);
        });
      });
    });
  }

  /**
   * Returns an array of strings representing the
   * marketplaces
   */
  public getAmazonMarketplaces(): string[] {
    return Object.values(AmazonMarketplaces);
  }

  // getBlankFilters

  /**
   * Checks on the status of a job until the job
   * completes. Then returns the raw data from
   * the API.
   * @param jobId id of the report being processed
   */
  private handleQueuedReportJob(jobId: string, reportType: string): Promise<ApiResponse> {
    return new Promise((resolve, reject) => {
      if (!jobId || !reportType) {
        return reject();
      }
      this.http.get<ApiResponse>(this.reportResultEndpoint, {
        params: {
          jobId: jobId,
          reportType: reportType
        }
      }).pipe(
        delay(2000), // TODO: find way to change this delay dynamically. Delay should increase with number of requests
        mergeMap(val => {
          if (val.status === 'processing' || val.status === 'waiting') {
            return throwError('Error!');
          }

          return of(val);
        }),
        retryWhen(errors =>
          errors.pipe(delay(2000), take(150))
        ),
        takeUntil(this.unsubscribe),
      ).subscribe((result) => {
        resolve(result);
      }, (err) => {
        reject(err);
      });
    })
  }

  /**
   * Calculates royalties using currency conversions and kenprates
   * returns a pormise with processed data.
   * @param rawData data from api
   */
  private prepareSnapshotData(rawData: any): Promise<SnapshotData> {
    return new Promise(async (resolve, reject) => {
      let snapshotData: SnapshotData = {
        today: {
          totals: {
            freeOrders: 0,
            freeUnits: 0,
            kenpcRead: 0,
            kenpcRoyalty: 0,
            kindleCountdownRefunds: 0,
            kindleCountdownRoyalty: 0,
            kindleCountdownUnits: 0,
            paidOrders: 0,
            paperbackNet: 0,
            expandedDistributionChannelsNet: 0,
            kindleCountdownNet: 0,
            preOrderNet: 0,
            standardNet: 0,
            paperbackRefunds: 0,
            paperbackRoyalty: 0,
            paperbackUnits: 0,
            expandedDistributionChannelsRefunds: 0,
            expandedDistributionChannelsRoyalty: 0,
            expandedDistributionChannelsUnits: 0,
            preOrderRefunds: 0,
            preOrderRoyalty: 0,
            preOrderUnits: 0,
            standardRefunds: 0,
            standardRoyalty: 0,
            standardUnits: 0,
            totalRoyalty: 0,
            totalUnits: 0,
            spend: 0,
            profit: 0
          },
          bookPerformances: [],
          groupedData: {
            book: [],
            marketplace: [],
            geo: [],
            author: [],
            series: [],
            platform: []
          }
        },
        yesterday: {
          totals: {
            freeOrders: 0,
            freeUnits: 0,
            kenpcRead: 0,
            kenpcRoyalty: 0,
            kindleCountdownRefunds: 0,
            kindleCountdownRoyalty: 0,
            kindleCountdownUnits: 0,
            paidOrders: 0,
            paperbackNet: 0,
            expandedDistributionChannelsNet: 0,
            kindleCountdownNet: 0,
            preOrderNet: 0,
            standardNet: 0,
            paperbackRefunds: 0,
            paperbackRoyalty: 0,
            paperbackUnits: 0,
            expandedDistributionChannelsRefunds: 0,
            expandedDistributionChannelsRoyalty: 0,
            expandedDistributionChannelsUnits: 0,
            preOrderRefunds: 0,
            preOrderRoyalty: 0,
            preOrderUnits: 0,
            standardRefunds: 0,
            standardRoyalty: 0,
            standardUnits: 0,
            totalRoyalty: 0,
            totalUnits: 0,
            spend: 0,
            profit: 0
          },
          bookPerformances: []
        }
      }
      let spendSetFor : Array<string> = [];

      let bookIds: Array<string> = [];
      let marketplaces: Array<string> = [];
      let geos: Array<string> = [];
      let authors: Array<string> = [];
      let series: Array<string> = [];
      let platforms: Array<string> = [];

      // today
      for (let todaysBTIndex = 0; todaysBTIndex < rawData.todaysBookTotals.length; todaysBTIndex++) {
        let curBookTotal: BookPerformance = rawData.todaysBookTotals[todaysBTIndex];
        const books = rawData.books;

        const bookAndPlatformBook = await this.getBookAndPlatformBookFromPlatformBookId(curBookTotal._id.platformBook, books);
        curBookTotal._id.book = bookAndPlatformBook.book;
        curBookTotal._id.platformBook = bookAndPlatformBook.platformBook;

        // repalce book titles if enabled
        if (localStorage.showReplacementBookTitles === 'true') {
          curBookTotal._id.platformBook.title = REPLACEMENT_BOOK_TITLES[todaysBTIndex % REPLACEMENT_BOOK_TITLES.length];
        }

        let dateString = curBookTotal._id.date;
        let date = new Date(dateString);
        let marketplace = curBookTotal._id.marketplace;
        let currency = curBookTotal._id.currency ||  ['applebooks', 'draft2digital'].includes(curBookTotal._id.platformBook.platform) ? 'USD' : this.getCurrencyOfMarketplace(marketplace);
        let currencyConversion = this.getCurrencyConversionForDate(rawData.currencyConversions, date, currency);

        // Convert roaylties to USD
        curBookTotal.kenpcRoyalty = curBookTotal.kenpcRoyalty / currencyConversion;
        curBookTotal.kindleCountdownRoyalty = curBookTotal.kindleCountdownRoyalty / currencyConversion;
        curBookTotal.paperbackRoyalty = curBookTotal.paperbackRoyalty / currencyConversion;
        curBookTotal.expandedDistributionChannelsRoyalty = curBookTotal.expandedDistributionChannelsRoyalty / currencyConversion;
        curBookTotal.preOrderRoyalty = curBookTotal.preOrderRoyalty / currencyConversion;
        curBookTotal.standardRoyalty = curBookTotal.standardRoyalty / currencyConversion;

        // Calculate kenp royalites if needed
        if (curBookTotal.kenpcRead > 0 && curBookTotal.kenpcRoyalty <= 0) {
          let kenpRate = this.getKenpRateForDate(rawData.kenpRates, date, marketplace);
          curBookTotal.kenpcRoyalty = (curBookTotal.kenpcRead * kenpRate) / currencyConversion;
        }

        // Calculate net units
        curBookTotal.paperbackNet = curBookTotal.paperbackUnits - curBookTotal.paperbackRefunds;
        curBookTotal.expandedDistributionChannelsNet = curBookTotal.expandedDistributionChannelsUnits - curBookTotal.expandedDistributionChannelsRefunds;
        curBookTotal.kindleCountdownNet = curBookTotal.kindleCountdownUnits - curBookTotal.kindleCountdownRefunds;
        curBookTotal.preOrderNet = curBookTotal.preOrderUnits - curBookTotal.preOrderRefunds;
        curBookTotal.standardNet = curBookTotal.standardUnits - curBookTotal.standardRefunds;

        curBookTotal.totalRoyalty = curBookTotal.kenpcRoyalty
          + curBookTotal.kindleCountdownRoyalty
          + curBookTotal.paperbackRoyalty
          + curBookTotal.expandedDistributionChannelsRoyalty
          + curBookTotal.preOrderRoyalty
          + curBookTotal.standardRoyalty;

        curBookTotal.spend = 0;

        curBookTotal.profit = curBookTotal.totalRoyalty - curBookTotal.spend;

        curBookTotal.totalUnits = curBookTotal.standardNet + curBookTotal.paperbackNet + curBookTotal.expandedDistributionChannelsNet + curBookTotal.preOrderNet + curBookTotal.kindleCountdownNet;

        // Set totals
        snapshotData.today.totals = this.addBookPerformance(snapshotData.today.totals, curBookTotal);

        // Set book cover
        curBookTotal._id.platformBook.coverUrl = this.getBookCover(curBookTotal._id.book);

        // group book totals
        let bookIndex = bookIds.indexOf(curBookTotal._id.book._id);
        if (bookIndex === -1) {
          bookIds.push(curBookTotal._id.book._id);
          snapshotData.today.groupedData.book.push({
            ... curBookTotal,
            groupId: curBookTotal._id.book.title
          });
        } else {
          snapshotData.today.groupedData.book[bookIndex] = this.addBookPerformance(snapshotData.today.groupedData.book[bookIndex], curBookTotal);
        }


        // update marketplace name
        const countryCode = this.getCountryCodeOfMarketplace(curBookTotal._id.marketplace);
        switch (curBookTotal._id.platformBook.platform) {
          case 'amazon':
            curBookTotal._id.marketplace = `Amazon ${countryCode}`;
            break;
          case 'barnesandnoble':
            curBookTotal._id.marketplace = `Barnes & Noble ${curBookTotal._id.marketplace}`;
            break;
          case 'applebooks':
            curBookTotal._id.marketplace = `Apple Books ${curBookTotal._id.marketplace}`;
            break;
        }

        // group marketplace totals
        let marketplaceIndex = marketplaces.indexOf(curBookTotal._id.marketplace);
        if (marketplaceIndex === -1) {
          marketplaces.push(curBookTotal._id.marketplace);
          snapshotData.today.groupedData.marketplace.push({
            ...curBookTotal,
            groupId: curBookTotal._id.marketplace
          });
        } else {
          snapshotData.today.groupedData.marketplace[marketplaceIndex] = this.addBookPerformance(snapshotData.today.groupedData.marketplace[marketplaceIndex], curBookTotal);
        }

        let geoIndex = geos.indexOf(countryCode);
        if (geoIndex === -1) {
          geos.push(countryCode);
          snapshotData.today.groupedData.geo.push({
            ...curBookTotal,
            groupId: countryCode
          });
        } else {
          snapshotData.today.groupedData.geo[geoIndex] = this.addBookPerformance(snapshotData.today.groupedData.geo[geoIndex], curBookTotal);
        }

        // group author totals
        let authorIndex = authors.indexOf(curBookTotal._id.book.author);
        if (authorIndex === -1) {
          authors.push(curBookTotal._id.book.author);
          snapshotData.today.groupedData.author.push({
            ...curBookTotal,
            groupId: curBookTotal._id.book.author
          });
        } else {
          snapshotData.today.groupedData.author[authorIndex] = this.addBookPerformance(snapshotData.today.groupedData.author[authorIndex], curBookTotal);
        }

        // group platform totals
        let platformIndex = platforms.indexOf(curBookTotal._id.platformBook.platform);
        if (platformIndex === -1) {
          platforms.push(curBookTotal._id.platformBook.platform);
          let platformTitle = '';
          switch (curBookTotal._id.platformBook.platform) {
            case 'amazon':
              platformTitle = 'Amazon KDP';
              break;
            case 'applebooks':
              platformTitle = 'Apple Books';
              break;
            case 'barnesandnoble':
              platformTitle = 'Barnes & Noble';
              break;
            case 'draft2digital':
              platformTitle = 'Draft2Digital';
              break;
            case 'kobo':
              platformTitle = 'Kobo';
              break;
          }
          snapshotData.today.groupedData.platform.push({
            ...curBookTotal,
            groupId: platformTitle
          });
        } else {
          snapshotData.today.groupedData.platform[platformIndex] = this.addBookPerformance(snapshotData.today.groupedData.platform[platformIndex], curBookTotal);
        }

        // group series totals
        let seriesName = '';
        for (let tagIndex = 0; tagIndex < curBookTotal._id.book.tags.length; tagIndex++) {
          if (curBookTotal._id.book.tags[tagIndex].name === 'series') {
            seriesName = curBookTotal._id.book.tags[tagIndex].value;
          }
        }
        let seriesIndex = series.indexOf(seriesName);
        if (seriesIndex === -1) {
          series.push(seriesName);
          snapshotData.today.groupedData.series.push({
            ...curBookTotal,
            groupId: seriesName
          });
        } else {
          snapshotData.today.groupedData.series[seriesIndex] = this.addBookPerformance(snapshotData.today.groupedData.series[seriesIndex], curBookTotal);
        }
      }

      snapshotData.today.bookPerformances = rawData.todaysBookTotals;

      spendSetFor = [];

      // yesterday
      for (let yesterdaysBTIndex = 0; yesterdaysBTIndex < rawData.yesterdaysBookTotals.length; yesterdaysBTIndex++) {
        let curBookTotal: BookPerformance = rawData.yesterdaysBookTotals[yesterdaysBTIndex];
        const books = rawData.books;

        const bookAndPlatformBook = await this.getBookAndPlatformBookFromPlatformBookId(curBookTotal._id.platformBook, books);
        curBookTotal._id.book = bookAndPlatformBook.book;
        curBookTotal._id.platformBook = bookAndPlatformBook.platformBook;

        // repalce book titles if enabled
        if (localStorage.showReplacementBookTitles === 'true') {
          curBookTotal._id.platformBook.title = REPLACEMENT_BOOK_TITLES[yesterdaysBTIndex % REPLACEMENT_BOOK_TITLES.length];
        }

        let dateString = curBookTotal._id.date;
        let date = new Date(dateString);
        let marketplace = curBookTotal._id.marketplace;
        let currency = ['applebooks', 'draft2digital'].includes(curBookTotal._id.platformBook.platform) ? 'USD' : this.getCurrencyOfMarketplace(marketplace);
        let currencyConversion = this.getCurrencyConversionForDate(rawData.currencyConversions, date, currency);

        // Convert roaylties to USD
        curBookTotal.kenpcRoyalty = curBookTotal.kenpcRoyalty / currencyConversion;
        curBookTotal.kindleCountdownRoyalty = curBookTotal.kindleCountdownRoyalty / currencyConversion;
        curBookTotal.paperbackRoyalty = curBookTotal.paperbackRoyalty / currencyConversion;
        curBookTotal.expandedDistributionChannelsRoyalty = curBookTotal.expandedDistributionChannelsRoyalty / currencyConversion;
        curBookTotal.preOrderRoyalty = curBookTotal.preOrderRoyalty / currencyConversion;
        curBookTotal.standardRoyalty = curBookTotal.standardRoyalty / currencyConversion;

        // Calculate kenp royalites if needed
        if (curBookTotal.kenpcRead > 0 && curBookTotal.kenpcRoyalty <= 0) {
          let kenpRate = this.getKenpRateForDate(rawData.kenpRates, date, marketplace);
          curBookTotal.kenpcRoyalty = (curBookTotal.kenpcRead * kenpRate) / currencyConversion;
        }

        // Calculate net units
        curBookTotal.paperbackNet = curBookTotal.paperbackUnits - curBookTotal.paperbackRefunds;
        curBookTotal.expandedDistributionChannelsNet = curBookTotal.expandedDistributionChannelsUnits - curBookTotal.expandedDistributionChannelsRefunds;
        curBookTotal.kindleCountdownNet = curBookTotal.kindleCountdownUnits - curBookTotal.kindleCountdownRefunds;
        curBookTotal.preOrderNet = curBookTotal.preOrderUnits - curBookTotal.preOrderRefunds;
        curBookTotal.standardNet = curBookTotal.standardUnits - curBookTotal.standardRefunds;

        curBookTotal.totalRoyalty = curBookTotal.kenpcRoyalty
          + curBookTotal.kindleCountdownRoyalty
          + curBookTotal.paperbackRoyalty
          + curBookTotal.expandedDistributionChannelsRoyalty
          + curBookTotal.preOrderRoyalty
          + curBookTotal.standardRoyalty;

        curBookTotal.spend = 0;

        curBookTotal.profit = curBookTotal.totalRoyalty - curBookTotal.spend;

        curBookTotal.totalUnits = curBookTotal.standardNet + curBookTotal.paperbackNet + curBookTotal.expandedDistributionChannelsNet + curBookTotal.preOrderNet + curBookTotal.kindleCountdownNet;

        // Set spend
        for (let expenseIndex = 0; expenseIndex < rawData.yesterdaysBookExpenses.length; expenseIndex++) {
          let curBookExpense: BookExpense = rawData.yesterdaysBookExpenses[expenseIndex];
          if (curBookExpense.book && curBookTotal._id.book._id === curBookExpense.book._id && !spendSetFor.includes(curBookExpense.book._id)) {
            let dateDivider = this.dateService.dateDiff(new Date(curBookExpense.dateEnd), new Date(curBookExpense.dateStart)) + 1;
            let currencyConversion = 1;
            if (curBookExpense.currency) {
              currencyConversion = this.getCurrencyConversionForDate(rawData.currencyConversions, date, curBookExpense.currency);
            }
            let spend = (curBookExpense.spend / currencyConversion) / dateDivider;

            curBookTotal.spend = spend;
            if (curBookExpense.type === BookExpenseTypes.FACEBOOK || curBookExpense.type === BookExpenseTypes.AMAZON_ADS) {
              curBookTotal.adSpend = spend;
              curBookTotal.impressions = curBookExpense.impressions;
              curBookTotal.clicks = curBookExpense.outboundClicks || curBookExpense.clicks;
              curBookTotal.cpc = curBookTotal.clicks === 0 ? 0 : curBookTotal.adSpend / curBookTotal.clicks
            }
            spendSetFor.push(curBookExpense.book._id);
          }
        }

        // Set totals
        snapshotData.yesterday.totals = this.addBookPerformance(snapshotData.yesterday.totals, curBookTotal);
      }

      snapshotData.yesterday.bookPerformances = rawData.yesterdaysBookTotals;

      // total expenses including non book assigned
      snapshotData.today.totals.spend = 0;
      snapshotData.yesterday.totals.spend = 0;

      for (let bookExpense of rawData.todaysBookExpenses) {
        if (bookExpense.type == BookExpenseTypes.AMAZON_ADS_V2) {
          bookExpense.type = BookExpenseTypes.AMAZON_ADS;
        }

        if (bookExpense.type === BookExpenseTypes.AMAZON_ADS) {
          if (!bookExpense.amsCampaign) {
            const campaignAndAd = this.getAmazonCampaignAndAd(bookExpense, rawData.amazonCampaigns);
            bookExpense.amazonCampaign = campaignAndAd.campaign;
            bookExpense.amazonAd = campaignAndAd.ad;

            bookExpense.book = this.getBookFromBookId((bookExpense.amazonAd ? bookExpense.amazonAd.assignedBook : bookExpense.amazonCampaign.assignedBook), rawData.books);

            bookExpense.country = bookExpense?.amazonAd?.marketplace;
          }
        } else if (bookExpense.type === BookExpenseTypes.FACEBOOK) {
          const campaignAndAd = this.getFacebookCampaignAndAd(bookExpense, rawData.facebookCampaigns);
          bookExpense.facebookCampaign = campaignAndAd.campaign;
          bookExpense.facebookAd = campaignAndAd.ad;

          bookExpense.book = this.getBookFromBookId(bookExpense.facebookAd.assignedBook, rawData.books);
        } else if (bookExpense.type === BookExpenseTypes.MANUAL) {
          bookExpense.book = this.getBookFromBookId(bookExpense.book, rawData.books);
        }

        let dateDivider = this.dateService.dateDiff(new Date(bookExpense.dateEnd), new Date(bookExpense.dateStart)) + 1
        let date = new Date(bookExpense.dateStart);
        let currencyConversion = 1;
        if (bookExpense.currency) {
          currencyConversion = this.getCurrencyConversionForDate(rawData.currencyConversions, date, bookExpense.currency);
        } else if (bookExpense.amazonCampaign && bookExpense.amazonCampaign.marketplace) {
          currencyConversion = this.getCurrencyConversionForDate(rawData.currencyConversions, date, MarketplaceCurrencies[bookExpense.amazonCampaign.marketplace]);
        }

        let spend = (bookExpense.spend / currencyConversion) / dateDivider;
        let impressions = bookExpense.impressions;
        let clicks = bookExpense.outboundClicks || bookExpense.clicks;
        let adSpend = 0;
        if (BOOK_EXPENSE_AD_TYPES.includes(bookExpense.type)) {
          adSpend = spend;
        }
        let spendBySource = {
          [bookExpense.type]: spend
        }

        if (bookExpense.book) {
          for (let platformBook of bookExpense.book.platformBooks) {
            platformBook.platformIdentifier = platformBook.platformIdentifier.trim();
            if (platformBook.platformIdentifier.length === 10) {
              bookExpense.book.platformBook = platformBook;
              bookExpense.book.platformBook.coverUrl = this.getBookCover(bookExpense.book);
            } else {
            }
          }
        }

        let expenseBookTotal = {
          freeOrders: 0,
          freeUnits: 0,
          kenpcRead: 0,
          kenpcRoyalty: 0,
          kindleCountdownRefunds: 0,
          kindleCountdownRoyalty: 0,
          kindleCountdownUnits: 0,
          paidOrders: 0,
          paperbackRefunds: 0,
          paperbackRoyalty: 0,
          paperbackUnits: 0,
          expandedDistributionChannelsRefunds: 0,
          expandedDistributionChannelsRoyalty: 0,
          expandedDistributionChannelsUnits: 0,
          preOrderRefunds: 0,
          preOrderRoyalty: 0,
          preOrderUnits: 0,
          standardRefunds: 0,
          standardRoyalty: 0,
          paperbackNet: 0,
          expandedDistributionChannelsNet: 0,
          kindleCountdownNet: 0,
          preOrderNet: 0,
          standardNet: 0,
          standardUnits: 0,
          totalRoyalty: 0,
          totalUnits: 0,
          spend: spend,
          profit: 0 - spend,
          adSpend: adSpend,
          impressions: impressions,
          clicks: clicks,
          cpc: clicks === 0 ? 0 : adSpend / clicks,
          facebookSpend: 0,
          facebookImpressions: 0,
          facebookClicks: 0,
          facebookCpc: 0,
          amazonAdsSpend: 0,
          amazonAdsImpressions: 0,
          amazonAdsClicks: 0,
          amazonAdsCpc: 0,
          manualSpend: 0,
          spendBySource: spendBySource,
          _id: {
            book: bookExpense.book,
            platformBook: bookExpense.book ? bookExpense.book.platformBook : undefined,
            country: bookExpense.country
          }
        };

        switch (bookExpense.type) {
          case 'facebook':
            expenseBookTotal.facebookSpend = spend;
            expenseBookTotal.facebookImpressions = impressions;
            expenseBookTotal.facebookClicks = clicks;
            expenseBookTotal.facebookCpc = clicks === 0 ? 0 : adSpend / clicks;
            break;
          case 'amazonads':
            expenseBookTotal.amazonAdsSpend = spend;
            expenseBookTotal.amazonAdsImpressions = impressions;
            expenseBookTotal.amazonAdsClicks = clicks;
            expenseBookTotal.amazonAdsCpc = clicks === 0 ? 0 : adSpend / clicks;
            break;
          case 'manual':
            expenseBookTotal.manualSpend = spend;
            break;
        }

        snapshotData.today.totals = this.addBookPerformance(snapshotData.today.totals, expenseBookTotal);

        if (expenseBookTotal._id.country) {
          let geoIndex = geos.indexOf(expenseBookTotal._id.country);
          if (geoIndex === -1) {
            geos.push(expenseBookTotal._id.country);
            snapshotData.today.groupedData.geo.push({
              ...expenseBookTotal,
              groupId: expenseBookTotal._id.country
            });
          } else {
            snapshotData.today.groupedData.geo[geoIndex] = this.addBookPerformance(snapshotData.today.groupedData.geo[geoIndex], expenseBookTotal);
          }
        }

        // group book totals
        let bookIndex = bookIds.indexOf(expenseBookTotal?._id?.book?._id || '0');
        if (bookIndex === -1) {
          bookIds.push(expenseBookTotal?._id?.book?._id || '0');
          snapshotData.today.groupedData.book.push({
            ...expenseBookTotal,
            groupId: expenseBookTotal?._id?.book?.title || 'Unassigned Ads'
          });
        } else {
          snapshotData.today.groupedData.book[bookIndex] = this.addBookPerformance(snapshotData.today.groupedData.book[bookIndex], expenseBookTotal);
        }
        // group author totals
        let authorIndex = authors.indexOf(expenseBookTotal?._id?.book?.author || '0');
        if (authorIndex === -1) {
          authors.push(expenseBookTotal?._id?.book?.author || '0');
          snapshotData.today.groupedData.author.push({
            ...expenseBookTotal,
            groupId: expenseBookTotal?._id?.book?.author || 'Unassigned Ads'
          });
        } else {
          snapshotData.today.groupedData.author[authorIndex] = this.addBookPerformance(snapshotData.today.groupedData.author[authorIndex], expenseBookTotal);
        }

        // group series totals
        let seriesName = '';
        if (expenseBookTotal._id.book && expenseBookTotal._id.book._id) {
          for (let tagIndex = 0; tagIndex < expenseBookTotal._id.book.tags.length; tagIndex++) {
            if (expenseBookTotal._id.book.tags[tagIndex].name === 'series') {
              seriesName = expenseBookTotal._id.book.tags[tagIndex].value;
            }
          }
        }
        if (!bookExpense.book) {
          seriesName = 'Unassigned Ads';
        }
        let seriesIndex = series.indexOf(seriesName);
        if (seriesIndex === -1) {
          series.push(seriesName);
          snapshotData.today.groupedData.series.push({
            ...expenseBookTotal,
            groupId: seriesName
          });
        } else {
          snapshotData.today.groupedData.series[seriesIndex] = this.addBookPerformance(snapshotData.today.groupedData.series[seriesIndex], expenseBookTotal);
        }
      }

      for (let bookExpense of rawData.yesterdaysBookExpenses) {
        if (bookExpense.type == BookExpenseTypes.AMAZON_ADS_V2) {
          bookExpense.type = BookExpenseTypes.AMAZON_ADS;
        }
        if (bookExpense.type === BookExpenseTypes.AMAZON_ADS) {
          if (!bookExpense.amsCampaign) {
            const campaignAndAd = this.getAmazonCampaignAndAd(bookExpense, rawData.amazonCampaigns);
            bookExpense.amazonCampaign = campaignAndAd.campaign;
            bookExpense.amazonAd = campaignAndAd.ad;

            bookExpense.book = this.getBookFromBookId((bookExpense.amazonAd ? bookExpense.amazonAd.assignedBook : bookExpense.amazonCampaign.assignedBook), rawData.books);

            bookExpense.country = bookExpense?.amazonAd?.marketplace;
          }
        }

        let dateDivider = this.dateService.dateDiff(new Date(bookExpense.dateEnd), new Date(bookExpense.dateStart)) + 1;
        let date = new Date(bookExpense.dateStart);
        let currencyConversion = 1;
        if (bookExpense.currency) {
          currencyConversion = this.getCurrencyConversionForDate(rawData.currencyConversions, date, bookExpense.currency);
        } else if (bookExpense.amazonCampaign && bookExpense.amazonCampaign.marketplace) {
          currencyConversion = this.getCurrencyConversionForDate(rawData.currencyConversions, date, MarketplaceCurrencies[bookExpense.amazonCampaign.marketplace]);
        }
        snapshotData.yesterday.totals.spend += (bookExpense.spend / currencyConversion) / dateDivider;
      }

      // set total profit
      snapshotData.today.totals.profit = snapshotData.today.totals.totalRoyalty - snapshotData.today.totals.spend;

      return resolve(snapshotData);
    });
  }

  private prepareProfitReportData(rawData: any): Promise<ProfitReportData> {
    return new Promise(async (resolve, reject) => {
      let reportData: ProfitReportData = {
        totals: {
          freeOrders: 0,
          freeUnits: 0,
          kenpcRead: 0,
          kenpcRoyalty: 0,
          kindleCountdownRefunds: 0,
          kindleCountdownRoyalty: 0,
          kindleCountdownUnits: 0,
          paidOrders: 0,
          paperbackRefunds: 0,
          paperbackRoyalty: 0,
          paperbackUnits: 0,
          expandedDistributionChannelsRefunds: 0,
          expandedDistributionChannelsRoyalty: 0,
          expandedDistributionChannelsUnits: 0,
          preOrderRefunds: 0,
          preOrderRoyalty: 0,
          preOrderUnits: 0,
          standardRefunds: 0,
          standardRoyalty: 0,
          standardUnits: 0,
          totalRoyalty: 0,
          totalUnits: 0,
          paperbackNet: 0,
          expandedDistributionChannelsNet: 0,
          kindleCountdownNet: 0,
          preOrderNet: 0,
          standardNet: 0,
          spend: 0,
          profit: 0,
          adSpend: 0,
          impressions: 0,
          clicks: 0,
          cpc: 0,
          facebookSpend: 0,
          facebookImpressions: 0,
          facebookClicks: 0,
          facebookCpc: 0,
          amazonAdsSpend: 0,
          amazonAdsImpressions: 0,
          amazonAdsClicks: 0,
          amazonAdsCpc: 0,
          manualSpend: 0,
          amazonAdsData: {
            orders: 0,
            pages: 0,
            unitsSold: 0,
            pagesRoyalty: 0
          }
        },
        bookPerformances: [],
        groupedData: {
          book: [],
          marketplace: [],
          author: [],
          series: [],
          date: [],
          geo: []
        },
        expenses: {
          [BookExpenseTypes.FACEBOOK]: [],
          [BookExpenseTypes.AMAZON_ADS]: [],
          [BookExpenseTypes.MANUAL]: []
        }
      }

      let bookIds: Array<string> = [];
      let marketplaces: Array<string> = [];
      let authors: Array<string> = [];
      let series: Array<string> = [];
      let geos: Array<string> = [];

      let dateRange = new DateRange(rawData.dateRange.start.substring(0, 10), rawData.dateRange.end.substring(0, 10));
      const books = rawData.books;

      // book totals
      for (let bookTotalIndex = 0; bookTotalIndex < rawData.bookTotals.length; bookTotalIndex++) {
        let curBookTotal: BookPerformance = rawData.bookTotals[bookTotalIndex];

        if (curBookTotal?._id?.marketplace.includes('CreateSpace ')) {
          curBookTotal._id.marketplace = curBookTotal._id.marketplace.replace('CreateSpace ', '');
        }

        const bookAndPlatformBook = await this.getBookAndPlatformBookFromPlatformBookId(curBookTotal._id.platformBook, books);
        curBookTotal._id.book = bookAndPlatformBook.book;
        curBookTotal._id.platformBook = bookAndPlatformBook.platformBook;

        // repalce book titles if enabled
        if (localStorage.showReplacementBookTitles === 'true') {
          curBookTotal._id.platformBook.title = REPLACEMENT_BOOK_TITLES[bookTotalIndex % REPLACEMENT_BOOK_TITLES.length];
        }

        let date = new Date(curBookTotal._id.year, curBookTotal._id.month - 1, 1);
        let marketplace = curBookTotal._id.marketplace;
        let currency = curBookTotal._id.currency || ['applebooks', 'draft2digital'].includes(curBookTotal._id.platformBook.platform) ? 'USD' : this.getCurrencyOfMarketplace(marketplace);
        let currencyConversion = this.getCurrencyConversionForDate(rawData.currencyConversions, date, currency);

        // Convert roaylties to USD
        curBookTotal.kenpcRoyalty = curBookTotal.kenpcRoyalty / currencyConversion;
        curBookTotal.kindleCountdownRoyalty = curBookTotal.kindleCountdownRoyalty / currencyConversion;
        curBookTotal.paperbackRoyalty = curBookTotal.paperbackRoyalty / currencyConversion;
        curBookTotal.expandedDistributionChannelsRoyalty = curBookTotal.expandedDistributionChannelsRoyalty / currencyConversion;
        curBookTotal.preOrderRoyalty = curBookTotal.preOrderRoyalty / currencyConversion;
        curBookTotal.standardRoyalty = curBookTotal.standardRoyalty / currencyConversion;

        // Calculate kenp royalites if needed
        if (curBookTotal.kenpcRead > 0 && curBookTotal.kenpcRoyalty <= 0) {
          let kenpRate = this.getKenpRateForDate(rawData.kenpRates, date, marketplace);
          curBookTotal.kenpcRoyalty = (curBookTotal.kenpcRead * kenpRate) / currencyConversion;
        }

        // Calculate net units
        curBookTotal.paperbackNet = curBookTotal.paperbackUnits - curBookTotal.paperbackRefunds;
        curBookTotal.expandedDistributionChannelsNet = curBookTotal.expandedDistributionChannelsUnits - curBookTotal.expandedDistributionChannelsRefunds;
        curBookTotal.kindleCountdownNet = curBookTotal.kindleCountdownUnits - curBookTotal.kindleCountdownRefunds;
        curBookTotal.preOrderNet = curBookTotal.preOrderUnits - curBookTotal.preOrderRefunds;
        curBookTotal.standardNet = curBookTotal.standardUnits - curBookTotal.standardRefunds;

        curBookTotal.totalRoyalty = curBookTotal.kenpcRoyalty
          + curBookTotal.kindleCountdownRoyalty
          + curBookTotal.paperbackRoyalty
          + curBookTotal.expandedDistributionChannelsRoyalty
          + curBookTotal.preOrderRoyalty
          + curBookTotal.standardRoyalty;

        curBookTotal.spend = 0;

        curBookTotal.profit = curBookTotal.totalRoyalty - curBookTotal.spend;

        curBookTotal.totalUnits = curBookTotal.standardNet + curBookTotal.paperbackNet + curBookTotal.expandedDistributionChannelsNet + curBookTotal.preOrderNet + curBookTotal.kindleCountdownNet;

        // Set totals
        reportData.totals.freeOrders += curBookTotal.freeOrders;
        reportData.totals.freeUnits += curBookTotal.freeUnits;
        reportData.totals.kenpcRead += curBookTotal.kenpcRead;
        reportData.totals.kenpcRoyalty += curBookTotal.kenpcRoyalty;
        reportData.totals.kindleCountdownRefunds += curBookTotal.kindleCountdownRefunds;
        reportData.totals.kindleCountdownRoyalty += curBookTotal.kindleCountdownRoyalty;
        reportData.totals.kindleCountdownUnits += curBookTotal.kindleCountdownUnits;
        reportData.totals.paidOrders += curBookTotal.paidOrders;
        reportData.totals.paperbackRefunds += curBookTotal.paperbackRefunds;
        reportData.totals.paperbackRoyalty += curBookTotal.paperbackRoyalty;
        reportData.totals.paperbackUnits += curBookTotal.paperbackUnits;
        reportData.totals.expandedDistributionChannelsRefunds += curBookTotal.expandedDistributionChannelsRefunds;
        reportData.totals.expandedDistributionChannelsRoyalty += curBookTotal.expandedDistributionChannelsRoyalty;
        reportData.totals.expandedDistributionChannelsUnits += curBookTotal.expandedDistributionChannelsUnits;
        reportData.totals.preOrderRefunds += curBookTotal.preOrderRefunds;
        reportData.totals.preOrderRoyalty += curBookTotal.preOrderRoyalty;
        reportData.totals.preOrderUnits += curBookTotal.preOrderUnits;
        reportData.totals.standardRefunds += curBookTotal.standardRefunds;
        reportData.totals.standardRoyalty += curBookTotal.standardRoyalty;
        reportData.totals.standardUnits += curBookTotal.standardUnits;
        reportData.totals.totalRoyalty += curBookTotal.totalRoyalty;
        reportData.totals.totalUnits += curBookTotal.totalUnits;
        reportData.totals.spend += curBookTotal.spend;

        reportData.totals.paperbackNet += curBookTotal.paperbackNet;
        reportData.totals.expandedDistributionChannelsNet += curBookTotal.expandedDistributionChannelsNet;
        reportData.totals.kindleCountdownNet += curBookTotal.kindleCountdownNet;
        reportData.totals.preOrderNet += curBookTotal.preOrderNet;
        reportData.totals.standardNet += curBookTotal.standardNet;
        
        reportData.totals.profit = reportData.totals.totalRoyalty - reportData.totals.spend;

        // Set book cover
        curBookTotal._id.platformBook.coverUrl = this.getBookCover(curBookTotal._id.book);

        reportData.bookPerformances.push(curBookTotal);

        // group book totals
        let bookIndex = bookIds.indexOf(curBookTotal._id.book._id);
        if (bookIndex === -1) {
          bookIds.push(curBookTotal._id.book._id);
          reportData.groupedData.book.push({
            ...curBookTotal,
            groupId: curBookTotal._id.book.title
          });
        } else {
          reportData.groupedData.book[bookIndex] = this.addBookPerformance(reportData.groupedData.book[bookIndex], curBookTotal);
        }

        // group author totals
        const countryCode = this.getCountryCodeOfMarketplace(curBookTotal._id.marketplace);
        let geoIndex = geos.indexOf(countryCode);
        if (geoIndex === -1) {
          geos.push(countryCode);
          reportData.groupedData.geo.push({
            ...curBookTotal,
            groupId: countryCode
          });
        } else {
          reportData.groupedData.geo[geoIndex] = this.addBookPerformance(reportData.groupedData.geo[geoIndex], curBookTotal);
        }

        // update marketplace name
        switch (curBookTotal._id.platformBook.platform) {
          case 'amazon':
            curBookTotal._id.marketplace = `Amazon ${countryCode}`;
            break;
          case 'barnesandnoble':
            curBookTotal._id.marketplace = `Barnes & Noble ${curBookTotal._id.marketplace}`;
            break;
          case 'applebooks':
            curBookTotal._id.marketplace = `Apple Books ${curBookTotal._id.marketplace}`;
            break;
        }

        // group marketplace totals
        let marketplaceIndex = marketplaces.indexOf(curBookTotal._id.marketplace);
        if (marketplaceIndex === -1) {
          marketplaces.push(curBookTotal._id.marketplace);
          reportData.groupedData.marketplace.push({
            ...curBookTotal,
            groupId: curBookTotal._id.marketplace
          });
        } else {
          reportData.groupedData.marketplace[marketplaceIndex] = this.addBookPerformance(reportData.groupedData.marketplace[marketplaceIndex], curBookTotal);
        }

        // group author totals
        let authorIndex = authors.indexOf(curBookTotal._id.platformBook.author);
        if (authorIndex === -1) {
          authors.push(curBookTotal._id.book.author);
          reportData.groupedData.author.push({
            ...curBookTotal,
            groupId: curBookTotal._id.book.author
          });
        } else {
          reportData.groupedData.author[authorIndex] = this.addBookPerformance(reportData.groupedData.author[authorIndex], curBookTotal);
        }

        // group series totals
        let seriesName = '';
        for (let tagIndex = 0; tagIndex < curBookTotal._id.book.tags.length; tagIndex++) {
          if (curBookTotal._id.book.tags[tagIndex].name === 'series') {
            seriesName = curBookTotal._id.book.tags[tagIndex].value;
          }
        }
        let seriesIndex = series.indexOf(seriesName);
        if (seriesIndex === -1) {
          series.push(seriesName);
          reportData.groupedData.series.push({
            ...curBookTotal,
            groupId: seriesName
          });
        } else {
          reportData.groupedData.series[seriesIndex] = this.addBookPerformance(reportData.groupedData.series[seriesIndex], curBookTotal);
        }
      }
      // END Book Totals

      // Date Totals
      let dates: Array<number> = [];

      for (let dateTotalIndex = 0; dateTotalIndex < rawData.dateTotals.length; dateTotalIndex++) {
        let curDateTotal: BookPerformance = rawData.dateTotals[dateTotalIndex];
        let dateString = curDateTotal._id.date;
        let date = new Date(dateString);

        let marketplace = curDateTotal._id.marketplace;
        let currency = curDateTotal._id.currency || ['applebooks', 'draft2digital'].includes(curDateTotal._id.platform) ? 'USD' : this.getCurrencyOfMarketplace(marketplace);
        let currencyConversion = this.getCurrencyConversionForDate(rawData.currencyConversions, date, currency);

        // Convert roaylties to USD
        curDateTotal.kenpcRoyalty = curDateTotal.kenpcRoyalty / currencyConversion;
        curDateTotal.kindleCountdownRoyalty = curDateTotal.kindleCountdownRoyalty / currencyConversion;
        curDateTotal.paperbackRoyalty = curDateTotal.paperbackRoyalty / currencyConversion;
        curDateTotal.expandedDistributionChannelsRoyalty = curDateTotal.expandedDistributionChannelsRoyalty / currencyConversion;
        curDateTotal.preOrderRoyalty = curDateTotal.preOrderRoyalty / currencyConversion;
        curDateTotal.standardRoyalty = curDateTotal.standardRoyalty / currencyConversion;

        // Calculate kenp royalites if needed
        if (curDateTotal.kenpcRead > 0 && curDateTotal.kenpcRoyalty <= 0) {
          let kenpRate = this.getKenpRateForDate(rawData.kenpRates, date, marketplace);
          curDateTotal.kenpcRoyalty = (curDateTotal.kenpcRead * kenpRate) / currencyConversion;
        }

        curDateTotal.totalRoyalty = curDateTotal.kenpcRoyalty
          + curDateTotal.kindleCountdownRoyalty
          + curDateTotal.paperbackRoyalty
          + curDateTotal.expandedDistributionChannelsRoyalty
          + curDateTotal.preOrderRoyalty
          + curDateTotal.standardRoyalty;

        curDateTotal.spend = 0;

        curDateTotal.profit = curDateTotal.totalRoyalty - curDateTotal.spend;

        // Calculate net units
        curDateTotal.paperbackNet = curDateTotal.paperbackUnits - curDateTotal.paperbackRefunds;
        curDateTotal.expandedDistributionChannelsNet = curDateTotal.expandedDistributionChannelsUnits - curDateTotal.expandedDistributionChannelsRefunds;
        curDateTotal.kindleCountdownNet = curDateTotal.kindleCountdownUnits - curDateTotal.kindleCountdownRefunds;
        curDateTotal.preOrderNet = curDateTotal.preOrderUnits - curDateTotal.preOrderRefunds;
        curDateTotal.standardNet = curDateTotal.standardUnits - curDateTotal.standardRefunds;

        curDateTotal.totalUnits = curDateTotal.standardNet + curDateTotal.paperbackNet + curDateTotal.expandedDistributionChannelsNet + curDateTotal.preOrderNet + curDateTotal.kindleCountdownNet;

        curDateTotal.spendBySource = {
          [BookExpenseTypes.FACEBOOK]: 0,
          [BookExpenseTypes.AMAZON_ADS]: 0,
          [BookExpenseTypes.MANUAL]: 0
        };

        // group date totals
        let dateIndex = dates.indexOf(date.getTime());
        if (dateIndex === -1) {
          dates.push(date.getTime());
          reportData.groupedData.date.push({
            ...curDateTotal,
            groupId: date.toISOString()
          });
        } else {
          reportData.groupedData.date[dateIndex] = this.addBookPerformance(reportData.groupedData.date[dateIndex], curDateTotal);
        }
      }
      // END Date Totals

      // Expenses
      let expenseIndexes = {
        [BookExpenseTypes.FACEBOOK]: [],
        [BookExpenseTypes.AMAZON_ADS]: [],
        [BookExpenseTypes.MANUAL]: []
      };
      for (let bookExpenseIndex = 0; bookExpenseIndex < rawData.bookExpenses.length; bookExpenseIndex++) {
        let curBookExpense: BookExpense = rawData.bookExpenses[bookExpenseIndex];

        if (curBookExpense.type == BookExpenseTypes.AMAZON_ADS_V2) {
          curBookExpense.type = BookExpenseTypes.AMAZON_ADS;
        }

        if (curBookExpense.type === BookExpenseTypes.AMAZON_ADS) {
          if (!curBookExpense.amsCampaign) {
            const campaignAndAd = this.getAmazonCampaignAndAd(curBookExpense, rawData.amazonCampaigns);
            curBookExpense.amazonCampaign = campaignAndAd.campaign;
            curBookExpense.amazonAd = campaignAndAd.ad;

            curBookExpense.book = this.getBookFromBookId((curBookExpense.amazonAd ? curBookExpense.amazonAd.assignedBook : curBookExpense.amazonCampaign.assignedBook), rawData.books);

            curBookExpense.country = curBookExpense?.amazonAd?.marketplace;
          }
        } else if (curBookExpense.type === BookExpenseTypes.FACEBOOK) {
          const campaignAndAd = this.getFacebookCampaignAndAd(curBookExpense, rawData.facebookCampaigns);
          curBookExpense.facebookCampaign = campaignAndAd.campaign;
          curBookExpense.facebookAd = campaignAndAd.ad;

          curBookExpense.book = this.getBookFromBookId(curBookExpense.facebookAd.assignedBook, rawData.books);
        } else if (curBookExpense.type === BookExpenseTypes.MANUAL) {
          curBookExpense.book = this.getBookFromBookId((curBookExpense.book as any), rawData.books);
        }

        let dateDivider = this.dateService.dateDiff(new Date(curBookExpense.dateEnd), new Date(curBookExpense.dateStart)) + 1;

        let date = new Date(curBookExpense.dateStart);
        let currencyConversion = 1;
        if (curBookExpense.currency) {
          currencyConversion = this.getCurrencyConversionForDate(rawData.currencyConversions, date, curBookExpense.currency);
        } else if (curBookExpense.amazonCampaign && curBookExpense.amazonCampaign.marketplace) {
          currencyConversion = this.getCurrencyConversionForDate(rawData.currencyConversions, date, MarketplaceCurrencies[curBookExpense.amazonCampaign.marketplace]);
        }

        let spend = (curBookExpense.spend / currencyConversion) / dateDivider;
        let clicks = (curBookExpense.outboundClicks || curBookExpense.clicks) / dateDivider;
        let impressions = curBookExpense.impressions / dateDivider;
        let adSpend = 0;

        curBookExpense.spend = spend;
        curBookExpense.clicks = clicks;
        curBookExpense.impressions = impressions;

        if (BOOK_EXPENSE_AD_TYPES.includes(curBookExpense.type)) {
          adSpend = spend;

          // ams pages royalty
          if (curBookExpense.type === BookExpenseTypes.AMAZON_ADS) {
            let kenpRate = this.getKenpRateForDate(rawData.kenpRates, date, AmazonMarketplaces[curBookExpense.amsCampaign ? curBookExpense.amsCampaign.marketplace : curBookExpense.amazonCampaign ? curBookExpense.amazonCampaign.marketplace : curBookExpense.amazonAd.marketplace]);
            curBookExpense.amazonAdsData.pagesRoyalty = (curBookExpense.amazonAdsData.pages * kenpRate) / currencyConversion;
          }
          // END ams pages royalty

          // group expenses
          let expenseIndex = expenseIndexes[curBookExpense.type].indexOf(curBookExpense.parent);
          if (expenseIndex === -1) {
            let groupId;
            if (curBookExpense.type === BookExpenseTypes.FACEBOOK) {
              groupId = curBookExpense.facebookAd.name;
            } else if (curBookExpense.type === BookExpenseTypes.AMAZON_ADS) {
              groupId = curBookExpense.amsCampaign ? curBookExpense.amsCampaign.name : curBookExpense.amazonAd ? curBookExpense.amazonAd.name : curBookExpense.amazonCampaign.name;
            }
            expenseIndexes[curBookExpense.type].push(curBookExpense.parent);
            reportData.expenses[curBookExpense.type].push({
              ...curBookExpense,
              groupId: groupId
            });
          } else {
            reportData.expenses[curBookExpense.type][expenseIndex] = {
              ...this.addBookExpense(reportData.expenses[curBookExpense.type][expenseIndex], curBookExpense),
              groupId: curBookExpense.name
            };
          }
        }

        let spendBySource = {
          [curBookExpense.type]: spend
        }

        const dateStart = new Date(curBookExpense.dateStart);
        for (let dateIndex = 0; dateIndex < dateDivider; dateIndex++) {
          let date = new Date(Date.UTC(dateStart.getUTCFullYear(), dateStart.getUTCMonth(), dateStart.getUTCDate() + dateIndex, 0, 0, 0, 0));

          // only include expense if it is within the dateRange
          if (dateRange.includes(date)) {

            let expenseBookTotal = {
              freeOrders: 0,
              freeUnits: 0,
              kenpcRead: 0,
              kenpcRoyalty: 0,
              kindleCountdownRefunds: 0,
              kindleCountdownRoyalty: 0,
              kindleCountdownUnits: 0,
              paidOrders: 0,
              paperbackRefunds: 0,
              paperbackRoyalty: 0,
              paperbackUnits: 0,
              expandedDistributionChannelsRefunds: 0,
              expandedDistributionChannelsRoyalty: 0,
              expandedDistributionChannelsUnits: 0,
              preOrderRefunds: 0,
              preOrderRoyalty: 0,
              preOrderUnits: 0,
              standardRefunds: 0,
              standardRoyalty: 0,
              paperbackNet: 0,
              expandedDistributionChannelsNet: 0,
              kindleCountdownNet: 0,
              preOrderNet: 0,
              standardNet: 0,
              standardUnits: 0,
              totalRoyalty: 0,
              totalUnits: 0,
              spend: spend,
              profit: 0 - spend,
              adSpend: adSpend,
              impressions: impressions,
              clicks: clicks,
              cpc: clicks === 0 ? 0 : adSpend / clicks,
              spendBySource: spendBySource,
              facebookSpend: 0,
              facebookImpressions: 0,
              facebookClicks: 0,
              facebookCpc: 0,
              amazonAdsSpend: 0,
              amazonAdsImpressions: 0,
              amazonAdsClicks: 0,
              amazonAdsCpc: 0,
              manualSpend: 0,
              _id: {
                book: curBookExpense.book,
                country: curBookExpense.country
              },
              amazonAdsData: {
                orders: curBookExpense.amazonAdsData ? curBookExpense.amazonAdsData.orders : 0,
                pages: curBookExpense.amazonAdsData ? curBookExpense.amazonAdsData.pages : 0,
                pagesRoyalty: curBookExpense.amazonAdsData ? curBookExpense.amazonAdsData.pagesRoyalty : 0,
                unitsSold: curBookExpense.amazonAdsData ? curBookExpense.amazonAdsData.unitsSold : 0
              }
            };

            switch (curBookExpense.type) {
              case 'facebook':
                expenseBookTotal.facebookSpend = spend;
                expenseBookTotal.facebookImpressions = impressions;
                expenseBookTotal.facebookClicks = clicks;
                expenseBookTotal.facebookCpc = clicks === 0 ? 0 : adSpend / clicks;

                reportData.totals.facebookSpend += spend;
                reportData.totals.facebookImpressions += impressions;
                reportData.totals.facebookClicks += clicks;
                reportData.totals.facebookCpc = reportData.totals.facebookClicks === 0 ? 0 : reportData.totals.facebookSpend / reportData.totals.facebookClicks;
                break;
              case 'amazonads':
                expenseBookTotal.amazonAdsSpend = spend;
                expenseBookTotal.amazonAdsImpressions = impressions;
                expenseBookTotal.amazonAdsClicks = clicks;
                expenseBookTotal.amazonAdsCpc = clicks === 0 ? 0 : adSpend / clicks;

                reportData.totals.amazonAdsSpend += spend;
                reportData.totals.amazonAdsImpressions += impressions;
                reportData.totals.amazonAdsClicks += clicks;
                reportData.totals.amazonAdsCpc = reportData.totals.amazonAdsClicks === 0 ? 0 : reportData.totals.amazonAdsSpend / reportData.totals.amazonAdsClicks;
                break;
              case 'manual':
                expenseBookTotal.manualSpend = spend;
                reportData.totals.manualSpend += spend;
                break;
            }

            // group manual expenses
            if (!BOOK_EXPENSE_AD_TYPES.includes(curBookExpense.type)) {
              let expenseIndex = expenseIndexes[curBookExpense.type].indexOf(curBookExpense._id);
              if (expenseIndex === -1) {
                expenseIndexes[curBookExpense.type].push(curBookExpense._id);
                reportData.expenses[curBookExpense.type].push({
                  ...curBookExpense,
                  groupId: curBookExpense.name
                });
              } else {
                reportData.expenses[curBookExpense.type][expenseIndex] = {
                  ...this.addBookExpense(reportData.expenses[curBookExpense.type][expenseIndex], curBookExpense),
                  groupId: curBookExpense.name
                };
              }
            }

            // group book totals
            if (!expenseBookTotal._id.book) {
              expenseBookTotal._id.book = {
                _id: '0',
                title: 'Unassigned Ads',
                author: 'Unassigned Ads'
              }
            }
            let bookIndex = bookIds.indexOf(expenseBookTotal._id.book._id );
            if (bookIndex === -1) {
              bookIds.push(expenseBookTotal._id.book._id);
              reportData.groupedData.book.push({
                ...expenseBookTotal,
                groupId: expenseBookTotal._id.book.title
              });
            } else {
              reportData.groupedData.book[bookIndex] = this.addBookPerformance(reportData.groupedData.book[bookIndex], expenseBookTotal);
            }

            // group date totals
            let dateIndex = dates.indexOf(date.getTime());
            if (dateIndex === -1) {
              dates.push(date.getTime());
              reportData.groupedData.date.push({
                ...expenseBookTotal,
                groupId: date.toISOString()
              });
            } else {
              reportData.groupedData.date[dateIndex] = this.addBookPerformance(reportData.groupedData.date[dateIndex], expenseBookTotal);
            }

            // group geo totals
            if (expenseBookTotal._id.country) {
              let geoIndex = geos.indexOf(expenseBookTotal._id.country);
              if (geoIndex === -1) {
                geos.push(expenseBookTotal._id.country);
                reportData.groupedData.geo.push({
                  ...expenseBookTotal,
                  groupId: expenseBookTotal._id.country
                });
              } else {
                reportData.groupedData.geo[geoIndex] = this.addBookPerformance(reportData.groupedData.geo[geoIndex], expenseBookTotal);
              }
            }

            reportData.totals.spend += spend;
            reportData.totals.profit = reportData.totals.totalRoyalty - reportData.totals.spend;

            reportData.totals.adSpend += adSpend;
            reportData.totals.impressions += impressions;
            reportData.totals.clicks += clicks;

            reportData.totals.cpc = reportData.totals.clicks === 0 ? 0 : reportData.totals.adSpend / reportData.totals.clicks;

            reportData.totals.amazonAdsData.pages += expenseBookTotal.amazonAdsData && expenseBookTotal.amazonAdsData.pages ? expenseBookTotal.amazonAdsData.pages : 0;
            reportData.totals.amazonAdsData.unitsSold += expenseBookTotal.amazonAdsData && expenseBookTotal.amazonAdsData.unitsSold ? expenseBookTotal.amazonAdsData.unitsSold : 0;
            reportData.totals.amazonAdsData.orders += expenseBookTotal.amazonAdsData && expenseBookTotal.amazonAdsData.orders ? expenseBookTotal.amazonAdsData.orders : 0;
            reportData.totals.amazonAdsData.pagesRoyalty += expenseBookTotal.amazonAdsData && expenseBookTotal.amazonAdsData.pagesRoyalty ? expenseBookTotal.amazonAdsData.pagesRoyalty : 0;
          }
        }
      }
      // END Expenses


      reportData.groupedData.date.sort((a: any, b: any) => {
        let aDate = new Date(a.groupId);
        let bDate = new Date(b.groupId);
        return aDate.getTime() - bDate.getTime();
      });

      return resolve(reportData);
    });
  }

  /**
   * Accepts object containing currency conversions, date string representing target date,
   * and currency code of target. Returns a number representing the value of one USD in
   * the target currency for the target date.
   * @param currencyConversions Object where key is date and value is a CurrencyConversion
   * @param date Date Object
   * @param currency ISO 4217 currency code
   */
  private getCurrencyConversionForDate(currencyConversions: any, date: Date, currency: string):number {
    let currencyConversionDate = this.getCurrencyConversionDate(date);
    let currencyConversionMilliseconds = currencyConversionDate.getTime();

    let usedCurrencyConversionDate; // The date that was used for currency conversion

    let currencyConversion = 1;
    if (currencyConversions[currencyConversionMilliseconds]) {
      if (currency !== MarketplaceCurrencies.US) {
        currencyConversion = currencyConversions[currencyConversionMilliseconds]['USD' + currency];
      }
      usedCurrencyConversionDate = currencyConversionMilliseconds;
    } else {
      // use fallback (most recent date available)
      let dates = Object.keys(currencyConversions);
      dates.sort((a: string, b: string) => {
        return parseInt(b) - parseInt(a);
      });
      if (currency !== MarketplaceCurrencies.US) {
        currencyConversion = currencyConversions[dates[0]]['USD' + currency];
      }
      usedCurrencyConversionDate = parseInt(dates[0]);
    }

    // convert to target currency
    if (this.targetCurrency && this.targetCurrency !== 'USD') {
      currencyConversion = currencyConversion / currencyConversions[usedCurrencyConversionDate]['USD' + this.targetCurrency];
    }

    return currencyConversion;
  }

  private getKenpRateForDate(kenpRates: any, date: Date, marketplace: string):number {
    let kenpRateKeys = Object.keys(kenpRates);
    let returnRate;
    let usedFallbackRate = false;
    for (let monthOffset = 0; monthOffset < kenpRateKeys.length; monthOffset++) {
      if (!returnRate) {
        if (monthOffset > 0) {
          usedFallbackRate = true;
        }
        let modifiedDate = new Date(Date.UTC(date.getFullYear(), (date.getMonth() - monthOffset), 1, 0, 0, 0, 0));
        let kenpRateDateMilliseconds = modifiedDate.getTime();
        if (kenpRates[kenpRateDateMilliseconds] && kenpRates[kenpRateDateMilliseconds].length > 0) {
          Object.keys(kenpRates[kenpRateDateMilliseconds]).forEach(kenpRate => {
            if (kenpRates[kenpRateDateMilliseconds][kenpRate].marketplace === marketplace) {
              returnRate = kenpRates[kenpRateDateMilliseconds][kenpRate].rate;
            }
          });
        }
      }
    }
    if (isUndefined(returnRate)) {
      returnRate = 0;
    }

    // only apply knep rate modifications if the rate for the month being processed is undetermined
    if (usedFallbackRate) {
      if (this.kenpRateSettings.estimatedKenpRateType === 'CUSTOM_GLOBAL_RATE' && typeof this.kenpRateSettings.customGlobalRate === 'number') {
        // Return custom rate if selected
        return this.kenpRateSettings.customGlobalRate;
      } else if (this.kenpRateSettings.estimatedKenpRateType === 'ASSUME_INCREASE' && typeof this.kenpRateSettings.kenpAssumeIncreaseRate === 'number') {
        // apply percent increase
        returnRate += returnRate * (this.kenpRateSettings.kenpAssumeIncreaseRate / 100);
      } else if (this.kenpRateSettings.estimatedKenpRateType === 'ASSUME_DECREASE' && typeof this.kenpRateSettings.kenpAssumeDecreaseRate === 'number') {
        // apply percent decrease
        returnRate -= returnRate * (this.kenpRateSettings.kenpAssumeDecreaseRate / 100);
      } else if (this.kenpRateSettings.estimatedKenpRateType === 'CUSTOM_MARKETPLACE_RATES' && typeof this.kenpRateSettings.customMarketplaceRates[CountryCodesFromAmazonMarketplace[marketplace]] === 'number') {
        return this.kenpRateSettings.customMarketplaceRates[CountryCodesFromAmazonMarketplace[marketplace]];
      }
    }

    return returnRate;
  }

  /**
   * Accepts a string representing the amazon marketplace and returns
   * the ISO 4217 currency code of the marketplace
   * @param marketplace string representing the amazon marketplace
   */
  private getCurrencyOfMarketplace(marketplace: string) {
    switch (marketplace) {
      case AmazonMarketplaces.US:
        return MarketplaceCurrencies.US;
      case AmazonMarketplaces.GB:
        return MarketplaceCurrencies.GB;
      case AmazonMarketplaces.DE:
        return MarketplaceCurrencies.DE;
      case AmazonMarketplaces.FR:
        return MarketplaceCurrencies.FR;
      case AmazonMarketplaces.ES:
        return MarketplaceCurrencies.ES;
      case AmazonMarketplaces.IT:
        return MarketplaceCurrencies.IT;
      case AmazonMarketplaces.NL:
        return MarketplaceCurrencies.NL;
      case AmazonMarketplaces.JP:
        return MarketplaceCurrencies.JP;
      case AmazonMarketplaces.IN:
        return MarketplaceCurrencies.IN;
      case AmazonMarketplaces.CA:
        return MarketplaceCurrencies.CA;
      case AmazonMarketplaces.BR:
        return MarketplaceCurrencies.BR;
      case AmazonMarketplaces.MX:
        return MarketplaceCurrencies.MX;
      case AmazonMarketplaces.AU:
        return MarketplaceCurrencies.AU;
      case AmazonMarketplaces.PL:
        return MarketplaceCurrencies.PL;
      case AmazonMarketplaces.CN:
        return MarketplaceCurrencies.CN;
      case AmazonMarketplaces.EG:
        return MarketplaceCurrencies.EG;
      case AmazonMarketplaces.SA:
        return MarketplaceCurrencies.SA;
      case AmazonMarketplaces.SG:
        return MarketplaceCurrencies.SG;
      case AmazonMarketplaces.SE:
        return MarketplaceCurrencies.SE;
      case AmazonMarketplaces.TR:
        return MarketplaceCurrencies.TR;
      case AmazonMarketplaces.AE:
        return MarketplaceCurrencies.AE;
      default:
        return MarketplaceCurrencies[marketplace];
    }
  }

  private getCurrencyOfCountry(country: string) {
    return MarketplaceCurrencies[country];
  }

  private getCountryCodeOfMarketplace(marketplace: string) {
    if (marketplace.includes('Amazon')) {
      return CountryCodesFromAmazonMarketplace[marketplace];
    } else {
      return marketplace;
    }
  }

  /**
   * Calculates the correct date for currency conversion
   * given an input date.
   * @param date Date to convert from
   */
  private getCurrencyConversionDate(date: Date): Date {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), 1));
  }

  /**
   * Calculates the number of months between two Dates.
   * @param d1 date1
   * @param d2 date2
   */
  public monthDiff(d1: Date, d2: Date): number {
    let months;
    months = (d2.getUTCFullYear() - d1.getUTCFullYear()) * 12;
    months -= d1.getUTCMonth();
    months += d2.getUTCMonth();
    return months <= 0 ? 0 : months;
  }

  /**
   * Returns string of date in format that matches date returned by API.
   * @param dateToFormat Date that will be formated
   * @param monthOffset Optional number of months to offset output date
   * @param dayOffset Optional number of days to offset output date
   */
  private formatDate(dateToFormat: Date, monthOffset?: number, dayOffset?: number): string {
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

    let date = new Date(dateToFormat.getUTCFullYear(), dateToFormat.getUTCMonth() + (monthOffset || 0), dateToFormat.getUTCDate() + (dayOffset || 0), dateToFormat.getUTCHours(), dateToFormat.getUTCMinutes(), dateToFormat.getUTCSeconds(), dateToFormat.getUTCMilliseconds());

    let dateString = ''+date.getTime();

    return dateString;
  }

  private updateQuickStats(quickStats: QuickStats): void {
    this.store.dispatch(new UpdateQuickStats(quickStats));
  }

  public clearQuickStats(): void {
    const emptyQuickStats: QuickStats = {
      syncDate: undefined,
      today: {
        royalty: undefined,
        profit: undefined,
        spend: undefined,
        pages: undefined,
        units: undefined
      },
      yesterday: {
        royalty: undefined,
        profit: undefined,
        spend: undefined,
        pages: undefined,
        units: undefined
      }
    }
    this.store.dispatch(new UpdateQuickStats(emptyQuickStats));
  }

  /**
   * Adds two BookPerformances together maintains metadata for firstBookPerformance
   * @param firstBookPerformance
   * @param secondBookPerformance
   */
  private addBookPerformance(firstBookPerformance: BookPerformance, secondBookPerformance: BookPerformance): BookPerformance {
    let returnBookPerformance: BookPerformance = JSON.parse(JSON.stringify(firstBookPerformance));

    returnBookPerformance.freeOrders = (returnBookPerformance.freeOrders || 0) + (secondBookPerformance.freeOrders || 0);
    returnBookPerformance.freeUnits = (returnBookPerformance.freeUnits || 0) + (secondBookPerformance.freeUnits || 0);
    returnBookPerformance.kenpcRead = (returnBookPerformance.kenpcRead || 0) + (secondBookPerformance.kenpcRead || 0);
    returnBookPerformance.kenpcRoyalty = (returnBookPerformance.kenpcRoyalty || 0) + (secondBookPerformance.kenpcRoyalty || 0);
    returnBookPerformance.kindleCountdownRefunds = (returnBookPerformance.kindleCountdownRefunds || 0) + (secondBookPerformance.kindleCountdownRefunds || 0);
    returnBookPerformance.kindleCountdownRoyalty = (returnBookPerformance.kindleCountdownRoyalty || 0) + (secondBookPerformance.kindleCountdownRoyalty || 0);
    returnBookPerformance.kindleCountdownUnits = (returnBookPerformance.kindleCountdownUnits || 0) + (secondBookPerformance.kindleCountdownUnits || 0);
    returnBookPerformance.paidOrders = (returnBookPerformance.paidOrders || 0) + (secondBookPerformance.paidOrders || 0);
    returnBookPerformance.paperbackRefunds = (returnBookPerformance.paperbackRefunds || 0) + (secondBookPerformance.paperbackRefunds || 0);
    returnBookPerformance.paperbackRoyalty = (returnBookPerformance.paperbackRoyalty || 0) + (secondBookPerformance.paperbackRoyalty || 0);
    returnBookPerformance.paperbackUnits = (returnBookPerformance.paperbackUnits || 0) + (secondBookPerformance.paperbackUnits || 0);
    returnBookPerformance.expandedDistributionChannelsRefunds = (returnBookPerformance.expandedDistributionChannelsRefunds || 0) + (secondBookPerformance.expandedDistributionChannelsRefunds || 0);
    returnBookPerformance.expandedDistributionChannelsRoyalty = (returnBookPerformance.expandedDistributionChannelsRoyalty || 0) + (secondBookPerformance.expandedDistributionChannelsRoyalty || 0);
    returnBookPerformance.expandedDistributionChannelsUnits = (returnBookPerformance.expandedDistributionChannelsUnits || 0) + (secondBookPerformance.expandedDistributionChannelsUnits || 0);
    returnBookPerformance.preOrderRefunds = (returnBookPerformance.preOrderRefunds || 0) + (secondBookPerformance.preOrderRefunds || 0);
    returnBookPerformance.preOrderRoyalty = (returnBookPerformance.preOrderRoyalty || 0) + (secondBookPerformance.preOrderRoyalty || 0);
    returnBookPerformance.preOrderUnits = (returnBookPerformance.preOrderUnits || 0) + (secondBookPerformance.preOrderUnits || 0);
    returnBookPerformance.standardRefunds = (returnBookPerformance.standardRefunds || 0) + (secondBookPerformance.standardRefunds || 0);
    returnBookPerformance.standardRoyalty = (returnBookPerformance.standardRoyalty || 0) + (secondBookPerformance.standardRoyalty || 0);
    returnBookPerformance.standardUnits = (returnBookPerformance.standardUnits || 0) + (secondBookPerformance.standardUnits || 0);
    returnBookPerformance.totalRoyalty = (returnBookPerformance.totalRoyalty || 0) + (secondBookPerformance.totalRoyalty || 0);
    returnBookPerformance.totalUnits = (returnBookPerformance.totalUnits || 0) + (secondBookPerformance.totalUnits || 0);
    returnBookPerformance.spend = (returnBookPerformance.spend || 0) + (secondBookPerformance.spend || 0);
    returnBookPerformance.adSpend = (returnBookPerformance.adSpend || 0) + (secondBookPerformance.adSpend || 0);
    returnBookPerformance.clicks = (returnBookPerformance.clicks || 0) + (secondBookPerformance.clicks || 0);
    returnBookPerformance.impressions = (returnBookPerformance.impressions || 0) + (secondBookPerformance.impressions || 0);

    returnBookPerformance.paperbackNet = (returnBookPerformance.paperbackNet || 0) + (secondBookPerformance.paperbackNet || 0);
    returnBookPerformance.expandedDistributionChannelsNet = (returnBookPerformance.expandedDistributionChannelsNet || 0) + (secondBookPerformance.expandedDistributionChannelsNet || 0);
    returnBookPerformance.kindleCountdownNet = (returnBookPerformance.kindleCountdownNet || 0) + (secondBookPerformance.kindleCountdownNet || 0);
    returnBookPerformance.preOrderNet = (returnBookPerformance.preOrderNet || 0) + (secondBookPerformance.preOrderNet || 0);
    returnBookPerformance.standardNet = (returnBookPerformance.standardNet || 0) + (secondBookPerformance.standardNet || 0);

    returnBookPerformance.profit = returnBookPerformance.totalRoyalty - returnBookPerformance.spend;
    returnBookPerformance.cpc = returnBookPerformance.clicks === 0 ? 0 : returnBookPerformance.adSpend / returnBookPerformance.clicks;

    returnBookPerformance.facebookSpend = (returnBookPerformance.facebookSpend || 0) + (secondBookPerformance.facebookSpend || 0);
    returnBookPerformance.facebookImpressions = (returnBookPerformance.facebookImpressions || 0) + (secondBookPerformance.facebookImpressions || 0);
    returnBookPerformance.facebookClicks = (returnBookPerformance.facebookClicks || 0) + (secondBookPerformance.facebookClicks || 0);
    returnBookPerformance.amazonAdsSpend = (returnBookPerformance.amazonAdsSpend || 0) + (secondBookPerformance.amazonAdsSpend || 0);
    returnBookPerformance.amazonAdsImpressions = (returnBookPerformance.amazonAdsImpressions || 0) + (secondBookPerformance.amazonAdsImpressions || 0);
    returnBookPerformance.amazonAdsClicks = (returnBookPerformance.amazonAdsClicks || 0) + (secondBookPerformance.amazonAdsClicks || 0);
    returnBookPerformance.manualSpend = (returnBookPerformance.manualSpend || 0) + (secondBookPerformance.manualSpend || 0);

    returnBookPerformance.facebookCpc = returnBookPerformance.facebookClicks === 0 ? 0 : returnBookPerformance.facebookSpend / returnBookPerformance.facebookClicks;
    returnBookPerformance.amazonAdsCpc = returnBookPerformance.amazonAdsClicks === 0 ? 0 : returnBookPerformance.amazonAdsSpend / returnBookPerformance.amazonAdsClicks;

    // amazon ads
    if (!returnBookPerformance.amazonAdsData) returnBookPerformance.amazonAdsData = {
      pages: 0,
      unitsSold: 0,
      orders: 0,
      pagesRoyalty: 0
    };
    if (!secondBookPerformance.amazonAdsData) secondBookPerformance.amazonAdsData = {
      pages: 0,
      unitsSold: 0,
      orders: 0,
      pagesRoyalty: 0
    };

    returnBookPerformance.amazonAdsData.pages = (returnBookPerformance.amazonAdsData.pages || 0) + (secondBookPerformance.amazonAdsData.pages || 0);
    returnBookPerformance.amazonAdsData.orders = (returnBookPerformance.amazonAdsData.orders || 0) + (secondBookPerformance.amazonAdsData.orders || 0);
    returnBookPerformance.amazonAdsData.unitsSold = (returnBookPerformance.amazonAdsData.unitsSold || 0) + (secondBookPerformance.amazonAdsData.unitsSold || 0);
    returnBookPerformance.amazonAdsData.pagesRoyalty = (returnBookPerformance.amazonAdsData.pagesRoyalty || 0) + (secondBookPerformance.amazonAdsData.pagesRoyalty || 0);
    // END amazon ads

    // add key if missing
    if (!returnBookPerformance.spendBySource) returnBookPerformance.spendBySource = {};
    if (!secondBookPerformance.spendBySource) secondBookPerformance.spendBySource = {};

    returnBookPerformance.spendBySource[BookExpenseTypes.FACEBOOK] = (returnBookPerformance.spendBySource[BookExpenseTypes.FACEBOOK] || 0) + (secondBookPerformance.spendBySource[BookExpenseTypes.FACEBOOK] || 0);
    returnBookPerformance.spendBySource[BookExpenseTypes.AMAZON_ADS] = (returnBookPerformance.spendBySource[BookExpenseTypes.AMAZON_ADS] || 0) + (secondBookPerformance.spendBySource[BookExpenseTypes.AMAZON_ADS] || 0);
    returnBookPerformance.spendBySource[BookExpenseTypes.MANUAL] = (returnBookPerformance.spendBySource[BookExpenseTypes.MANUAL] || 0) + (secondBookPerformance.spendBySource[BookExpenseTypes.MANUAL] || 0);

    return returnBookPerformance;
  }

  /**
   * Adds two BookPerformances together maintains metadata for firstBookExpense.
   * Does not add vendor specific metrics
   * @param firstBookExpense
   * @param secondBookExpense
   */
  private addBookExpense(firstBookExpense: BookExpense, secondBookExpense: BookExpense): BookExpense {
    let returnBookExpense: BookExpense = JSON.parse(JSON.stringify(firstBookExpense));

    returnBookExpense.spend += secondBookExpense.spend;
    returnBookExpense.reach += secondBookExpense.reach;
    returnBookExpense.impressions += secondBookExpense.impressions;
    returnBookExpense.clicks += secondBookExpense.clicks;

    if (firstBookExpense.type === BookExpenseTypes.AMAZON_ADS) {
      returnBookExpense.amazonAdsData.pages += secondBookExpense.amazonAdsData.pages;
      returnBookExpense.amazonAdsData.unitsSold += secondBookExpense.amazonAdsData.unitsSold;
      returnBookExpense.amazonAdsData.orders += secondBookExpense.amazonAdsData.orders;

      if (!returnBookExpense.amazonAdsData.pagesRoyalty) {
        returnBookExpense.amazonAdsData.pagesRoyalty = 0;
      }

      returnBookExpense.amazonAdsData.pagesRoyalty += secondBookExpense.amazonAdsData.pagesRoyalty ? secondBookExpense.amazonAdsData.pagesRoyalty : 0;
    }

    if (firstBookExpense?.country && secondBookExpense?.country) {
      if (!firstBookExpense.country.includes(secondBookExpense.country)) {
        returnBookExpense.country = `${firstBookExpense.country}, ${secondBookExpense.country}`;
      }
    }

    returnBookExpense.cpc = returnBookExpense.clicks === 0 ? 0 : returnBookExpense.spend / returnBookExpense.clicks;

    return returnBookExpense;
  }

  public getKenpRateFromAPI(date?: Date): Promise<any> {
    return new Promise((resolve, reject) => {
      let endpoint = this.kenpRateEndpoint;
      if (date) {
        endpoint += `/?date=${date.getUTCFullYear()}-${('0' + (date.getUTCMonth() + 1)).slice(-2)}-${('0' + date.getUTCDate()).slice(-2)}`
      }
      this.http.get(endpoint).pipe(take(1)).subscribe((res) => {
        return resolve(res);
      }, (err) => {
        return reject();
      });
    });
  }

  private roundKenpReadSnapshot(snapshotData: SnapshotData): SnapshotData {
    //today
    for (let bookPerformance of snapshotData.today.bookPerformances) {
      bookPerformance.kenpcRead = Math.round(bookPerformance.kenpcRead);
    }
    for (let author of snapshotData.today.groupedData.author) {
      author.kenpcRead = Math.round(author.kenpcRead);
    }
    for (let marketplace of snapshotData.today.groupedData.marketplace) {
      marketplace.kenpcRead = Math.round(marketplace.kenpcRead);
    }
    for (let book of snapshotData.today.groupedData.book) {
      book.kenpcRead = Math.round(book.kenpcRead);
    }
    for (let series of snapshotData.today.groupedData.series) {
      series.kenpcRead = Math.round(series.kenpcRead);
    }
    snapshotData.today.totals.kenpcRead = Math.round(snapshotData.today.totals.kenpcRead);

    // yesterday
    for (let bookPerformance of snapshotData.yesterday.bookPerformances) {
      bookPerformance.kenpcRead = Math.round(bookPerformance.kenpcRead);
    }
    snapshotData.yesterday.totals.kenpcRead = Math.round(snapshotData.yesterday.totals.kenpcRead);
    return snapshotData;
  }

  private roundProfitVariables(profitData: ProfitReportData): ProfitReportData {

    for (let bookPerformance of profitData.bookPerformances) {
      bookPerformance.kenpcRead = Math.round(bookPerformance.kenpcRead);
      bookPerformance.totalUnits = Math.round(bookPerformance.totalUnits);
    }
    for (let author of profitData.groupedData.author) {
      author.kenpcRead = Math.round(author.kenpcRead);
      author.totalUnits = Math.round(author.totalUnits);
    }
    for (let book of profitData.groupedData.book) {
      book.kenpcRead = Math.round(book.kenpcRead);
      book.totalUnits = Math.round(book.totalUnits);
    }
    for (let date of profitData.groupedData.date) {
      date.kenpcRead = Math.round(date.kenpcRead);
      date.totalUnits = Math.round(date.totalUnits);
    }
    for (let marketplace of profitData.groupedData.marketplace) {
      marketplace.kenpcRead = Math.round(marketplace.kenpcRead);
      marketplace.totalUnits = Math.round(marketplace.totalUnits);
    }
    for (let series of profitData.groupedData.series) {
      series.kenpcRead = Math.round(series.kenpcRead);
      series.totalUnits = Math.round(series.totalUnits);
    }
    profitData.totals.kenpcRead = Math.round(profitData.totals.kenpcRead);
    profitData.totals.totalUnits = Math.round(profitData.totals.totalUnits);

    return profitData;
  }

  private getBookAndPlatformBookFromPlatformBookId(platformBookId: string, books: Array<Book>): { book: Book, platformBook: PlatformBook} {
    for (let book of books) {
      for (let platformBookCandidate of book.platformBooks) {
        if (platformBookCandidate._id === platformBookId) {
          return {
            book: book,
            platformBook: platformBookCandidate
          };
        }
      }
    }
  }

  private getBookFromBookId(bookId: string, books: Array<Book>): Book {
    for (let book of books) {
      if (bookId === book._id) {
        return book;
      }
    }
  }

  private getAmazonCampaignAndAd(bookExpense: BookExpense, campaigns: Array<AmazonCampaign>): { campaign: AmazonCampaign, ad: AmazonAd} {
    for (let campaign of campaigns) {
      if (bookExpense.parent === campaign._id) {
        let ad = undefined;

        if (campaign.ads.length == 1) {
          ad = campaign.ads[0];
        }

        return {
          campaign: {
            ...campaign,
            ads: undefined
          },
          ad: ad
        }
      }
      for (let ad of campaign.ads) {
        if (bookExpense.parent === ad._id) {
          return {
            campaign: {
              ...campaign,
              ads: undefined
            },
            ad: ad
          }
        }
      }
    }
  }

  private getFacebookCampaignAndAd(bookExpense: BookExpense, campaigns: Array<FacebookCampaign>): { campaign: FacebookCampaign, ad: FacebookAd} {
    for (let campaign of campaigns) {
      for (let ad of campaign.ads) {
        if (bookExpense.parent === ad._id) {
          return {
            campaign: {
              ... campaign,
              ads: undefined
            },
            ad: ad
          }
        }
      }
    }
  }

  private getBookCover(book: Book): string {
    return this.bookService.getCoverUrlFromBook(book);
  }

}
