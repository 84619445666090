import { Routes } from '@angular/router';
import { PublicLayoutComponent } from './routes/public/public-layout/public-layout.component';
import { PrivateLayoutComponent } from './routes/private/private-layout/private-layout.component';
import { AuthGuard } from './guards/auth.guard';
import { OnboardedGuard } from './guards/onboarded.guard';
import { HasSubscriptionGuard } from './guards/has-subscription.guard';
import { OnboardingWsComponent } from './routes/private/onboarding/onboarding-ws/onboarding-ws.component';

export const routes: Routes = [

  /* Child Routing Modules to load with LazyLoading */
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./routes/public/public.module').then(m => m.PublicModule)
      }
    ]
  },
  {
    path: 'auth',
    component: PublicLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./routes/auth/auth.module').then(m => m.AuthModule)
      }
    ]
  },
  {
    path: 'welcome',
    component: OnboardingWsComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: '',
    component: PrivateLayoutComponent,
    children: [
      {
        path: 'reports',
        loadChildren: () => import('./routes/private/reports/reports.module').then(m => m.ReportsModule),
        canActivate: [
          HasSubscriptionGuard
        ]
      },
      {
        path: 'books',
        loadChildren: () => import('./routes/private/books/books.module').then(m => m.BooksModule),
        canActivate: [
          HasSubscriptionGuard
        ]
      },
      {
        path: 'ads',
        loadChildren: () => import('./routes/private/ads/ads.module').then(m => m.AdsModule),
        canActivate: [
          HasSubscriptionGuard
        ]
      },
      {
        path: 'account',
        loadChildren: () => import('./routes/private/account/account.module').then(m => m.AccountModule)
      }, 
      {
        path: 'income',
        loadChildren: () => import('./routes/private/income/income.module').then(m => m.IncomeModule),
        canActivate: [
          HasSubscriptionGuard
        ]
      },
      {
        path: 'shared-access',
        loadChildren: () => import('./routes/private/shared-access/shared-access.module').then(m => m.SharedAccessModule)
      }
    ],
    canActivate: [
      AuthGuard,
      OnboardedGuard
    ]
  }
  // {
  //   path: '',
  //   component: LayoutComponent,
  //   children: [
  //     {
  //       path: 'reports',
  //       loadChildren: 'app/routes/reports/reports.module#ReportsModule',
  //     },
  //     {
  //       path: 'account',
  //       loadChildren: 'app/routes/user/user.module#UserModule',
  //     },
  //     {
  //       path: 'ads',
  //       loadChildren: 'app/routes/ads/ads.module#AdsModule',
  //     },
  //     {
  //       path: 'books',
  //       loadChildren: 'app/routes/books/books.module#BooksModule',
  //     },

  //   ]
  // },

];
