import * as moment from 'moment';

// Shortcut options
export enum ShortcutOptions {
  SHORTCUT_TODAY = 'Today',
  SHORTCUT_YESTERDAY = 'Yesterday',
  SHORTCUT_THIS_WEEK = 'This Week',
  SHORTCUT_LAST_WEEK = 'Last Week',
  SHORTCUT_THIS_MONTH = 'This Month',
  SHORTCUT_LAST_MONTH = 'Last Month',
  SHORTCUT_PAST_THIRTY_DAYS = '30 Days',
  SHORTCUT_PAST_SIXTY_DAYS = '60 Days',
  SHORTCUT_PAST_NEINTY_DAYS = '90 Days',
  SHORTCUT_YEAR_TO_DATE = 'YTD'
}
// END Shortcut options

export class DateRange {
  start: string; // string representing the start date in YYYY-MM-DD format UST 
  end: string; // string representing the end date in YYYY-MM-DD format UST
  shortcut?: string; // shortcut reperesenting the date range

  protected startDate: Date; // start date in Date format
  protected endDate: Date; // end date in Date format

  constructor(start: string, end: string, shortcut?: string) {
    const dateFormatRegex = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/g;

    /**
     * If has shortcut param then use it instead of the start and end
     */
    if (shortcut) {
      let shortcutsArray = this.shortcutsArray();
      if (shortcutsArray.values.includes(shortcut)) {
        this.shortcut = shortcut;
      } else {
        this.shortcut = 'Today';
      }
      
      // get the enum name form the value
      let enumName = shortcutsArray.enums[shortcutsArray.values.indexOf(this.shortcut)];
      let shortcutOptions = this.getShortcutOptions();
      let datesArray = shortcutOptions[enumName];
      
      start = datesArray[0].format('YYYY-MM-DD');
      end = datesArray[1].format('YYYY-MM-DD');

      this.start = start;
      this.end = end;

    } else if (start || end) {
      // Check if start is in correct format
      if (!start.match(dateFormatRegex)) {
        throw '"start" must be in format "YYYY-MM-DD"';
      }

      // Check if end is in correct format
      if (!end.match(dateFormatRegex)) {
        throw '"end" must be in format "YYYY-MM-DD"';
      }

      this.start = start;
      this.end = end;
    }

    if (start) {
      // create startDate using the start string
      let splitStart = start.split('-');
      let startDate = new Date();
      startDate.setTime(Date.UTC(parseInt(splitStart[0]), parseInt(splitStart[1]) - 1, parseInt(splitStart[2])));
      this.startDate = startDate;
    }

    if (end) {
      // create endDate using the end string
      let splitEnd = end.split('-');
      let endDate = new Date();
      endDate.setTime(Date.UTC(parseInt(splitEnd[0]), parseInt(splitEnd[1]) - 1, parseInt(splitEnd[2])));
      this.endDate = endDate;
    }
  }

  /**
   * Returns and object with key = shortcut
   * and value = moment[] with the first
   * index being the start and the second index
   * the end
   */
  public getShortcutOptions() {
    return {
      SHORTCUT_TODAY: [moment(), moment()],
      SHORTCUT_YESTERDAY: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      SHORTCUT_THIS_WEEK: [moment().startOf('week'), moment()],
      SHORTCUT_LAST_WEEK: [moment().startOf('week').subtract(1, 'week'), moment().startOf('week').subtract(1, 'days')],
      SHORTCUT_THIS_MONTH: [moment().startOf('month'), moment()],
      SHORTCUT_LAST_MONTH: [moment().startOf('month').subtract(1, 'month'), moment().startOf('month').subtract(1, 'days')],
      SHORTCUT_YEAR_TO_DATE: [moment().startOf('year'), moment()],
      SHORTCUT_PAST_THIRTY_DAYS: [moment().subtract(30, 'days'), moment()],
      SHORTCUT_PAST_SIXTY_DAYS: [moment().subtract(60, 'days'), moment()],
      SHORTCUT_PAST_NEINTY_DAYS: [moment().subtract(90, 'days'), moment()]
    }
  }

  /**
   * Returns the preset options that are compatible with
   * ngx-mat-daterange-picker.
   */
  public getPresets():any {
    const shortcutOptions = this.getShortcutOptions();
    const shortcutLabels = Object.keys(shortcutOptions);
    let presets: any = {};

    for (let i = 0; i < shortcutLabels.length; i++) {
      presets[ShortcutOptions[shortcutLabels[i]]] = shortcutOptions[shortcutLabels[i]];
    }

    return presets;
  }

  /**
   * Returns array of the values
   * of ShortcutOptions enum
   * @returns Array of object containg
   * shortcut enum names and values
   */
  private shortcutsArray(): EnumPairs  {
    let values: string[] = [];
    let enums: string[] = [];
    for (let enumMember in ShortcutOptions) {
      values.push(ShortcutOptions[enumMember]);
      enums.push(enumMember);
    }
    return {
      enums: enums,
      values: values
    };
  }

  /**
   * Returns the JSON stringified representation
   * of the DateRange object
   * @returns JSON String representaiton of object.
   */
  public toJSONString(): string {
    return JSON.stringify({
      start: this.start,
      end: this.end,
      shortcut: this.shortcut,
      startDate: this.startDate.toUTCString(),
      endDate: this.endDate.toUTCString()
    });
  }

  /**
   * Accepts an integer number of days
   * to be added to the start date. Returns
   * a DateRange object.
   * @param Number of days to add to the start
   * date
   * @returns DateRange object with start date
   * advanced by the number provided.
   */
  public addDaysToStart(days: number): DateRange {
    let startDate = this.startDate;
    startDate.setDate(startDate.getDate() + days);
    this.startDate = startDate;
    return this;
  }

  /**
   * Accepts an integer number of days
   * to be added to the end date. Returns
   * a DateRange object.
   * @param Number of days to add to the end
   * date
   * @returns DateRange object with end date
   * advanced by the number provided.
   */
  public addDaysToEnd(days: number): DateRange {
    let endDate = this.endDate;
    endDate.setDate(endDate.getDate() + days);
    this.endDate = endDate;
    return this;
  }

  /**
   * Accepts an integer number of days
   * to be subtracted from the start date. Returns
   * a DateRange object.
   * @param Number of days to subtracted from the start
   * date
   * @returns DateRange object with start date
   * reduced by the number provided.
   */
  public subtractDaysFromStart(days: number): DateRange {
    let startDate = this.startDate;
    startDate.setDate(startDate.getDate() - days);
    this.startDate = startDate;
    return this;
  }

  /**
   * Accepts an integer number of days
   * to be subtracted from the end date. Returns
   * a DateRange object.
   * @param Number of days to subtracted from the end
   * date
   * @returns DateRange object with end date
   * reduced by the number provided.
   */
  public subtractDaysFromEnd(days: number): DateRange {
    let endDate = this.endDate;
    endDate.setDate(endDate.getDate() - days);
    this.endDate = endDate;
    return this;
  }

  /**
   * Converts the start date to UTC and returns
   * as string in format 'YYYY-MM-DD'
   * @returns String representing the start date
   */
  public getStartDateString(): string {
    let string = this.startDate.getUTCFullYear() + '-' + ('0' + (this.startDate.getUTCMonth() + 1)).slice(-2) + '-' + ('0' + this.startDate.getUTCDate()).slice(-2);
    return string;
  }

  /**
   * Converts the end date to UTC and returns
   * as string in format 'YYYY-MM-DD'
   * @returns String representing the end date
   */
  public getEndDateString(): string {
    let string = this.endDate.getUTCFullYear() + '-' + ('0' + (this.endDate.getUTCMonth() + 1)).slice(-2) + '-' + ('0' + this.endDate.getUTCDate()).slice(-2);
    return string;
  }

  /**
   * Returns startDate in Date format
   */
  public getStartDate(): Date {
    let date = new Date(this.startDate.getUTCFullYear(), this.startDate.getUTCMonth(), this.startDate.getUTCDate());
    return date;
  }

  /**
   * Returns endDate in Date format
   */
  public getEndDate(): Date {
    let date = new Date(this.endDate.getUTCFullYear(), this.endDate.getUTCMonth(), this.endDate.getUTCDate());
    return date;
  }

  /**
   * Returns startDate as moment object
   */
  public getStartDateMoment(): any {
    let date = moment(new Date(this.startDate.getUTCFullYear(), this.startDate.getUTCMonth(), this.startDate.getUTCDate()));
    return date;
  }

  /**
   * Returns endDate as moment object
   */
  public getEndDateMoment(): any {
    let date = moment(new Date(this.endDate.getUTCFullYear(), this.endDate.getUTCMonth(), this.endDate.getUTCDate()));
    return date;
  }

  public toJSON(): any {
    return {
      start: this.start,
      end: this.end,
      shortcut: this.shortcut
    };
  }

  public setStartDate(date: Date): void {
    let startDate = new Date();
    startDate.setTime(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    this.startDate = startDate;
    this.start = this.getStartDateString();
    this.clearShortcut();
  }

  public setEndDate(date: Date): void {
    let endDate = new Date();
    endDate.setTime(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    this.endDate = endDate;
    this.end = this.getEndDateString();
    this.clearShortcut();
  }

  public clearShortcut() {
    this.shortcut = undefined;
  }

  public setShortcut(shortcut: string) {
    this.shortcut = shortcut;
  }

  /**
   * Returns true if the provided date is included
   * within the DateRange inclusive.
   * @param date 
   */
  public includes(date: Date): boolean {
    return date >= this.startDate && date <= this.endDate;
  }

  /**
   * Returns the number of days in the DateRange
   */
  public countDays(): number {
    const diffMilliseconds = this.endDate.getTime() - this.startDate.getTime();
    return diffMilliseconds / 86400000;
  }
}

interface EnumPairs {
  enums: string[] // array of enum names,
  values: string[] // array of enum values
}