<div class="nav" [attr.data-pathname]="pathname">
  <div class="nav-container">
    <div class="nav-col brand">
      <a routerLink="/">PublishWide</a>
    </div>
    <div class="nav-col links links-center">
      <!-- <ul>
        <li>
          <a routerLink="/tools">Free Tools</a>
        </li>
        <li>
          <a routerLink="/about">About</a>
        </li>
        <li>
          <a routerLink="/pricing">Pricing</a>
        </li>
      </ul> -->
    </div>
    <div class="nav-col links links-right">
      <ul>
        <li *ngIf="!authenticated">
          <a class="button button-no-contrast" routerLink="/auth/login">Log In</a>
        </li>
        <li *ngIf="authenticated">
          <a class="button button-no-contrast" routerLink="/reports">Dashboard</a>
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="main-router-outlet" [attr.data-pathname]="pathname">
  <router-outlet></router-outlet>
</div>
<div class="footer">
  <div class="footer-container">
    <div class="footer-col">
      &copy; {{year}} PublishWide Inc.
    </div>
    <div class="footer-col">
      <ul>
        <li>
          <a routerLink="/legal/terms">Terms</a>
        </li>
        <li>
          <a routerLink="/legal/privacy">Privacy</a>
        </li>
      </ul>
    </div>
    <div class="footer-col">
      <ul>
        <li>
          <a href="https://www.facebook.com/publishwide/" target="_blank" rel="noopener noreferrer" title="Follow us on Facebook"><i class="fab fa-facebook-f"></i></a>
        </li>
        <li>
          <a href="https://twitter.com/publishwide" target="_blank" rel="noopener noreferrer" title="Follow us on Twitter"><i class="fab fa-twitter"></i></a>
        </li>
      </ul>
    </div>
  </div>
</div>