import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { StoreModule, ActionReducer, MetaReducer } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { localStorageSync } from 'ngrx-store-localstorage';
import { AuthService } from './routes/auth/auth.service';
import { Ng2UiAuthModule } from 'ng2-ui-auth';
import { MaterialModule } from './material-module';
import { ChartsModule } from 'ng2-charts';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { CookieService } from 'ngx-cookie-service';

import { AppComponent } from './app.component';
import { PublicLayoutComponent } from './routes/public/public-layout/public-layout.component';
import { PrivateLayoutComponent } from './routes/private/private-layout/private-layout.component';
import { OnboardingModule } from './routes/private/onboarding/onboarding.module';
import { routes } from './routes';
import { BookService } from './routes/private/books/book.service';

import { environment } from '../environments/environment';
import { reducers } from './state';

import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { UiComponentsModule } from './ui-components/ui-components.module';
import { FormsModule } from '@angular/forms';

// set which stores to store in localstorage
export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({ keys: ['auth', 'quickStats', 'books', 'notifications', 'dashboards'], rehydrate: true })(reducer);
}
const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
  declarations: [
    AppComponent,
    PublicLayoutComponent,
    PrivateLayoutComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, { enableTracing: false, relativeLinkResolution: 'legacy' }),
    BrowserAnimationsModule,
    MaterialModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreDevtoolsModule.instrument({
      maxAge: 5,
      logOnly: environment.production
    }),
    EffectsModule.forRoot([]),
    ChartsModule,
    OnboardingModule,
    UiComponentsModule,
    FormsModule
  ],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    CookieService,
    BookService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
