export const REPLACEMENT_BOOK_TITLES = [
  "Cantankerous Laboratory",
  "Drowsy College",
  "Spooky Brook",
  "Vile Boulevard",
  "Wealthy College",
  "Distressed Hill",
  "Cagy Schoolroom",
  "Abandoned Boulevard",
  "Hideous Bog",
  "Cruel Lighthouse",
  "Thoughtless Icehouse",
  "Weary Kennel",
  "Loony Mill",
  "Mute Labyrinth",
  "Cement Rock",
  "Circular Stream",
  "Damaged Gallery",
  "Gigantic Bunker",
  "Strange Cellar",
  "Ghastly Chalet",
  "Wax Barn",
  "Cantankerous Graveyard",
  "Bootleg Gallery",
  "Cinnamon Vineyard",
  "Clandestine Graveyard",
  "Rusty Icehouse",
  "Malicious Desert",
  "Abandoned Factory",
  "Thoughtless Igloo",
  "Risky Alcove",
  "Archaic Harbor",
  "Fanatical Dormitory",
  "Depressed Dormitory",
  "Vile Boulevard",
  "Bankrupt Monastery",
  "Strange Bakery",
  "Forgotten Palace",
  "Frozen Cellar",
  "Shattered Shipyard",
  "Maniacal Highlands",
  "Princely Gatehouse",
  "Dismal Hospital",
  "Villainous Airport",
  "Faint Tower",
  "Milky Laboratory",
  "Cranky Island",
  "Abandoned Cove",
  "Magical Restaurant",
  "Enchanted City",
  "Jealous Cottage",
  "Princely Cabin",
  "Capricious Gallery",
  "Strange Hill",
  "Fanatical Gallery",
  "Eccentric Quarry",
  "Finicky Conservatory",
  "Vicious Store",
  "Faint Cliff",
  "Lonely Graveyard",
  "Morbid Bridge",
  "Bleak Canal",
  "Sunken Lighthouse",
  "Moldy Cove",
  "Cedar Hill",
  "Fickle Hermitage",
  "Helpless Mansion",
  "Luminous Cabin",
  "Risky Crypt",
  "Forlorn Stream",
  "Volatile Orchard",
  "Blond Warehouse",
  "French Planetarium",
  "Wax Hospital",
  "Clever Reef",
  "Stone Fountain",
  "Covert Crematorium",
  "Hidden Schoolroom",
  "Loony Quarry",
  "Mysterious Gym",
  "Cavernous Winery",
  "Rustic Asylum",
  "Wicked Skyscraper",
  "Volatile Runway",
  "Malignant Plantation",
  "Obnoxious Crossroads",
  "Cavernous Sawmill",
  "Bamboo Mill",
  "Swiss Quarry",
  "Counterfeit Playground",
  "Broken Plantation",
  "Abrasive Savanna",
  "Clever Nursery",
  "Counterfeit Fountain",
  "Lonely Garden",
  "Moss-covered Cabin",
  "Cement Outpost",
  "Ragged Reef",
  "Princely Monastery",
  "Zany Castle",
  "Moldy College",
  "Wrecked House",
  "Grotesque Tunnel",
  "Bamboo Cannery",
  "Bronze House",
  "Strange Railroad",
  "Incandescent Railway",
  "Origami Restaurant",
  "Wooden Motel",
  "Rusty Schoolroom",
  "Foolish Hillside",
  "Pitiful Cellar",
  "Fickle Zoo",
  "Lavender Swamp",
  "British Cemetery",
  "Antique Attic",
  "Delirious Cabin",
  "Lawless Racetrack",
  "Craven Shipyard",
  "Cowardly Palace",
  "Moldy Fountain",
  "Volatile Crypt",
  "Wrecked Hilltop",
  "Frail Graveyard",
  "Circular Gatehouse",
  "Dusty Cafeteria",
  "Cedar Hatchery",
  "Volatile City",
  "Horrible Fort",
  "Infamous Theater",
  "Vile Schoolroom",
  "Bronze Barn",
  "Pesky Restaurant",
  "Hidden Schoolhouse",
  "Obnoxious Gallery",
  "Filthy Motel",
  "Reckless Railway",
  "Archaic Airport",
  "British Mountain",
  "Lavender City",
  "Deadly Storeroom",
  "Dreary Gatehouse",
  "Cement Labyrinth",
  "Shrewd Trail",
  "Repulsive Cave",
  "Baffled Laboratory",
  "Greedy Hall",
  "Broken Hospital",
  "Invisible Dormitory",
  "Defiant Farm",
  "Italian Gallery",
  "Ominous Cannery",
  "Arrogant Railway",
  "Twisted Bunker",
  "Thoughtless Hall",
  "Jade Parkway",
  "Irish Reef",
  "Abrasive Canyon",
  "Hooded Schoolhouse",
  "Majestic Chalet",
  "Incandescent Tabernacle",
  "Ultraviolet Balcony",
  "Ruined Cleft",
  "Fickle Graveyard",
  "Magic Hermitage",
  "Jinxed Zoo",
  "Abandoned Bank",
  "Cautious Boulevard",
  "Purple Tunnel",
  "Sullen Lake",
  "Eerie Fort",
  "Infrared House",
  "Violet Hatchery",
  "Infrared Crematorium",
  "Ruthless Wharf",
  "Obnoxious Gatehouse",
  "Melted Mountain",
  "Lost Rock",
  "Cantankerous Railway",
  "Poisonous Swamp",
  "Stone Crossroads",
  "Icy Hatchery",
  "Deadly University",
  "Craven Lagoon",
  "Forgotten Cleft",
  "Languid Airport",
  "Alabaster Garden",
  "Baffled Cafe",
  "Grisly Meadow",
  "Quixotic Garage",
  "Incandescent Hilltop",
  "Lavender Cannery",
  "Gruesome Cistern",
  "Princely Foxhole",
  "Careless Mill",
  "Lost Lagoon",
  "Moldy Oasis",
  "Clever Archipelago",
  "Spanish Hotel",
  "Alabaster Farm",
  "Ferocious Railway"
]