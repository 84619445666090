import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

import * as fromStore from '../state';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(
    private snackBar: MatSnackBar,
    private store: Store<fromStore.State>
  ) { }

  public openSnackBar(message: string, action: string, duration: number, callback?: any) {
    setTimeout(() => {
      this.store.select(fromStore.selectThemeNumber).pipe(take(1)).subscribe((themeNumber) => {
        let snackbar = this.snackBar.open(message, action, {
          duration: duration,
          panelClass: themeNumber === 1 ? 'light' : ''
        });

        let afterDismissed$ = snackbar.afterDismissed();

        afterDismissed$.subscribe((data) => {
          if (callback) {
            callback(data);
          }
        });
      });
    }, 0);
  }
}
