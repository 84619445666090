import { Action } from '@ngrx/store';
import { SimpleDashboard } from '../dashboard.service';

export enum DashboardsActionTypes {
  PullDashboardsSuccessfull = 'PULL_DASHBOARDS_SUCCESSFULL',
}

export class PullDashboardsSuccessfull implements Action {
  readonly type = DashboardsActionTypes.PullDashboardsSuccessfull;

  constructor(public payload: { dashboards: Array<SimpleDashboard>, sharedAccessDashboards: Array<SimpleDashboard> }) { }
}

export type DashboardsActions =
  | PullDashboardsSuccessfull
