import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { UserService } from '../../private/user/user.service';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../state';
import { GotProfile } from '../../auth/actions/auth.actions';
import { User } from '../../private/user/user.model';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-public-layout',
  templateUrl: './public-layout.component.html',
  styleUrls: ['./public-layout.component.scss']
})
export class PublicLayoutComponent implements OnInit {
  public year: number; // Current year in YYYY format
  public pathname: string; // Current value of window.location.pathname
  public user: User;
  public authenticated: boolean;

  constructor(
    private router: Router,
    private store: Store<fromStore.State>,
    private userService: UserService,
    private authService: AuthService
  ) {
    this.year = new Date().getFullYear();

    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.pathname = window.location.pathname;
      }
    });
  }

  ngOnInit() {
    // get user data from store and subscribe to changes
    this.store.select(fromStore.selectUser).subscribe((dat) => {
      this.user = dat;
      this.authenticated = this.authService.authenticated();
      if (!this.authenticated) {
        localStorage.clear();
        this.authService.destroyCookies();
      }
    });
  }

}
