import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { CanActivate, Router } from '@angular/router';
import { of, Observable } from 'rxjs';
import { mergeMap, map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { AuthService } from '../routes/auth/auth.service';
import * as fromStore from '../state';

@Injectable({
  providedIn: 'root'
})
export class OnboardedGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private store: Store<fromStore.State>,
    private router: Router,
    private location: Location
  ) { }

  canActivate(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (!this.authService.authenticated()) {
        resolve(false)
      } else {
        this.checkStoreOnboarded().subscribe((result) => {
          if (result) {
            resolve(true);
          } else {
            this.router.navigate(['/welcome']);
            resolve(false);
          }
        });
      }
    });
  }

  checkStoreOnboarded() {
    return this.store.select(fromStore.selectIsOnboarded).pipe(take(1));
  }
}
