import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import { environment } from '../../environments/environment';
import * as fromAuth from './auth.reducer';
import * as fromQuickStats from '../routes/private/quick-stats/reducers/quick-stats.reducer';
import * as fromBooks from '../routes/private/books/reducers/books.reducer';
import * as fromNotifications from '../routes/private/notifications/reducers/notifications.reducer';
import * as fromDashboards from '../routes/private/reports/reducers/dashboards.reducer';

export interface State {
  auth: fromAuth.State;
  quickStats: fromQuickStats.State,
  books: fromBooks.State,
  notifications: fromNotifications.State,
  dashboards: fromDashboards.State
}

export const reducers: ActionReducerMap<State> = {
  auth: fromAuth.reducer,
  quickStats: fromQuickStats.reducer,
  books: fromBooks.reducer,
  notifications: fromNotifications.reducer,
  dashboards: fromDashboards.reducer
};

export const metaReducers: MetaReducer<State>[] = !environment.production
  ? []
  : [];

export const selectAuthState = createFeatureSelector<fromAuth.State>('auth');
export const selectQuickStatsState = createFeatureSelector<fromQuickStats.State>('quickStats');
export const selectBooksState = createFeatureSelector<fromBooks.State>('books');
export const selectNotificationsState = createFeatureSelector<fromNotifications.State>('notifications');
export const selectDashboardsState = createFeatureSelector<fromDashboards.State>('dashboards');

export const selectIsLoggedIn = createSelector(
  selectAuthState,
  fromAuth.selectIsLoggedIn
);

export const selectIsOnboarded = createSelector(
  selectAuthState,
  fromAuth.selectIsOnboarded
)

export const selectHasSubscription = createSelector(
  selectAuthState,
  fromAuth.selectHasSubscription
)

export const selectIsSponsored = createSelector(
  selectAuthState,
  fromAuth.selectIsSponsored
)

export const selectIsAgency = createSelector(
  selectAuthState,
  fromAuth.selectIsAgency
)

export const selectIsAgencyLimitedSponsor = createSelector(
  selectAuthState,
  fromAuth.selectIsAgencyLimitedSponsor
)

export const selectToken = createSelector(
  selectAuthState,
  fromAuth.selectToken
);

export const selectUser = createSelector(
  selectAuthState,
  fromAuth.selectUser
)

export const selectUserLastPull = createSelector(
  selectAuthState,
  fromAuth.selectUserLastPull
)

export const selectThemeNumber = createSelector(
  selectAuthState,
  fromAuth.selectThemeNumber
)

export const selectQuickStats = createSelector(
  selectQuickStatsState,
  fromQuickStats.selectQuickStats
)

export const selectBooks = createSelector(
  selectBooksState,
  fromBooks.selectBooks
)

export const selectNotifications = createSelector(
  selectNotificationsState,
  fromNotifications.selectNotifications
)

export const selectDashboards = createSelector(
  selectDashboardsState,
  fromDashboards.selectDashboards
)
