import { ConnectablePlatforms, SyncDates } from "../user/user.model";

export interface SharedAccessGrantRequest {
  _id?: string;
  owner?: string;
  requestingEmail?: string;
  target: string;
  requestedPermissions: Array<SharedAccessPermission>;
  invite: boolean;
  approved: boolean;
  declined: boolean;
  sent: boolean;
}

export interface SharedAccessAccount {
  _id: string;
  email: string;
  permissions: Array<string>;
  syncDates: SyncDates;
  connectedPlatforms: ConnectablePlatforms;

  requestingName?: string;
  verified?: boolean;
  name?: string;

  lastSalesSyncDate?: Date;
}

export const SHARED_ACCESS_PERMISSIONS = ['PROFILE','BOOKS_READ','BOOKS_WRITE','ADS_READ','ADS_WRITE','REPORTS_READ','REPORTS_WRITE','EXPENSES_READ','EXPENSES_WRITE','SALES_READ','SALES_WRITE','CUSTOM_VARIABLE_READ','CUSTOM_VARIABLE_WRITE','TRIGGER_SYNC'] as const;
export type SharedAccessPermission = typeof SHARED_ACCESS_PERMISSIONS[number];

type PermissionDefinitions = {
  [key in SharedAccessPermission]: {
    topic: string;
    level: 'READ' | 'WRITE' | 'NA';
    required: boolean;
    explanation: string;
    dependencies?: Array<SharedAccessPermission>;
  }
};

export const PERMISSION_DEFINITIONS: PermissionDefinitions = {
  'PROFILE': {
    topic: 'Profile',
    level: 'READ',
    required: true,
    explanation: 'Basic information about your user profile. Including your email address, preferred currency, your last sync dates, and which platforms you have connected.'
  },
  'BOOKS_READ': {
    topic: 'Books',
    level: 'READ',
    required: false,
    explanation: 'Allows for viewing of your bookshelf. Including each books title, author, and tags.'
  },
  'BOOKS_WRITE': {
    topic: 'Books',
    level: 'WRITE',
    required: false,
    explanation: 'Allows for editing of your bookshelf. This lets the grantee to change your book title, author, tags, and allows them to merge books. These settings are only reflected in PublishWide.',
    dependencies: ['BOOKS_READ']
  },
  'ADS_READ': {
    topic: 'Ads',
    level: 'READ',
    required: false,
    explanation: 'Allows for viewing of you ads.',
    dependencies: ['BOOKS_READ']
  },
  'ADS_WRITE': {
    topic: 'Ads',
    level: 'WRITE',
    required: false,
    explanation: 'Allows for editing of your ads.',
    dependencies: ['ADS_READ']
  },
  'REPORTS_READ': {
    topic: 'Reports',
    level: 'READ',
    required: false,
    explanation: 'Allows the grantee to view your reports'
  },
  'REPORTS_WRITE': {
    topic: 'Reports',
    level: 'WRITE',
    required: false,
    explanation: 'Allows the grantee to create reports.',
    dependencies: ['REPORTS_READ']
  },
  'EXPENSES_READ': {
    topic: 'Manual Expenses',
    level: 'READ',
    required: false,
    explanation: 'Allows the grantee to view your expenses.',
    dependencies: ['BOOKS_READ']
  },
  'EXPENSES_WRITE': {
    topic: 'Manual Expenses',
    level: 'WRITE',
    required: false,
    explanation: 'Allows the grantee to add and edit expenses.',
    dependencies: ['EXPENSES_READ']
  },
  'SALES_READ': {
    topic: 'Manual Income',
    level: 'READ',
    required: false,
    explanation: 'Allows the grantee to view your income.',
    dependencies: ['BOOKS_READ']
  },
  'SALES_WRITE': {
    topic: 'Manual Income',
    level: 'WRITE',
    required: false,
    explanation: 'Allows the grantee to add and edit income.',
    dependencies: ['SALES_READ']
  },
  'CUSTOM_VARIABLE_READ': {
    topic: 'Custom Variables',
    level: 'READ',
    required: false,
    explanation: 'Allows the grantee to read your custom variables'
  },
  'CUSTOM_VARIABLE_WRITE': {
    topic: 'Custom Variables',
    level: 'WRITE',
    required: false,
    explanation: 'Allows the grantee to create custom variables.',
    dependencies: ['CUSTOM_VARIABLE_READ']
  },
  'TRIGGER_SYNC': {
    topic: 'Trigger Sync',
    level: 'NA',
    required: false,
    explanation: 'Allows the grantee to trigger a manual sync of your data.'
  },
}

export interface SharedAccessGrantee {
  _id: string;
  name: string;
  permissions: Array<SharedAccessPermission>;
}