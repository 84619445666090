import { AuthActions, AuthActionTypes } from '../routes/auth/actions/auth.actions';
import { Action } from '@ngrx/store';
import { User } from '../routes/private/user/user.model';

export interface State {
  isLoggedIn: boolean;
  token: string;
  user?: User;
  lastPull: Date;
}

export const initialState: State = {
  isLoggedIn: false,
  token: null,
  user: null,
  lastPull: null,
};

export function reducer(state = initialState, action: AuthActions): State {
  switch (action.type) {
    case AuthActionTypes.LoginSuccess:
      return {
        ...state,
        isLoggedIn: true,
        token: action.payload
      };

    case AuthActionTypes.GotProfile:
      if (!document.cookie.includes('adminUser=true;')) {
        try {
          (window as any).Intercom("update", {
            email: action.payload.email,
            user_id: action.payload._id,
            user_hash: action.payload.intercomVerification,
            name: action.payload.name,
            created_at: action.payload.createdAt,
            'Email Verified': action.payload.emailVerified,
            'Onboarded': action.payload.onboarded,
            'Connected KDP': action.payload.connectedPlatforms ? action.payload.connectedPlatforms.amazonKdp : undefined,
            'Connected Amazon Ads': action.payload.connectedPlatforms ? action.payload.connectedPlatforms.amazonAds : undefined,
            'Connected Facebook': action.payload.connectedPlatforms ? action.payload.connectedPlatforms.facebook : undefined,
            'Connected Barnes & Noble': action.payload.connectedPlatforms ? action.payload.connectedPlatforms.barnesAndNoble : undefined,
            'Connected Draft2Digital': action.payload.connectedPlatforms ? action.payload.connectedPlatforms.draft2digital : undefined,
            'Has Subscription': action.payload.subscription ? action.payload.subscription.hasSubscription : undefined,
            'Free Trial': action.payload.subscription ? action.payload.subscription.freeTrial : undefined,
            'Subscription Ends': action.payload.subscription ? new Date(action.payload.subscription.periodEnd) : undefined,
            'Invited By Code': action.payload.invitedByCode,
            hide_default_launcher: action.payload.subscription && action.payload.subscription.subscriptionType == '1001'
          });
        } catch (err) {
          console.error(err);
        }
      }
      return {
        ...state,
        user: action.payload,
        lastPull: new Date()
      }

    case AuthActionTypes.LogoutConfirmed:
      return initialState; // the initial state has isLoggedIn set to false

    default:
      return state;
  }
}

export const selectIsLoggedIn = (state: State) => state.isLoggedIn;

export const selectIsOnboarded = (state: State) => state.user ? state.user.onboarded : false;

export const selectHasSubscription = (state: State) => state.user && state.user.subscription ? state.user.subscription.hasSubscription : false;

export const selectIsSponsored = (state: State) => state.user && state.user.subscription && state.user.subscription.sponsored;

export const selectIsAgency = (state: State) => state.user && state.user.subscription ? state.user.subscription.subscriptionType == '1001' : false;

export const selectIsAgencyLimitedSponsor = (state: State) => state.user && state.user.subscription ? state.user.subscription.subscriptionType == '1002' : false;

export const selectToken = (state: State) => state.token;

export const selectUser = (state: State) => state.user;

export const selectUserLastPull = (state: State) => state.lastPull;

export const selectThemeNumber = (state: State) => state.user && state.user.settings ? state.user.settings.theme : 0;
