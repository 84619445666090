<app-onboarding-nav></app-onboarding-nav>

<!-- ---------------------------------------------------------------- -->
<!-- Page Zero -->
<!-- ---------------------------------------------------------------- -->
<ng-container *ngIf="onboardingPage==0">
  <div class="center-center">
    <form class="onboarding-form">
      <h1><b>{{ agencyInvite.requestingName }}</b><ng-container *ngIf="agencyInvite.verified">&nbsp;<span class="verified" title="Verified by PublishWide"><i class="fas fa-check"></i></span></ng-container> has invited you to share your data via PublishWide.</h1>
      <p>Continuing will give {{ agencyInvite.requestingName }} access to all the data you sync.</p>
      <div class="button-section">
        <button class="button button--continue button-low-contrast" (click)="declineAgencyInvite()">Decline</button>
        <button class="button button--continue" (click)="acceptAgencyInvite()">Accept</button>
      </div>
    </form>
  </div>
</ng-container>
<!-- ---------------------------------------------------------------- -->
<!-- END Page Zero -->
<!-- ---------------------------------------------------------------- -->

<!-- ---------------------------------------------------------------- -->
<!-- Page One -->
<!-- ---------------------------------------------------------------- -->
<ng-container *ngIf="onboardingPage==1">
  <div class="center-center">
    <form class="onboarding-form">
      <h1>What platforms do you use?</h1>
      <div class="split">
        <div>
          <div><input type="checkbox" id="amazonKdp" [checked]="onboardingIntent.sales.amazonKdp" (change)="onboardingIntent.sales.amazonKdp = !onboardingIntent.sales.amazonKdp"/><label for="amazonKdp">Amazon KDP</label></div>
          <div><input type="checkbox" id="appleBooks" [checked]="onboardingIntent.sales.appleBooks" (change)="onboardingIntent.sales.appleBooks= !onboardingIntent.sales.appleBooks"><label for="appleBooks">Apple Books</label></div>
          <div><input type="checkbox" id="barnesAndNoble" [checked]="onboardingIntent.sales.barnesAndNoble" (change)="onboardingIntent.sales.barnesAndNoble = !onboardingIntent.sales.barnesAndNoble"><label for="barnesAndNoble">Barnes & Noble</label></div>
          <div><input type="checkbox" id="draft2digital" [checked]="onboardingIntent.sales.draft2digital" (change)="onboardingIntent.sales.draft2digital = !onboardingIntent.sales.draft2digital"><label for="draft2digital">Draft2Digital</label></div>
          <div><input type="checkbox" id="googleBooks" [checked]="onboardingIntent.sales.googleBooks" (change)="onboardingIntent.sales.googleBooks = !onboardingIntent.sales.googleBooks"><label for="googleBooks">Google Books</label></div>
          <div><input type="checkbox" id="kobo" [checked]="onboardingIntent.sales.kobo" (change)="onboardingIntent.sales.kobo = !onboardingIntent.sales.kobo"><label for="kobo">Kobo</label></div>
        </div>
        <div>
          <div><input type="checkbox" id="amazonAds" [checked]="onboardingIntent.expenses.amazonAds" (change)="onboardingIntent.expenses.amazonAds = !onboardingIntent.expenses.amazonAds"><label for="amazonAds">Amazon Ads</label></div>
          <div><input type="checkbox" id="facebook" [checked]="onboardingIntent.expenses.facebook" (change)="onboardingIntent.expenses.facebook = !onboardingIntent.expenses.facebook"><label for="facebook">Facebook</label></div>
        </div>
      </div>
      <button class="button button--continue" (click)="continue()" [disabled]="!selectedASalesPlatform()">Continue</button>
    </form>
  </div>
</ng-container>
<!-- ---------------------------------------------------------------- -->
<!-- END Page One -->
<!-- ---------------------------------------------------------------- -->

<!-- ---------------------------------------------------------------- -->
<!-- Page Two -->
<!-- ---------------------------------------------------------------- -->
<ng-container *ngIf="onboardingPage==2">
  <div class="center-center">
    <form class="onboarding-form" (ngSubmit)="verifyEmail()">
      <ng-container *ngIf="!user.emailVerified">
        <h1>Your confirmation code is waiting for you in your email box!</h1>
        <p>Enter the 6-digit code we sent to <b>{{ user.email }}</b> to confirm your email address.</p>
        <label for="confirmationCode" class="input-label"><span>Confirmation Code</span>
          <input type="text" placeholder="XXXXXX" id="confirmationCode" [formControl]="verifyForm.controls['confirmationCode']" />
          <span *ngIf="verifyForm.controls['confirmationCode'].errors?.pattern" class="input-error">Invalid Code</span>
        </label>
        <p>Didn’t get any code? <button type="button" class="button button-text" (click)="sendVerificationEmail()">Send another one</button></p>
        <div class="button-section">
          <button type="button" class="button button--back button-low-contrast" (click)="back()">Back</button>
          <button type="submit" class="button button--continue" (click)="continue()" [disabled]="!verifyForm.valid">Continue</button>
        </div>
      </ng-container>
      <ng-container *ngIf="user.emailVerified">
        <h1>Email Verified!</h1>
        <p>Your email address has been verified!</p>
        <div class="button-section">
          <button class="button button--back button-low-contrast" (click)="back()">Back</button>
          <button class="button button--continue" (click)="continue()">Continue</button>
        </div>
      </ng-container>
    </form>
  </div>
</ng-container>
<!-- ---------------------------------------------------------------- -->
<!-- END Page Two -->
<!-- ---------------------------------------------------------------- -->

<!-- ---------------------------------------------------------------- -->
<!-- Page Three -->
<!-- ---------------------------------------------------------------- -->
<ng-container *ngIf="onboardingPage==3">
  <div class="center-center">
    <form class="onboarding-form">
      <h2>TOS</h2>
      <div class="tos-wrap">
        <markdown [src]="'assets/legal/terms.md'" (load)="onLoad($event)" (error)="onError($event)"></markdown>
      </div>
      <h2>Privacy</h2>
      <div class="tos-wrap">
        <markdown [src]="'assets/legal/privacy.policy.md'" (load)="onLoad($event)" (error)="onError($event)"></markdown>
      </div>
      <div class="button-section">
        <button class="button button--back button-low-contrast" (click)="back()">Back</button>
        <button class="button button--continue" (click)="continue()" *ngIf="showAgree">Agree & Continue</button>
      </div>
    </form>
  </div>
</ng-container>
<!-- ---------------------------------------------------------------- -->
<!-- END Page Three -->
<!-- ---------------------------------------------------------------- -->

<!-- ---------------------------------------------------------------- -->
<!-- Page Four -->
<!-- ---------------------------------------------------------------- -->
<!-- <ng-container *ngIf="onboardingPage==4">
  <div class="center-center">
    <div class="onboarding-form">
      <h1>Light Side or Dark Side?</h1>
      <div class="button-toggle">
        <button [ngClass]="{'button-toggle__option--selected': theme == 1}" class="button-toggle__option" (click)="setTheme(1)">Light Side</button>
        <button [ngClass]="{'button-toggle__option--selected': theme == 0}" class="button-toggle__option" (click)="setTheme(0)" title="Unlimited Power">Dark Side</button>
      </div>
      <button class="button button--continue" (click)="continue()">Continue</button>
    </div>
  </div>
</ng-container> -->
<!-- ---------------------------------------------------------------- -->
<!-- END Page Four -->
<!-- ---------------------------------------------------------------- -->

<!-- ---------------------------------------------------------------- -->
<!-- Page Five -->
<!-- ---------------------------------------------------------------- -->
<ng-container *ngIf="onboardingPage==5">
  <div class="center-center">
    <div class="onboarding-form">
      <h1>What currency do you work with?</h1>
      <div class="mode-toggle">
        <div class="option" [ngClass]="{'active': !userSettings.currency || userSettings.currency ==='USD'}" (click)="setCurrency('USD')">USD</div>
        <div class="option" [ngClass]="{'active': userSettings.currency ==='AUD'}" (click)="setCurrency('AUD')">AUD</div>
        <div class="option" [ngClass]="{'active': userSettings.currency ==='BRL'}" (click)="setCurrency('BRL')">BRL</div>
        <div class="option" [ngClass]="{'active': userSettings.currency ==='CAD'}" (click)="setCurrency('CAD')">CAD</div>
        <div class="option" [ngClass]="{'active': userSettings.currency ==='EUR'}" (click)="setCurrency('EUR')">EUR</div>
        <div class="option" [ngClass]="{'active': userSettings.currency ==='GBP'}" (click)="setCurrency('GBP')">GBP</div>
        <div class="option" [ngClass]="{'active': userSettings.currency ==='INR'}" (click)="setCurrency('INR')">INR</div>
        <div class="option" [ngClass]="{'active': userSettings.currency ==='JPY'}" (click)="setCurrency('JPY')">JPY</div>
        <div class="option" [ngClass]="{'active': userSettings.currency ==='MXN'}" (click)="setCurrency('MXN')">MXN </div>
        <!-- <div class="option" [ngClass]="{'active': userSettings.currency ==='PLN'}" (click)="setCurrency('PLN')">PLN </div>
        <div class="option" [ngClass]="{'active': userSettings.currency ==='CNY'}" (click)="setCurrency('CNY')">CNY </div>
        <div class="option" [ngClass]="{'active': userSettings.currency ==='EGP'}" (click)="setCurrency('EGP')">EGP </div>
        <div class="option" [ngClass]="{'active': userSettings.currency ==='SAR'}" (click)="setCurrency('SAR')">SAR </div>
        <div class="option" [ngClass]="{'active': userSettings.currency ==='SGD'}" (click)="setCurrency('SGD')">SGD </div>
        <div class="option" [ngClass]="{'active': userSettings.currency ==='SEK'}" (click)="setCurrency('SEK')">SEK </div>
        <div class="option" [ngClass]="{'active': userSettings.currency ==='TRY'}" (click)="setCurrency('TRY')">TRY </div>
        <div class="option" [ngClass]="{'active': userSettings.currency ==='AED'}" (click)="setCurrency('AED')">AED </div> -->
      </div>
      <div class="button-section">
        <button class="button button--back button-low-contrast" (click)="back()">Back</button>
        <button class="button button--continue" (click)="continue()">Continue</button>
      </div>
    </div>
  </div>
</ng-container>
<!-- ---------------------------------------------------------------- -->
<!-- END Page Five -->
<!-- ---------------------------------------------------------------- -->

<!-- ---------------------------------------------------------------- -->
<!-- Page Six -->
<!-- ---------------------------------------------------------------- -->
<ng-container *ngIf="onboardingPage==6">
  <div class="center-center">
    <form class="onboarding-form">
      <div *ngIf="!facebookLinked && !facebookMissingPermissions && !reauthenticationNeeded">
        <h1>Connect Facebook</h1>
        <button class="button" (click)="loginWithFacebook()">Continue with Facebook</button>
        <div class="button-section">
          <button class="button button--back button-low-contrast" (click)="back()">Back</button>
          <button class="button button--continue" (click)="continue()">Skip</button>
        </div>
      </div>
      <ng-container *ngIf="facebookMissingPermissions">
        <h1>Unable to access your Facebook Ads!</h1>
        <p>Please make sure you allow PublishWide access to your Facebook ads and related stats.</p>
        <button class="button" (click)="loginWithFacebook(true)">Continue with Facebook</button>
        <div class="button-section">
          <button class="button button--back button-low-contrast" (click)="back()">Back</button>
          <button class="button button--continue" (click)="continue()">Skip</button>
        </div>
      </ng-container>
      <ng-container *ngIf="reauthenticationNeeded">
        <h1>Reauthenticate Facebook</h1>
        <p>Link your Facebook account with PublishWide to unlock insights into your ad campaigns.</p>
        <button class="button" (click)="loginWithFacebook()">Continue with Facebook</button>
        <div class="button-section">
          <button class="button button--back button-low-contrast" (click)="back()">Back</button>
          <button class="button button--continue" (click)="continue()">Skip</button>
        </div>
      </ng-container>
      <ng-container *ngIf="selectAdAccount">
        <h1>Select your Facebook ad account</h1>
        <p>Select the ad account you would like to use.</p>
        <div class="ad-accounts">
          <div class="ad-account {{ adAccount.selected ? 'selected' : null }}"
            *ngFor="let adAccount of adAccounts; let i = index;" (click)="toggleAdAccount(i)">
            <h3 title="{{ adAccount.name }}">{{ adAccount.name }}
              ({{ FACEBOOK_ACCOUNT_STATUS_OPTIONS[adAccount.account_status] }})</h3>
            <h4>{{ adAccount.id }}</h4>
          </div>
        </div>
        <div class="button-section">
          <button class="button button--back button-low-contrast" (click)="back()">Back</button>
          <button class="button button--continue" (click)="selectAccount()">Continue</button>
        </div>
      </ng-container>
    </form>
  </div>
</ng-container>
<!-- ---------------------------------------------------------------- -->
<!-- END Page Six -->
<!-- ---------------------------------------------------------------- -->

<!-- ---------------------------------------------------------------- -->
<!-- Page Seven -->
<!-- ---------------------------------------------------------------- -->
<ng-container *ngIf="onboardingPage==7">
  <div class="center-center">
    <div class="onboarding-form" *ngIf="!user?.amazon?.connected">
      <h1>Connect Amazon Advertising</h1>
      <button class="button" (click)="loginWithAmazon()">Login With Amazon</button>
      <div class="button-section">
        <button class="button button--back button-low-contrast" (click)="back()">Back</button>
        <button class="button button--continue" (click)="continue()">Continue</button>
      </div>
    </div>
    <div class="onboarding-form" *ngIf="user?.amazon?.connected">
      <h1>Amazon Advertising Connected!</h1>
      <div class="button-section">
        <button class="button button--back button-low-contrast" (click)="back()">Back</button>
        <button class="button button--continue" (click)="continue()">Continue</button>
      </div>
    </div>
  </div>
</ng-container>
<!-- ---------------------------------------------------------------- -->
<!-- END Page Seven -->
<!-- ---------------------------------------------------------------- -->

<!-- ---------------------------------------------------------------- -->
<!-- Page Eight -->
<!-- ---------------------------------------------------------------- -->
<ng-container *ngIf="onboardingPage==8">
  <div class="center-center">
    <div class="onboarding-form">
      <h1>Let's sync your data!</h1>
      <p>Install our extension or our desktop app to get started.</p>
      <div class="flex flex--row">
        <ng-container *ngIf="clientPeers && clientPeers['EXTENSION']">
          <button class="button button--install" (click)="installExtension()">
            <span>Add to Chrome</span>
            <i class="fab fa-chrome"></i>
            <b>Recommended</b>
          </button>
        </ng-container>
        <ng-container *ngIf="!clientPeers || !clientPeers['EXTENSION']">
          <a class="button button--install" (click)="installExtension()" href="https://chrome.google.com/webstore/detail/publishwide-sync/ogmbfahmbffjabepohiadhikjpgjbhig" target="_blank">
            <span>Add to Chrome</span>
            <i class="fab fa-chrome"></i>
            <b>Recommended</b>
          </a>
        </ng-container>
        <a class="button button--install" (click)="installDesktop()" href="https://publishwide.com/download/mac">
          <span>Install on MacOS</span>
          <i class="fab fa-apple"></i>
        </a>
        <a class="button button--install" (click)="installDesktop()" href="https://publishwide.com/download/windows">
          <span>Install on Windows</span>
          <i class="fab fa-windows"></i>
        </a>
      </div>
      <div class="extension-detected" *ngIf="clientPeers && clientPeers['EXTENSION']">
        <h3>Extension Installed!</h3>
        <button class="button button--back button-low-contrast" (click)="back()">Back</button>
        <button class="button button--continue" (click)="continue(10)">Continue</button>
      </div>
      <div *ngIf="!clientPeers || !clientPeers['EXTENSION']">
        <div class="button-section">
          <button class="button button--back button-low-contrast" (click)="back()">Back</button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<!-- ---------------------------------------------------------------- -->
<!-- END Page Eight -->
<!-- ---------------------------------------------------------------- -->

<!-- ---------------------------------------------------------------- -->
<!-- Page Nine -->
<!-- ---------------------------------------------------------------- -->
<ng-container *ngIf="onboardingPage==9">
  <div class="center-center">
    <div class="onboarding-form">
      <ng-container *ngIf="!showFinish">
        <h1>Continue on the desktop app.</h1>
        <p>This page will update once you sync using the desktop app.</p>
      </ng-container>
      <ng-container *ngIf="showFinish">
        <h1>Data Detected!</h1>
        <p>Click finish to start looking at your data!</p>
      </ng-container>
        <div class="button-section">
          <button class="button button--back button-low-contrast" (click)="back()">Back</button>
          <button class="button button--continue" *ngIf="showFinish" (click)="finishOnboarding()">Finish</button>
        </div>
    </div>
  </div>
</ng-container>
<!-- ---------------------------------------------------------------- -->
<!-- END Page Nine -->
<!-- ---------------------------------------------------------------- -->

<!-- ---------------------------------------------------------------- -->
<!-- Page Ten -->
<!-- ---------------------------------------------------------------- -->
<ng-container *ngIf="onboardingPage==10">
  <div class="center-center">
    <div class="onboarding-form" *ngIf="!clientPeers || !clientPeers['EXTENSION']">
      <h1>Waiting for Extension</h1>
      <p>This page will automatically update once the extension is installed. <br><span class="small-text">If you need a link to install the extension or the desktop app you can click the back button below.</span></p>
      <button class="button button--back" (click)="back()">Back</button>
    </div>
    <div class="onboarding-form" *ngIf="clientPeers && clientPeers['EXTENSION']">
      <h1>Extension Detected!</h1>
      <p>Getting login states for your selected platforms. <br><b>This might take a minute.</b></p>
      <div class="flex flex--row" style="align-items: center;" *ngIf="loginStates.sales.amazonKdp">
        <app-onboarding-state *ngIf="loginStates.sales.amazonKdp" [title]="'Amazon KDP'" [state]="loginStates.sales.amazonKdp.state" [stateMessage]="loginStates.sales.amazonKdp.friendlyState"></app-onboarding-state>
        <button class="button" *ngIf="loginStates.sales.amazonKdp.state == 'loggedOut'" (click)="initAuth('amazonKdp')">Log In</button>
      </div>
      <div class="flex flex--row" style="align-items: center;" *ngIf="loginStates.sales.appleBooks">
        <app-onboarding-state *ngIf="loginStates.sales.appleBooks" [title]="'Apple Books'" [state]="loginStates.sales.appleBooks.state" [stateMessage]="loginStates.sales.appleBooks.friendlyState"></app-onboarding-state>
        <button class="button" *ngIf="loginStates.sales.appleBooks.state == 'loggedOut'" (click)="initAuth('appleBooks')">Log In</button>
      </div>
      <div class="flex flex--row" style="align-items: center;" *ngIf="loginStates.sales.barnesAndNoble">
        <app-onboarding-state *ngIf="loginStates.sales.barnesAndNoble" [title]="'Barnes & Noble'" [state]="loginStates.sales.barnesAndNoble.state" [stateMessage]="loginStates.sales.barnesAndNoble.friendlyState"></app-onboarding-state>
        <button class="button" *ngIf="loginStates.sales.barnesAndNoble.state == 'loggedOut'" (click)="initAuth('barnesAndNoble')">Log In</button>
      </div>
      <div class="flex flex--row" style="align-items: center;" *ngIf="loginStates.sales.draft2digital">
        <app-onboarding-state *ngIf="loginStates.sales.draft2digital" [title]="'Draft2Digital'" [state]="loginStates.sales.draft2digital.state" [stateMessage]="loginStates.sales.draft2digital.friendlyState"></app-onboarding-state>
        <button class="button" *ngIf="loginStates.sales.draft2digital.state == 'loggedOut'" (click)="initAuth('draft2digital')">Log In</button>
      </div>
      <div class="flex flex--row" style="align-items: center;" *ngIf="loginStates.sales.kobo">
        <app-onboarding-state *ngIf="loginStates.sales.kobo" [title]="'Kobo'" [state]="loginStates.sales.kobo.state" [stateMessage]="loginStates.sales.kobo.friendlyState"></app-onboarding-state>
        <button class="button" *ngIf="loginStates.sales.kobo.state == 'loggedOut'" (click)="initAuth('kobo')">Log In</button>
      </div>

      <div *ngIf="loginStates.sales.appleBooks">
        <h2>Select Apple Account</h2>
        <p *ngIf="!appleProviderIds">Loading...</p>
        <ng-container *ngIf="appleProviderIds">
          <div *ngFor="let appleProviderId of appleProviderIds"><input type="checkbox" [checked]="appleProviderId.selected" id="{{appleProviderId.providerId}}" (change)="appleProviderId.selected = !appleProviderId.selected" /><label for="{{appleProviderId.providerId}}">{{ appleProviderId.name }}</label></div>
        </ng-container>
      </div>

      <p *ngIf="numAuthedSalesPlatforms && numSelectedSalesPlatforms && numAuthedSalesPlatforms < numSelectedSalesPlatforms">It looks like you are signed into {{ numAuthedSalesPlatforms }} of {{ numSelectedSalesPlatforms }} selected platforms. <button class="button button-text button-text-underline" (click)="checkPlatformAuth()">Check Again</button></p>
      <div class="button-section">
        <button class="button button--back button-low-contrast" (click)="back()">Back</button>
        <button class="button button--continue" (click)="continue()" *ngIf="numAuthedSalesPlatforms">Sync {{ numAuthedSalesPlatforms }} platforms</button>
      </div>
    </div>
  </div>
</ng-container>
<!-- ---------------------------------------------------------------- -->
<!-- END Page Ten -->
<!-- ---------------------------------------------------------------- -->

<!-- ---------------------------------------------------------------- -->
<!-- Page Eleven -->
<!-- ---------------------------------------------------------------- -->
<ng-container *ngIf="onboardingPage==11">
  <div class="center-center">
    <div class="onboarding-form" *ngIf="!clientPeers || !clientPeers['EXTENSION']">
      <h1>Extension not detected!</h1>
      <p>We can't detect the extension on this computer, you may need to install it.</p>
      <button class="button button--back" (click)="back()">Back</button>
    </div>
    <div class="onboarding-form" *ngIf="clientPeers && clientPeers['EXTENSION']">
      <h1>Time to sync!</h1>
      <p>It’s time to sync all your data! <b>Depending on the size of your accounts this can take awhile.</b></p>
      <app-sync-progress *ngIf="syncSettings && syncSettings.amazonKdp" [platformName]="'Amazon KDP'" [percent]="syncProgress.amazonKdp"></app-sync-progress>
      <app-sync-progress *ngIf="syncSettings && syncSettings.appleBooks" [platformName]="'Apple Books'" [percent]="syncProgress.appleBooks"></app-sync-progress>
      <app-sync-progress *ngIf="syncSettings && syncSettings.barnesAndNoble" [platformName]="'Barnes & Noble'" [percent]="syncProgress.barnesAndNoble"></app-sync-progress>
      <app-sync-progress *ngIf="syncSettings && syncSettings.draft2digital" [platformName]="'Draft2Digital'" [percent]="syncProgress.draft2digital"></app-sync-progress>
      <app-sync-progress *ngIf="syncSettings && syncSettings.kobo" [platformName]="'Kobo'" [percent]="syncProgress.kobo"></app-sync-progress>
      <div class="button-section">
          <button class="button button--back button-low-contrast" (click)="back()">Back</button>
          <button class="button button--continue" *ngIf="showFinish" (click)="finishOnboarding()">Finish</button>
        </div>
    </div>
  </div>
</ng-container>
<!-- ---------------------------------------------------------------- -->
<!-- END Page Eleven -->
<!-- ---------------------------------------------------------------- -->