import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ApiResponse } from 'src/app/utilities/models/ApiResponse.model';
import { environment } from 'src/environments/environment';
import { PERMISSION_DEFINITIONS, SharedAccessAccount, SharedAccessGrantee, SharedAccessGrantRequest, SharedAccessPermission } from './SharedAccess.model';

@Injectable({
  providedIn: 'root'
})
export class SharedAccessService {
  // API Routes
  private accountsEndpoint = environment.apiUrl + 'sharedAccess/accounts';
  private grantsEndpoint = environment.apiUrl + 'sharedAccess/grants';
  private pendingGrantsEndpoint = environment.apiUrl + 'sharedAccess/pendingGrants';
  private granteesEndpoint = environment.apiUrl + 'sharedAccess/grantees';
  // END API Routes

  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) { }

  public getSharedAccessGrantees(): Promise<Array<SharedAccessGrantee>> {
    return new Promise((resolve, reject) => {
      this.http
        .get<ApiResponse>(this.granteesEndpoint)
        .subscribe((response) => {
          resolve(response.data);
        }, (err) => {
          reject(err);
        });
    });
  }

  public getSharedAccessRequestsWaitingApproval(page: number, pageSize: number): Promise<{ page: number, pages: number, data: Array<SharedAccessGrantRequest> }> {
    return new Promise((resolve, reject) => {
      this.http
        .get<ApiResponse>(this.pendingGrantsEndpoint, {
          params: {
            page: (page || 1).toString(),
            pageSize: (pageSize || 10).toString()
          }
        })
        .subscribe((response) => {
          resolve({
            page: response.page,
            pages: response.pages,
            data: response.data
          });
        }, (err) => {
          reject(err);
        });
    });
  }

  public getSharedAccessRequests(page: number, pageSize: number): Promise<{ page: number, pages: number, data: Array<SharedAccessGrantRequest>}> {
    return new Promise((resolve, reject) => {
      this.http
        .get<ApiResponse>(this.grantsEndpoint, {
          params: {
            page: (page || 1).toString(),
            pageSize: (pageSize || 10).toString()
          }
        })
        .subscribe((response) => {
          resolve({
            page: response.page,
            pages: response.pages,
            data: response.data
          });
        }, (err) => {
          reject(err);
        });
    });
  }

  public requestSharedAccess(emails: Array<string>, permissions: Array<SharedAccessPermission>): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .put<ApiResponse>(`${this.grantsEndpoint}`, {emails: emails, permissions: permissions})
        .subscribe((response) => {
          resolve(response.data);
        }, (err) => {
          reject(err);
        });
    });
  }

  public getSharingRequest(grantId: string): Promise<SharedAccessGrantRequest> {
    return new Promise((resolve, reject) => {
      this.http
        .get<ApiResponse>(`${this.grantsEndpoint}/${grantId}`)
        .subscribe((response) => {
          resolve(response.data);
        }, (err) => {
          reject(err);
        });
    });
  }

  public respondToSharedAccess(grantId: string, approve: boolean, grantedPermissions: Array<SharedAccessPermission>): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .post<ApiResponse>(`${this.grantsEndpoint}/${grantId}`, {
          approve: approve,
          grantedPermissions: grantedPermissions
        })
        .subscribe((response) => {
          resolve();
        }, (err) => {
          reject(err);
        });
    });
  }

  public getSharedAccounts(page: number, pageSize: number, search?: string): Promise<{ page: number, pages: number, data: Array<SharedAccessAccount>}> {
    return new Promise((resolve, reject) => {
      this.http
        .get<ApiResponse>(this.accountsEndpoint, {
          params: {
            page: (page || 1).toString(),
            pageSize: (pageSize || 10).toString(),
            search: search || ''
          }
        })
        .subscribe((response) => {
          resolve({
            data: response.data,
            page: response.page,
            pages: response.pages,
          });
        }, (err) => {
          reject(err);
        });
    });
  }

  public getSharedAccount(userId?: string): Promise<SharedAccessAccount> {
    return new Promise((resolve, reject) => {
      this.http
        .get<ApiResponse>(`${this.accountsEndpoint}/${userId || 'null'}`)
        .subscribe((response) => {
          resolve(response.data);
        }, (err) => {
          if ([404, 401, 403, 400].includes(err.status)) {
            this.cookieService.delete('sharedAccessUser', '/', `.${environment.domain}`);
          }
          reject(err);
        });
    });
  }

  public switchAccount(userId?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post<ApiResponse>(`${this.accountsEndpoint}/switch`, { user: userId })
        .subscribe((response) => {
          resolve(response.data);
        }, (err) => {
          reject(err);
        });
    });
  }

  public assumeSharedAccount(): Promise<any> {
    return new Promise((resolve, reject) => { });
  }

  /**
   * Returns an array of permissions that are required for the provided permission
   */
  public getPermissionDependencies(permission: SharedAccessPermission): Array<SharedAccessPermission> {
    let dependencies = (PERMISSION_DEFINITIONS[permission].dependencies ? PERMISSION_DEFINITIONS[permission].dependencies : []).map((dependency) => {
      return [dependency].concat(PERMISSION_DEFINITIONS[dependency].dependencies ? this.getPermissionDependencies(dependency) : []);
    });
    return dependencies.flat();
  }

  /**
   * Returns an array of permissions that must be disabled if the provided permission is disabled.
   */
  public getPermissionConflicts(permission: SharedAccessPermission): Array<SharedAccessPermission> {
    let conflicts = [];
    for (let permissionKey in PERMISSION_DEFINITIONS) {
      const dependencies = this.getPermissionDependencies(permissionKey as SharedAccessPermission);
      if (dependencies.includes(permission)) {
        conflicts.push(permissionKey);
      }
    }
    return conflicts;
  }

  public applyPermissionsDependencies(permission: SharedAccessPermission, enabled: boolean, selectedPermissions: Array<SharedAccessPermission>) {
    if (enabled) {
      const requiredPermissions = this.getPermissionDependencies(permission);
      return [...selectedPermissions, ...requiredPermissions.filter((permission) => {
        return !selectedPermissions.includes(permission);
      }), permission];
    } else {
      const permissionConflicts = this.getPermissionConflicts(permission);
      selectedPermissions.splice(selectedPermissions.indexOf(permission), 1);
      return selectedPermissions.filter((selectedPermission) => {
        return !permissionConflicts.includes(selectedPermission);
      });
    }
  }
}
