import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as fromStore from '../../state';
import { LoginComplete, LoginFailure } from './actions/auth.actions';
import { environment } from 'src/environments/environment';
import { concatMap, take } from 'rxjs/operators';
import { ApiResponse } from 'src/app/utilities/models/ApiResponse.model';
import { SnackbarService } from 'src/app/utilities/snackbar.service';
import { UserService } from '../private/user/user.service';
import { CookieService } from 'ngx-cookie-service';
import * as jwtDecode from 'jwt-decode';
import { EMPTY, from, of } from 'rxjs';
declare var FB: any;

@Injectable()
export class AuthService {

  // API Routes
  private isInviteCodeValidEndpoint = environment.apiUrl + 'auth/inviteCode';
  private signUpEndpoint = environment.apiUrl + 'auth/signup';
  private resetPasswordEndpoint = environment.apiUrl + 'auth/reset';
  private verifyEmailEndpoint = environment.apiUrl + 'auth/verify';
  private loginEndpoint = environment.apiUrl + 'auth/login';
  private logoutEndpoint = environment.apiUrl + 'auth/logout';
  private facebookAuthEndpoint = environment.apiUrl + 'auth/facebook';
  // END API Routes

  constructor(
    private router: Router,
    private store: Store<fromStore.State>,
    private http: HttpClient,
    private snackbarService: SnackbarService,
    private userService: UserService,
    private cookieService: CookieService
  ) { }

  public logout():Promise<boolean> {
    return new Promise((resolve) => {
      this.destroyCookies();
      localStorage.clear();
      console.clear();

      (window as any).Intercom('shutdown');

      this.http.get<ApiResponse>(this.logoutEndpoint).pipe(take(1)).subscribe((response) => {
        window.location.href = window.location.origin;
        return resolve(true);
      }, (err) => {
        window.location.href = window.location.origin;
        return resolve(true);
      });
    });
  }

  public authenticated() {
    try {
      let pwa1 = this.cookieService.get('pwa1');

      let token = `${pwa1}`;
      if (pwa1) {
        let decoded = jwtDecode(token);
        return (decoded.exp * 1000) > Date.now();
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  }

  public login(email, password) {
    return new Promise((resolve, reject) => {
      this.http.post<ApiResponse>(this.loginEndpoint, {
        email: email,
        password: password
      }).pipe(take(1)).subscribe((response) => {
        resolve();
      }, (err) => {
        reject(err);
      });
    });
  }

  public checkInviteCode(inviteCode: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post<ApiResponse>(this.isInviteCodeValidEndpoint, {
        inviteCode: inviteCode
      })
      .pipe(take(1)).subscribe((response) => {
        resolve(response.data.codeValid);
      }, (err) => {
        reject();
      })
    });
  }

  public signUp(formData: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post<ApiResponse>(this.signUpEndpoint, formData)
      .pipe(take(1)).subscribe((response) => {
        resolve(true);
      }, (err) => {
        reject();
      })
    });
  }

  public sendResetRequest(formData): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post<ApiResponse>(this.resetPasswordEndpoint, formData)
      .pipe(take(1)).subscribe((response) => {
        resolve(response.success);
      }, (err) => {
        reject();
      });
    });
  }

  public destroyCookies(): void {
    const cookiesToKeep = ['PWPUB'];
    let cookies = this.cookieService.getAll();
    for (let key in cookies) {
      if (!cookiesToKeep.includes(key)) {
        this.cookieService.delete(key, '/', `.${environment.domain}`);
      }
    }
  }

  public connectFacebook(rerequest?: boolean): Promise<string> {
    const requiredScopes = ['ads_read'];
    return new Promise((resolve, reject) => {
      this.facebookLogin(rerequest).pipe(take(1)).subscribe((authResponse) => {
        if (this.isMissingFacebookScopes(authResponse.grantedScopes.split(','), requiredScopes)) {
          return reject('MISSING_PERMISSIONS');
        } else {
          this.http.post<ApiResponse>(this.facebookAuthEndpoint, {
            code: authResponse.accessToken
          }).pipe(take(1)).subscribe((response) => {
            return resolve('CONNECTED');
          }, (err) => {
            reject(err);
          });
        }
      }, (err) => {
        return reject(err);
      });
    })
  }

  private facebookLogin(rerequest?: boolean) {
    if (rerequest) {
      return from(new Promise<fb.StatusResponse>(resolve => FB.login(resolve, {
        scope: 'ads_read',
        return_scopes: true,
        auth_type: 'rerequest'
      })))
        .pipe(concatMap(({ authResponse }) => {
          if (!authResponse) return EMPTY;
          return of(authResponse);
        }));
    } else {
      return from(new Promise<fb.StatusResponse>(resolve => FB.login(resolve, {
        scope: 'email,public_profile,ads_read',
        return_scopes: true
      })))
        .pipe(concatMap(({ authResponse }) => {
          if (!authResponse) return EMPTY;
          return of(authResponse);
        }));
    }
  }

  private isMissingFacebookScopes(grantedScopes: Array<string>, requiredScopes: Array<string>) {
    for (const requiredScope of requiredScopes) {
      if (!grantedScopes.includes(requiredScope)) {
        return true;
      }
    }
    return false;
  }
}
