import { Action } from '@ngrx/store';
import { Notification } from '../models/Notification.model';

export enum NotificationsActionTypes {
  PullNotificationsSuccessfull = 'PULL_NOTIFICATIONS_SUCCESSFULL',
  ViewedNotifications = 'VIEWED_NOTIFICATIONS'
}

export class PullNotificationsSuccessfull implements Action {
  readonly type = NotificationsActionTypes.PullNotificationsSuccessfull;

  constructor(public payload: Array<Notification>) { }
}

export class ViewedNotifications implements Action {
  readonly type = NotificationsActionTypes.ViewedNotifications;
}

export type NotificationsActions =
  | PullNotificationsSuccessfull
  | ViewedNotifications
