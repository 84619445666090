import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DataLayerCustomDimensionsService, PartialCustomDimensionsSet } from './data-layer-custom-dimensions.service';

@Injectable({
  providedIn: 'root'
})
export class UniversalAnalyticsService {

  constructor(
    private dataLayerCustomDimensions: DataLayerCustomDimensionsService,
    private router: Router
  ) { }

  public pageView(title: string, someDimensions: PartialCustomDimensionsSet) {
    this.dataLayerCustomDimensions.dimesnions = someDimensions;
    this.dataLayerCustomDimensions.trigger();
    (window as any).dataLayer.push({
      event: 'virtualPageview',
      virtualPageURL: this.router.url,
      virtualPageTitle: title || this.router.url
    });
  }
  
}