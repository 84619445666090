import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ApiResponse } from '../../../utilities/models/ApiResponse.model';
import { Notification } from './models/Notification.model';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../state';
import { PullNotificationsSuccessfull } from './actions/notifications.actions';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  // API Routes
  private notificationsEndpoint = environment.apiUrl + 'notifications';
  private removeNotificationEndpoint = environment.apiUrl + 'notification/remove';
  // END API Routes

  constructor(
    private http: HttpClient,
    private store: Store<fromStore.State>
  ) { }

  /**
   * Returns promise with array of Notifications, also stores
   * this data in the store.
   */
  public getNotifications(): Promise<Array<Notification>> {
    return new Promise((resolve, reject) => {
      this.http
        .get<ApiResponse>(this.notificationsEndpoint)
        .subscribe((response) => {
          this.store.dispatch(new PullNotificationsSuccessfull(response.data));
          resolve(response.data);
        }, (err) => {
          reject(err);
        });
    });
  }

  public dismissNotification(notificationId: string): Promise<Array<Notification>> {
    return new Promise((resolve, reject) => {
      this.http
        .post<ApiResponse>(this.removeNotificationEndpoint, { notification: notificationId })
        .subscribe((response) => {
          this.store.dispatch(new PullNotificationsSuccessfull(response.data));
          resolve(response.data);
        }, (err) => {
          reject();
        });
    })
  }

}
