import { Injectable, OnDestroy } from '@angular/core';
import { Subject, interval, throwError, of, Observable, Subscription } from '../../../../../node_modules/rxjs';
import { takeUntil, take, delay, mergeMap, retryWhen, takeWhile } from '../../../../../node_modules/rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ApiResponse } from '../../../utilities/models/ApiResponse.model';
import { User } from './user.model';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../state';
import { environment } from '../../../../environments/environment';
import { GotProfile } from '../../auth/actions/auth.actions';
import { SnackbarService } from 'src/app/utilities/snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class UserService implements OnDestroy {

  private unsubscribe: Subject<void> = new Subject;

  // API Routes
  private selfEndpoint = environment.apiUrl + 'self';
  private finishOnboardingEndpoint = environment.apiUrl + 'user/finishOnboarding'
  private sendVerificationEmailEndpoint = environment.apiUrl + 'auth/resendVerify';
  private verifyEmailEndpoint = environment.apiUrl + 'auth/verify';
  private deleteDataEndpoint = environment.apiUrl + 'account/deleteData';
  private putSettingsEndpoint = environment.apiUrl + 'account/settings';
  private putNameEndpiont = environment.apiUrl + 'account/name';
  private eligibleSubscriptionEndpoint = environment.apiUrl + 'account/eligibleSubscriptions';
  private subscriptionEndpoint = environment.apiUrl + 'account/subscription';
  private checkoutSessionEndpoint = environment.apiUrl + 'account/checkout';
  private checkoutUpdateSessionEndpoint = environment.apiUrl + 'account/checkout/update';
  private feedbackEndpoint = environment.apiUrl + 'account/feedback';
  private initializeDestkopAppOnboardingEndpoint = environment.apiUrl + 'user/initDaOnboarding';
  private initializeExtensionOnboardingEndpoint = environment.apiUrl + 'user/initExtOnboarding';
  private allowAccountAccessEndpoint = environment.apiUrl + 'account/accountAccess';
  private completeAmazonAuthEndpoint = environment.apiUrl + 'auth/amazon';
  private onboardingSelectedPlatformsEndpoint = environment.apiUrl + 'user/onboardingPlatforms';
  // END API Routes

  constructor(
    private http: HttpClient,
    private store: Store<fromStore.State>,
    private snackbarService: SnackbarService
  ) { }

  ngOnDestroy() {
    this.unsub();
  }

  /**
   * Unsubscribe to subscriptions
   */
  private unsub() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public checkUserForChanges(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http.get<User>(this.selfEndpoint)
        .pipe(take(1))
        .subscribe((user: User) => {
          this.store.dispatch(new GotProfile(user));
          let userString = JSON.stringify(user);

          this.http.get<User>(this.selfEndpoint)
            .pipe(
              delay(2000),
              mergeMap(val => {
                // check to see if the user is the same as when first checked
                if (userString === JSON.stringify(val)) {
                  return throwError('Error!');
                }

                return of(val);
              }),
              retryWhen(errors =>
                errors.pipe(delay(2000))
              ),
              takeUntil(this.unsubscribe)
            ).subscribe((res: User) => {
              this.store.dispatch(new GotProfile(res));
              resolve();
            }, (err) => {
              reject();
            });
        });
    });
  }

  public checkForFacebookConnection(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.getUserStoreObservable().pipe(take(1)).subscribe((user) => {
        if (user.connectedPlatforms.facebook) {
          resolve();
        } else {
          this.http.get<User>(this.selfEndpoint)
            .pipe(
              delay(2000),
              mergeMap((val: User) => {
                if (!val.connectedPlatforms.facebook) {
                  return throwError('Error!');
                }

                return of(val);
              }),
              retryWhen(errors =>
                errors.pipe(delay(2000))
              ),
              takeUntil(this.unsubscribe)
            ).subscribe((res: User) => {
              this.store.dispatch(new GotProfile(res));
              resolve();
            }, (err) => {
              reject();
            });
        }
      }, () => {
        reject();
      });
    });
  }

  public checkForAmazonAdsActivity(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.getUserStoreObservable().pipe(take(1)).subscribe((user) => {
        if (user.connectedPlatforms.amazonAds) {
          resolve();
        } else {
          this.http.get<User>(this.selfEndpoint)
            .pipe(
              delay(15000),
              mergeMap((val: User) => {
                if (!val.connectedPlatforms.amazonAds) {
                  return throwError('Error!');
                }

                return of(val);
              }),
              retryWhen(errors =>
                errors.pipe(delay(2000))
              ),
              takeUntil(this.unsubscribe)
            ).subscribe((res: User) => {
              this.store.dispatch(new GotProfile(res));
              resolve();
            }, (err) => {
              reject();
            });
        }
      }, () => {
        reject();
      });
    });
  }

  public checkForFirstSalesSourceSync(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.getUserStoreObservable().pipe(take(1)).subscribe((user) => {
        if (user.connectedPlatforms && (user.connectedPlatforms.amazonKdp || user.connectedPlatforms.barnesAndNoble || user.connectedPlatforms.appleBooks || user.connectedPlatforms.draft2digital || user.connectedPlatforms.kobo || user.connectedPlatforms.googleBooks)) {
          resolve();
        } else {
          this.http.get<User>(this.selfEndpoint)
            .pipe(
              delay(15000),
              mergeMap((val: User) => {
                if (!val.connectedPlatforms || (!val.connectedPlatforms.amazonKdp && !val.connectedPlatforms.barnesAndNoble && !val.connectedPlatforms.appleBooks && !val.connectedPlatforms.draft2digital && !val.connectedPlatforms.kobo && !val.connectedPlatforms.googleBooks)) {
                  return throwError('Error!');
                }

                return of(val);
              }),
              retryWhen(errors =>
                errors.pipe(delay(2000))
              ),
              takeUntil(this.unsubscribe)
            ).subscribe((res: User) => {
              this.store.dispatch(new GotProfile(res));
              resolve();
            }, (err) => {
              reject();
            });
        }
      });
    });
  }

  public checkForFbSyncLockResolved(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http.get<User>(this.selfEndpoint)
        .pipe(
          delay(10000),
          mergeMap((user: User) => {
            if (user.facebook.syncLock) {
              return throwError('Error!');
            }

            return of(user);
          }),
          retryWhen(errors =>
            errors.pipe(delay(2000))
          ),
          takeUntil(this.unsubscribe)
        ).subscribe((res: User) => {
          this.store.dispatch(new GotProfile(res));
          resolve();
        }, (err) => {
          reject();
        });
    });
  }

  public checkForAmazonSyncLockResolved(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http.get<User>(this.selfEndpoint)
        .pipe(
          delay(10000),
          mergeMap((user: User) => {
            if (user.amazon.syncLock) {
              return throwError('Error!');
            }

            return of(user);
          }),
          retryWhen(errors =>
            errors.pipe(delay(2000))
          ),
          takeUntil(this.unsubscribe)
        ).subscribe((res: User) => {
          this.store.dispatch(new GotProfile(res));
          resolve();
        }, (err) => {
          reject();
        });
    });
  }

  public checkForFbSyncComplete(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.getUserStoreObservable().pipe(take(1)).subscribe((user) => {
        if (this.isFbSyncComplete(user)) {
          resolve();
        } else {
          this.http.get<User>(this.selfEndpoint)
            .pipe(
              delay(15000),
              mergeMap((user: User) => {
                if (!this.isFbSyncComplete(user)) {
                  return throwError('Error!');
                }

                return of(user);
              }),
              retryWhen(errors =>
                errors.pipe(delay(2000))
              ),
              takeUntil(this.unsubscribe)
            ).subscribe((res: User) => {
              this.store.dispatch(new GotProfile(res));
              resolve();
            }, (err) => {
              reject();
            });
        }
      });
    });
  }

  /**
   * Compares the values for facebookComplete
   * and facebookStart. If facebookComplete is
   * after facebookStart then return true, else
   * return false.
   * @param user
   */
  private isFbSyncComplete(user: User) {
    if (user.syncDates.facebookComplete > user.syncDates.facebookStart) {
      return true;
    } else {
      return false;
    }
  }

  public whichSyncsAreInProgress(user: User): syncsInProgress {
    let syncsInProgress: syncsInProgress = {};

    if (user.syncDates.facebookComplete < user.syncDates.facebookStart) {
      syncsInProgress.facebook = true;
    }

    if (user.syncDates.kdpComplete < user.syncDates.kdpStart) {
      syncsInProgress.kdp = true;
    }

    return syncsInProgress;
  }

  public checkForEmailVerification(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.getUserStoreObservable().pipe(take(1)).subscribe((user) => {
        if (user.emailVerified) {
          resolve(true);
        } else {
          this.http.get<User>(this.selfEndpoint)
            .pipe(
              delay(10000),
              mergeMap((val: User) => {
                if (!val.emailVerified) {
                  return throwError('Error!');
                }

                return of(val);
              }),
              retryWhen(errors =>
                errors.pipe(delay(2000))
              ),
              takeUntil(this.unsubscribe)
            ).subscribe((res: User) => {
              this.store.dispatch(new GotProfile(res));
              resolve(true);
            }, (err) => {
              reject(false);
            });
        }
      }, () => {
        reject(false);
      });
    });
  }

  public getProfile(): Observable<User> {
    return this.http.get<User>(this.selfEndpoint);
  }

  public getAndStoreProfile(forcePull?: boolean): Promise<User> {
    return new Promise((resolve, reject) => {
      this.store.select(fromStore.selectUserLastPull).pipe(take(1)).subscribe((lastPull) => {
        let now = new Date();
        if (!lastPull || (lastPull.getTime() + 5000) < now.getTime() || forcePull) {
          this.http.get<User>(this.selfEndpoint).pipe(take(1)).subscribe((user) => {
            this.store.dispatch(new GotProfile(user));
            resolve(user);
          }, () => {
            reject();
          });
        } else {
          this.store.select(fromStore.selectUser).pipe(take(1)).subscribe((user) => {
            resolve(user);
          }, () => {
            reject();
          })
        }
      })
    });
  }

  public getUserStoreObservable(): Observable<User> {
    return this.store.select(fromStore.selectUser);
  }

  public finishOnboarding(step): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http.post<ApiResponse>(this.finishOnboardingEndpoint, {
        step: step
      }).pipe(take(1)).subscribe((result) => {
        if (result.user) {
          this.store.dispatch(new GotProfile(result.user));
        }
        resolve();
      }, (err) => {
        reject();
      });
    });
  }

  public sendVerificationEmail(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post<ApiResponse>(this.sendVerificationEmailEndpoint, {})
      .pipe(take(1)).subscribe((result) => {
        if (result.success) {
          this.snackbarService.openSnackBar('Verification email sent.', 'Dismiss', 0);
        }
        resolve(result.success);
      }, (err) => {
        reject(false);
      });
    });
  }

  public verifyEmail(token: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post<ApiResponse>(this.verifyEmailEndpoint, {
        verifyToken: token
      })
      .pipe(take(1)).subscribe((result) => {
        if (result.success) {
          this.snackbarService.openSnackBar('Email Verified.', null, 5000);
        }
        resolve(result.success);
      }, (err) => {
        reject(false);
      });
    });
  }

  public deleteData(subject: string, password?: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post<ApiResponse>(this.deleteDataEndpoint, {
        subject: subject,
        password: password
      })
      .pipe(take(1)).subscribe((result) => {
        return resolve(result.success);
      }, (err) => {
        reject(false)
      });
    })
  }

  public setName(name: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http.put<User>(this.putNameEndpiont, {
        name: name
      })
      .pipe(take(1)).subscribe((user) => {
        this.store.dispatch(new GotProfile(user));
        return resolve();
      }, (err) => {
        reject();
      });
    });
  }

  public putSettings(settings: any): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http.put<User>(this.putSettingsEndpoint, {
        settings: settings
      })
        .pipe(take(1)).subscribe((user) => {
        this.store.dispatch(new GotProfile(user));
        return resolve();
      }, (err) => {
        reject();
      });
    });
  }

  public getEligibleSubscriptions(): Promise<EligibleSubscriptionsResponse> {
    return new Promise((resolve, reject) => {
      this.http.get<ApiResponse>(this.eligibleSubscriptionEndpoint)
        .pipe(take(1)).subscribe((response) => {
          resolve(response.data);
        }, (err) => {
          reject();
        });
    });
  }

  public getCheckoutSessionId(pricePreference: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.http.post<ApiResponse>(this.checkoutSessionEndpoint, { pricePreference: pricePreference})
      .pipe(take(1)).subscribe((response) => {
        resolve(response.data);
      }, (err) => {
        reject();
      });
    });
  }

  public getCheckoutUpdateSessionId(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.http.get<ApiResponse>(this.checkoutUpdateSessionEndpoint)
      .pipe(take(1)).subscribe((response) => {
        resolve(response.data);
      }, (err) => {
        reject();
      });
    });
  }

  public cancelSubscription(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http.delete<User>(this.subscriptionEndpoint)
      .pipe(take(1)).subscribe((user) => {
        this.store.dispatch(new GotProfile(user));
        resolve();
      }, (err) => {
        reject();
      });
    });
  }

  public reactivateSubscription(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http.put<User>(this.subscriptionEndpoint, {})
      .pipe(take(1)).subscribe((user) => {
        this.store.dispatch(new GotProfile(user));
        resolve();
      }, (err) => {
        reject();
      });
    });
  }

  public cachedReportsValidForDate(user: User, date: Date): boolean {
    let datesToCheck: Array<Date> = [];
    for (let syncDateKey in user.syncDates) {
      datesToCheck.push(new Date(user.syncDates[syncDateKey]));
    }
    if (user.cachedReportsInvalidBefore) {
      datesToCheck.push(new Date(user.cachedReportsInvalidBefore));
    }
    for (let checkDate of datesToCheck) {
      if (date < checkDate) {
        return false;
      }
    }
    return true;
  }

  public sendUserFeedback(feedback: UserFeedback): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post<ApiResponse>(this.feedbackEndpoint, feedback).pipe(take(1)).subscribe((response) => {
        return resolve(response.success);
      }, (err) => {
        return resolve(false);
      });
    });
  }

  public initializeUserDesktopAppOnboarding(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http.post<ApiResponse>(this.initializeDestkopAppOnboardingEndpoint, {}).pipe(take(1)).subscribe((response) => {
        return resolve();
      }, (err) => {
        return resolve();
      });
    });
  }

  public initializeUserExtensionOnboarding(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http.post<ApiResponse>(this.initializeExtensionOnboardingEndpoint, {}).pipe(take(1)).subscribe((response) => {
        return resolve();
      }, (err) => {
        return resolve();
      });
    });
  }

  public allowAccountAccess(allowAccountAccess: boolean): Promise<User> {
    return new Promise((resolve, reject) => {
      this.http.put<User>(this.allowAccountAccessEndpoint, { allowAccountAccess: allowAccountAccess}).pipe(take(1)).subscribe((user) => {
        this.store.dispatch(new GotProfile(user));
        return resolve(user);
      }, (err) => {
        return resolve();
      });
    })
  }

  public completeAmazonAuth(code: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http.post<ApiResponse>(this.completeAmazonAuthEndpoint, { code: code }).pipe(take(1)).subscribe((response) => {
        return resolve();
      }, (err) => {
        return reject();
      });
    });
  }

  public sendOnboardingPlatforms(onboardingIntent: any): Promise<void> {
    let platforms = {
      ...onboardingIntent.expenses,
      ...onboardingIntent.sales
    };
    return new Promise((resolve, reject) => {
      this.http.post<User>(this.onboardingSelectedPlatformsEndpoint, { platforms: platforms}).pipe(take(1)).subscribe((user) => {
        this.store.dispatch(new GotProfile(user));
        return resolve();
      }, (err) => {
        return reject();
      })
    });
  }
}


interface syncsInProgress {
  kdp?: boolean;
  amazonAds?: boolean;
  facebook?: boolean;
}

interface UserFeedback {
  generalFeedback: string;
  detailedFeedback: string;
}

interface EligibleSubscriptionsResponse {
  eligibleSubscriptions: Array<string>;
  stripeCoupon: StripeCoupon;
}

export interface StripeCoupon {
  amount_off: number;
  currency: string;
  duration: string;
  duration_in_months: number;
  name: string;
  percent_off: number;
  valid: boolean;
}
