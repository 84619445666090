import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ApiResponse } from '../../../utilities/models/ApiResponse.model';
import { resolve } from 'url';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { FacebookAd } from './facebook/models/FacebookAd.model';
import { SnackbarService } from 'src/app/utilities/snackbar.service';
import { AmazonCampaign, AmazonAd } from './amazon/models/amazonCampaign.model';
import { FacebookAdAccount } from './facebook/models/FacebookAdAccount.model';
import { FacebookCampaign } from './facebook/models/FacebookCampaign.model'
import { AmazonAdAccount } from '../user/user.model';

@Injectable({
  providedIn: 'root'
})
export class AdsService {

  // API Routes
  private facebookAdAccountsEndpoint = environment.apiUrl + 'facebook/adAccounts';
  private adCampaignsEndpoint = environment.apiUrl + 'aads/campaigns';
  private facebookCampaignsEndpoint = environment.apiUrl + 'aads/facebook/campaigns';
  private facebookAdsEndpoint = environment.apiUrl + 'aads/facebook/aads';
  private amazonCampaignsEndpoint = environment.apiUrl + 'aads/amazon/campaigns';
  private amazonAdsEndpoint = environment.apiUrl + 'aads/amazon/aads';
  private amazonAdAccountsEndpoint = environment.apiUrl + 'amazon/adAccounts';
  // END API Routes

  constructor(
    private http: HttpClient,
    private snackbarService: SnackbarService
  ) { }

  public pullFacebookAdAccounts(): Promise<Array<FacebookAdAccount>> {
    return new Promise((resolve, reject) => {
      this.http.get<ApiResponse>(this.facebookAdAccountsEndpoint)
        .subscribe((res) => {
          return resolve(res.data);
        }, (err) => {
          reject(err);
        });
    });
  }

  public selectFacebookAdAccount(selectedAdAccounts: Array<FacebookAdAccount>): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http.post<ApiResponse>(this.facebookAdAccountsEndpoint, {
        selectedAdAccounts: selectedAdAccounts
      }).subscribe((res) => {
        return resolve();
      }, (err) => {
        reject(err);
      });
    });
  }

  public getFacebookCampaigns(page: number, search: string, filters: any): Promise<FacebookCampaignResponse> {
    return new Promise((resolve, reject) => {
      let url = `${this.facebookCampaignsEndpoint}?page=${page || 0}&search=${search}`;
      let pageSize = localStorage.pageSize;
      if (pageSize) {
        url += `&pageSize=${pageSize}`
      }
      if (filters) {
        url += `&filters=${JSON.stringify(filters)}`;
      }
      this.http.get<ApiResponse>(url)
        .subscribe((res) => {
          resolve({
            campaigns: res.data,
            page: res.page,
            pages: res.pages
          });
        }, (err) => {
          reject(err);
        });
    });
  }

  public getAmazonCampaigns(page: number, search: string, filters: any): Promise<AmazonCampaignResponse> {
    return new Promise((resolve, reject) => {
      let url = `${this.amazonCampaignsEndpoint}?page=${page || 0}&search=${search}`;
      let pageSize = localStorage.pageSize;
      if (pageSize) {
        url += `&pageSize=${pageSize}`
      }
      if (filters) {
        url += `&filters=${JSON.stringify(filters)}`;
      }
      this.http.get<ApiResponse>(url)
        .subscribe((res) => {
          resolve({
            campaigns: res.data,
            page: res.page,
            pages: res.pages
          });
        }, (err) => {
          reject(err);
        });
    });
  }

  public getAdCampaigns(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<ApiResponse>(this.adCampaignsEndpoint)
      .subscribe((res) => {
        resolve(res.data);
      }, (err) => {
        reject(err);
      });
    });
  }

  public putFacebookAds(facebookAds: Array<FacebookAd>): Promise<Array<FacebookAd>> {
    return new Promise((resolve, reject) => {
      this.http.put<ApiResponse>(this.facebookAdsEndpoint, {
        facebookAds: facebookAds
      }).subscribe((res) => {
        let facebookAds = res.data;
        let message: string = facebookAds.length === 1 ? '1 Facebook Ad Updated!' : `${facebookAds.length} Facebook Ads Updated!`;
        this.snackbarService.openSnackBar(message, null, 3000);
        resolve(facebookAds);
      }, (err) => {
        reject(err);
      });
    });
  }

  public putAmazonCampaign(amazonCampaigns: Array<AmazonCampaign>): Promise<Array<AmazonCampaign>> {
    return new Promise((resolve, reject) => {
      this.http.put<ApiResponse>(this.amazonCampaignsEndpoint, {
        amazonCampaigns: amazonCampaigns
      }).subscribe((res) => {
        let amazonCampaigns = res.data;
        let message: string = amazonCampaigns.length === 1 ? '1 Amazon Campaign Updated!' : `${amazonCampaigns.length} Amazon Campaigns Updated!`;
        this.snackbarService.openSnackBar(message, null, 3000);
        resolve(amazonCampaigns);
      }, (err) => {
        reject(err);
      });
    });
  }

  public putAmazonAds(amazonAds: Array<AmazonAd>): Promise<Array<AmazonAd>> {
    return new Promise((resolve, reject) => {
      this.http.put<ApiResponse>(this.amazonAdsEndpoint, {
        amazonAds: amazonAds
      }).subscribe((res) => {
        let amazonAds = res.data;
        let message: string = amazonAds.length === 1 ? '1 Amazon Ad Updated!' : `${amazonAds.length} Amazon Ads Updated!`;
        this.snackbarService.openSnackBar(message, null, 3000);
        resolve(amazonAds);
      }, (err) => {
        reject(err);
      });
    });
  }

  public pullAmazonAdAccounts(): Promise<Array<AmazonAdAccount>> {
    return new Promise((resolve, reject) => {
      this.http.get<ApiResponse>(this.amazonAdAccountsEndpoint)
        .subscribe((res) => {
          return resolve(res.data);
        }, (err) => {
          reject(err);
        });
    });
  }

  public selectAmazonAdAccount(selectedAdAccounts: Array<AmazonAdAccount>): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http.post<ApiResponse>(this.amazonAdAccountsEndpoint, {
        selectedAdAccounts: selectedAdAccounts
      }).subscribe((res) => {
        return resolve();
      }, (err) => {
        reject(err);
      });
    });
  }
}

interface FacebookCampaignResponse {
  campaigns: Array<FacebookCampaign>;
  page: number;
  pages: number;
}

interface AmazonCampaignResponse {
  campaigns: Array<AmazonCampaign>;
  page: number;
  pages: number;
}
