import { Action } from '@ngrx/store';

export enum AuthActionTypes {
  LoginComplete = 'LOGIN_COMPLETE',
  LoginSuccess = 'LOGIN_SUCCESS',
  LoginFailure = 'LOGIN_FAILURE',
  CheckCachedAuth = 'CHECK_CACHED_AUTH',
  Logout = 'LOGOUT',
  LogoutConfirmed = 'LOGOUT_CONFIRMED',
  GetProfile = 'GET_PROFILE',
  GotProfile = 'GOT_PROFILE'
}

export class LoginComplete implements Action {
  readonly type = AuthActionTypes.LoginComplete;
}

export class LoginSuccess implements Action {
  readonly type = AuthActionTypes.LoginSuccess;
  constructor(public payload: any) { }
}

export class LoginFailure implements Action {
  readonly type = AuthActionTypes.LoginFailure;

  constructor(public payload: any) { }
}

export class CheckCachedAuth implements Action {
  readonly type = AuthActionTypes.CheckCachedAuth;
}

export class Logout implements Action {
  readonly type = AuthActionTypes.Logout;
}

export class LogoutConfirmed implements Action {
  readonly type = AuthActionTypes.LogoutConfirmed;
}

export class GetProfile implements Action {
  readonly type = AuthActionTypes.GetProfile;
}

export class GotProfile implements Action {
  readonly type = AuthActionTypes.GotProfile;

  constructor(public payload: any) { }
}

export type AuthActions =
  | LoginComplete
  | LoginSuccess
  | LoginFailure
  | CheckCachedAuth
  | Logout
  | LogoutConfirmed
  | GetProfile
  | GotProfile;