<div class="onboarding-state" [ngClass]="{'error': state=='loggedOut','success': state=='loggedIn','in-progress': state=='checking'}">
  <div class="onboarding-state__icon">
    <i *ngIf="state=='loggedOut'" class="fas fa-times"></i>
    <i *ngIf="state=='loggedIn'" class="fas fa-check"></i>
  </div>
  <div class="onboarding-state__title">{{ title }}</div>
  <div class="onboarding-state__state-message">{{ stateMessage }}</div>
  <div class="onboarding-state__progress-wrap" *ngIf="progress != undefined">
    <div class="onboarding-state__progress-bar" [style.width.%]="progress"></div>
  </div>
</div>