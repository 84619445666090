import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-sync-progress',
  templateUrl: './sync-progress.component.html',
  styleUrls: ['./sync-progress.component.scss']
})
export class SyncProgressComponent implements OnChanges {

  @Input() platformName: string = '';
  @Input() percent: number = 0;
  public percentText: string;
  public inProgress: boolean;
  @Input() lastSync: Date;

  constructor() { }

  ngOnChanges() {
    this.percentText = `${this.percent}%`;
    this.inProgress = this.percent > 0 && this.percent < 100;
  }

}
