import { Action } from '@ngrx/store';
import { QuickStats } from '../models/quick-stats.model';

export enum QuickStatsActionTypes {
  UpdateQuickStats = 'UPDATE_QUICK_STATS',
}

export class UpdateQuickStats implements Action {
  readonly type = QuickStatsActionTypes.UpdateQuickStats;

  constructor(public payload: QuickStats) { }
}

export type QuickStatsActions =
  | UpdateQuickStats
