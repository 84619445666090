import { DashboardsActions, DashboardsActionTypes } from '../actions/dashboards.actions';
import { Action } from '@ngrx/store';
import { SimpleDashboard } from '../dashboard.service';

export interface State {
  dashboards: Array<SimpleDashboard>;
  sharedAccessDashboards: Array<SimpleDashboard>;
}

export const initialState: State = {
  dashboards: null,
  sharedAccessDashboards: null
};

export function reducer(state = initialState, action: DashboardsActions): State {
  switch (action.type) {
    case DashboardsActionTypes.PullDashboardsSuccessfull:
      return {
        ...state,
        dashboards: action.payload.dashboards,
        sharedAccessDashboards: action.payload.sharedAccessDashboards
      };
    default:
      return state;
  }
}

export const selectDashboards = (state: State) => state;