import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-onboarding-state',
  templateUrl: './onboarding-state.component.html',
  styleUrls: ['./onboarding-state.component.scss']
})
export class OnboardingStateComponent implements OnInit {

  @Input() title: string = '';
  @Input() state: 'checking' | 'loggedIn' | 'loggedOut' = 'checking';
  @Input() stateMessage: string | undefined;
  @Input() progress: number | undefined;

  constructor() { }

  ngOnInit(): void {
  }
}
