import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromStore from '../../../state';
import { GotProfile, Logout, LogoutConfirmed } from '../../auth/actions/auth.actions';
import { AuthService } from '../../auth/auth.service';
import { User } from '../user/user.model';
import { QuickStats } from '../quick-stats/models/quick-stats.model';
import { UserService } from '../user/user.service';
import { Notification } from '../notifications/models/Notification.model';
import { NotificationsService } from '../notifications/notifications.service';
import * as moment from 'moment';
import { Subject, interval, BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ReportsService } from '../reports/reports.service';
import { NavigationEnd, Router } from '@angular/router';
import { CurrencyService } from 'src/app/utilities/currency.service';
import { CookieService } from 'ngx-cookie-service';
import { SharedAccessService } from '../shared-access/shared-access.service';
import { SharedAccessAccount } from '../shared-access/SharedAccess.model';

@Component({
  selector: 'app-private-layout',
  host: { '[id]': '"privateLayout"' },
  templateUrl: './private-layout.component.html',
  styleUrls: ['./private-layout.component.scss'],
  providers: [
    UserService,
    NotificationsService,
    ReportsService
  ]
})
export class PrivateLayoutComponent implements OnInit, OnDestroy {

  public user: User;
  public sharedAccessUser: SharedAccessAccount;
  public isAgency: boolean;
  public isLimitedUser: boolean;

  public quickStats: QuickStats;
  public profit: string;
  public mostRecentSyncDate: Date;
  public lastSyncDateString: string;
  public lastSyncDate: Date;
  public dropdownOpen: boolean;
  public notificationDropdownOpen: boolean;
  public hasSubscription;
  public targetCurrency: 'USD' | 'EUR' | 'CAD' | 'GBP' | 'INR' | 'JPY' | 'MXN' | 'BRL' | 'AUD' | 'PLN' | 'CNY' | 'EGP' | 'SAR' | 'SGD' | 'SEK' | 'TRY' | 'AED';
  private lastKenpRateSettings: any;

  public reportsLink: string;

  public lightMode: boolean;

  public notifications: Array<Notification>;

  // User Qucik Switch Dropdown
  public sharedAccessUserSearch: string;
  private sharedAccessUserSearchSubject = new BehaviorSubject(''); // do this on next version: https://stackoverflow.com/questions/64604659/how-to-wait-for-the-last-keystroke-before-executing-a-function-in-angular
  public currentPageSharedAccounts: number;
  public numPagesSharedAccounts: number;
  public pageSizeSharedAccounts: number = 10;
  public pageOptionsSharedAccounts: Array<string>;
  public numNearPageOptions = 2;
  public sharedAccounts: Array<SharedAccessAccount>;
  public userDropdownOpen: boolean;
  // END User Qucik Switch Dropdown

  private gettingAutomaticSnapshotUpdate: boolean;
  private lastSnapshotCheck: Date;

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private store: Store<fromStore.State>,
    private authService: AuthService,
    private userService: UserService,
    private notificationsService: NotificationsService,
    private reportsService: ReportsService,
    private router: Router,
    private currencyService: CurrencyService,
    private cookieService: CookieService,
    private sharedAccessService: SharedAccessService
  ) {
    this.lightMode = false;
    this.quickStats = {
      today: {
        profit: null,
        royalty: null,
        spend: null,
        pages: null,
        units: null
      },
      yesterday: {
        profit: null,
        royalty: null,
        spend: null,
        pages: null,
        units: null
      }
    };
    this.profit = '';
    this.lastSyncDateString = '';
    this.lastSyncDate = undefined;

    this.dropdownOpen = false;
    this.notificationDropdownOpen = false;

    this.notifications = [];

    this.reportsLink = '/reports';
  }

  ngOnDestroy() {
    this.unsub();
  }

  /**
   * Unsubscribe to subscriptions
   */
  private unsub() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnInit() {
    this.currentPageSharedAccounts = localStorage.qaSharedAccountsPage || 1;
    this.sharedAccessUserSearchSubject
    .pipe(debounceTime(300))
    .pipe(distinctUntilChanged())
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(() => {
      if (typeof this.sharedAccessUserSearch != 'undefined') {
        this.searchForSharedAccessUsers(true);
      }
    });

    this.store.select(fromStore.selectIsAgency).pipe(takeUntil(this.unsubscribe)).subscribe((isAgency) => {
       this.isAgency = isAgency;
     });

     this.store.select(fromStore.selectIsAgencyLimitedSponsor).pipe(takeUntil(this.unsubscribe)).subscribe((isLimited) => {
       this.isLimitedUser = isLimited;
     });

    this.setReportLink(this.router.url);

    this.router.events.pipe(takeUntil(this.unsubscribe)).subscribe((evt: any) => {
      if (evt instanceof NavigationEnd) {
        this.setReportLink(evt.url);
        this.getSharedAccessUser();
      }
    });

    interval(300000).pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.userService.getAndStoreProfile().then((user) => {

      });
    });

    this.lastSnapshotCheck = new Date();
    this.gettingAutomaticSnapshotUpdate = false;

    interval(1000).pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      if (this.mostRecentSyncDate && this.mostRecentSyncDate.getTime() > 0) {
        this.lastSyncDateString = moment(this.mostRecentSyncDate).fromNow();
      }
    });

    // get user data from store and subscribe to changes
    this.store.select(fromStore.selectUser).pipe(takeUntil(this.unsubscribe)).subscribe((user) => {
      this.user = user;
      this.getSharedAccessUser();
      this.mostRecentSyncDate = new Date(0);
      const dateKeysToConsider = ['kdpComplete', 'appleBooksComplete', 'barnesAndNobleComplete', 'draft2digitalComplete', 'koboComplete'];
      for (let dateKey in user.syncDates) {
        if (dateKeysToConsider.includes(dateKey)) {
          const date = new Date(user.syncDates[dateKey]);
          if (date > this.mostRecentSyncDate) {
            this.mostRecentSyncDate = date;
          }
        }
      }

      this.lastSyncDateString = '';
      if (this.mostRecentSyncDate.getTime() > 0) {
        this.lastSyncDateString = moment(this.mostRecentSyncDate).fromNow();
      }

      let newTargetCurrency = user.settings.currency || 'USD';
      let newKenpRateSettings = JSON.stringify(user.settings.kenpRate);
      if ((this.targetCurrency && this.targetCurrency !== newTargetCurrency) || (this.lastKenpRateSettings && this.lastKenpRateSettings !== newKenpRateSettings)) {
        // invalidate old quickstats
        this.reportsService.clearQuickStats();
        this.profit = '';
        this.lastSyncDate = undefined;
        // triger snapshot refresh for quick stats
        this.getSnapshot();
      }
      this.targetCurrency = newTargetCurrency;
      this.lastKenpRateSettings = JSON.stringify(user.settings.kenpRate);

      this.hasSubscription = this.user.subscription && (this.user.subscription.hasSubscription || this.user.subscription.sponsored);
      this.lightMode = user.settings.theme === 1;

      // pull fresh snapshot report if needed
      if (!this.userService.cachedReportsValidForDate(user, this.lastSnapshotCheck) && !this.gettingAutomaticSnapshotUpdate) {
        this.getSnapshot();
      }
    });

    // get quick stats from store and subscribe to changes
    this.store.select(fromStore.selectQuickStats).pipe(takeUntil(this.unsubscribe)).subscribe((dat: QuickStats) => {
      this.quickStats = dat;
      this.profit = this.quickStats.today.profit ? this.currencyService.formatCurrency(this.quickStats.today.profit) : '';
      this.lastSyncDate = dat.syncDate;
    });

    // get notifications from store and subscribe to changes
    this.store.select(fromStore.selectNotifications).pipe(takeUntil(this.unsubscribe)).subscribe((dat: any) => {
      this.prepareNotifications(dat.notifications);
    });
    
    // TODO: should use observable to prevent need for two functions
    this.notificationsService.getNotifications().then();

    // interval to update dateString
    interval(30000).pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.prepareNotifications;
    });

    // interval to check for notifications
    interval(180000).pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.notificationsService.getNotifications().then();
    });
  }

  private prepareNotifications(notifications: Array<Notification>) {
    if (notifications) {
      this.notifications = notifications.map((notification: Notification) => {
        return {
          ...notification,
          dateString: moment(notification.date).fromNow(),
          linkIsExternal: notification.link.startsWith('http')
        }
      });
    }
  }

  public dismissNotification(notificationId: string) {
    this.notificationsService.dismissNotification(notificationId);
  }

  public setReportLink(url: string) {
    if ((url.startsWith('/reports') && !url.startsWith('/reports/snapshot') && !url.startsWith('/reports/profit')) || (this.isAgency && !this.sharedAccessUser)) {
      this.reportsLink = '/reports/custom';
    } else {
      this.reportsLink = '/reports';
    }
  }

  public toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  public toggleNotificationDropdown() {
    this.notificationDropdownOpen = !this.notificationDropdownOpen;
  }

  public logOut() {
    this.authService.logout().then(() => {
      this.store.dispatch(new LogoutConfirmed());
    });
  }

  public closeDropdowns() {
    this.dropdownOpen = false;
    this.notificationDropdownOpen = false;
    this.userDropdownOpen = false;
  }

  public setTheme(themeNumber) {
    this.userService.putSettings({
      theme: themeNumber
    }).then(() => {

    });
  }

  private getSnapshot() {
    if (!this.isAgency && !this.isLimitedUser && !this.sharedAccessUser && this.router.url !== '/reports/snapshot') {
      // pull new snapshot report
      this.gettingAutomaticSnapshotUpdate = true;
      this.reportsService.getSnapshotData({}).then((response) => {
        this.lastSnapshotCheck = new Date();
        this.gettingAutomaticSnapshotUpdate = false;
      });
    }
  }

  private getSharedAccessUser() {
    if (this.user) {
      let sharedAccessUserId = this.cookieService.get('sharedAccessUser');
      if (sharedAccessUserId) {
        if (!this.sharedAccessUser || this.sharedAccessUser._id !== sharedAccessUserId) {
          this.sharedAccessService.getSharedAccount(sharedAccessUserId).then((sharedAccessUser) => {
            this.sharedAccessUser = sharedAccessUser;
          });
        }
      } else {
        this.sharedAccessUser = undefined;
      }
    }
  }

  public exitSharedAccess() {
    this.sharedAccessService.switchAccount().then(() => {
      this.router.navigate(['/shared-access']);
    });
  }

  public toggleUserDropdown() {
    this.userDropdownOpen = !this.userDropdownOpen;

    if (this.userDropdownOpen) {
      this.searchForSharedAccessUsers();
    }
  }

  public quickSwitchUser(userId) {
    this.sharedAccessService.switchAccount(userId).then(() => {
      const currentUrl = this.router.url;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);
      });
    });
  }

  public searchForSharedAccessUsers(resetPage?: boolean) {
    if (resetPage) {
      this.currentPageSharedAccounts = 1;
    }
    this.sharedAccessService.getSharedAccounts(this.currentPageSharedAccounts, this.pageSizeSharedAccounts, this.sharedAccessUserSearch).then((sharedAccountsRes) => {
      this.sharedAccounts = sharedAccountsRes.data.map((sharedAccount) => {

        let dates: Array<Date> = [];
        for (let syncDateKey in sharedAccount.syncDates) {
          if (!syncDateKey.startsWith('facebook') && !syncDateKey.startsWith('amazonAdvertising')) {
            dates.push(new Date(sharedAccount.syncDates[syncDateKey]));
          }
        }
        dates.sort();
        return {
          ...sharedAccount,
          lastSalesSyncDate: dates[dates.length - 1]
        }
      });

      this.currentPageSharedAccounts = sharedAccountsRes.page;
      this.numPagesSharedAccounts = sharedAccountsRes.pages;
      this.pageOptionsSharedAccounts = Array(this.numPagesSharedAccounts).fill(0).map((x, index) => {
        let friendlyIndex = index + 1;
        if (friendlyIndex == 1 || friendlyIndex == this.numPagesSharedAccounts || Math.abs(friendlyIndex - this.currentPageSharedAccounts) <= this.numNearPageOptions) {
          return friendlyIndex.toString();
        } else {
          return '...';
        }
      }).filter((value, index, array) => {
        return !(value == '...' && array[index - 1] == '...');
      });
    });
  }

  userSearchChange(): void {
    this.sharedAccessUserSearchSubject.next(this.sharedAccessUserSearch);
  }

  public setSharedAccountsPage(index: any) {
    const pageNum = parseInt(index);

    if (isNaN(pageNum)) {
      return;
    }

    localStorage.qaSharedAccountsPage = pageNum;

    this.currentPageSharedAccounts = pageNum;
    this.searchForSharedAccessUsers();
  }
}
