import { Book } from "../../routes/private/books/models/Book.model";
import { AmazonAd, AmazonCampaign } from "../../routes/private/ads/amazon/models/amazonCampaign.model";
import { FacebookCampaign } from "src/app/routes/private/ads/facebook/models/FacebookCampaign.model";

export class BookExpense {
  _id: string;
  owner: string;
  book: Book;
  type: string;
  parent: string;
  name: string;

  country: string;

  currency: string;
  spend: number;
  reach: number;
  cpc: number;
  impressions: number;
  clicks: number;
  outboundClicks?: number;
  status: String;

  amazonAdsData?: AmazonAdsData;

  facebookData?: {
    facebookAdId: string;
    relevance_score: {
      status: string;
    },
    actions: Array<facebookAction>
  };

  dateStart: Date;
  dateEnd: Date;

  amsCampaign?: any;

  facebookAd?: any;
  facebookCampaign?: FacebookCampaign;

  amazonAd?: AmazonAd;
  amazonCampaign?: AmazonCampaign;
}

export interface AmazonAdsData {
  ctr: number;
  acos: number;
  stateName: string;
  budget: number;
  budgetType: string;
  pages: number;
  orders: number;
  unitsSold: number;

  pagesRoyalty?: number;
}

interface facebookAction {
  action_type: string;
  value: number;
}

export class SnapshotBookExpense {
  _id: {
    book: Book;
    length: number; // milliseconds between dateStart and dateEnd
  };
  currency: string;
  spend: number;
  amazonAdsData: AmazonAdsData;
}

export enum BookExpenseTypes {
  AMAZON_ADS = 'amazonads',
  AMAZON_ADS_V2 = 'amazonadsv2',
  FACEBOOK = 'facebook',
  MANUAL = 'manual'
}

export const BOOK_EXPENSE_AD_TYPES: Array<string> = [
  BookExpenseTypes.AMAZON_ADS,
  BookExpenseTypes.FACEBOOK
]