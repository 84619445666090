<div class="nav">
  <div class="nav-container">
    <div class="nav-col brand">
      <a routerLink="/">PublishWide</a>
    </div>
    <div class="nav-col links links-right">
      <ul>
        <li>
          <button class="button button-no-contrast" (click)="logout()">Log Out</button>
        </li>
      </ul>
    </div>
  </div>
</div>