import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingBarComponent } from './loading-bar/loading-bar.component';
import { BookmarkletLinkComponent } from './bookmarklet-link/bookmarklet-link.component';
import { SyncIndicatorComponent } from './sync-indicator/sync-indicator.component';
import { DaDownloadButtonComponent } from './da-download-button/da-download-button.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { FormsModule } from '@angular/forms';
import { ExtensionInstallButtonComponent } from './extension-install-button/extension-install-button.component';
import { SyncProgressComponent } from './sync-progress/sync-progress.component';
import { OnboardingStateComponent } from './onboarding-state/onboarding-state.component';

@NgModule({
  declarations: [
    LoadingBarComponent,
    BookmarkletLinkComponent,
    SyncIndicatorComponent,
    DaDownloadButtonComponent,
    FeedbackComponent,
    ExtensionInstallButtonComponent,
    SyncProgressComponent,
    OnboardingStateComponent
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
    LoadingBarComponent,
    BookmarkletLinkComponent,
    SyncIndicatorComponent,
    DaDownloadButtonComponent,
    FeedbackComponent,
    ExtensionInstallButtonComponent,
    SyncProgressComponent,
    OnboardingStateComponent
  ]
})
export class UiComponentsModule { }
