import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { CanActivate, Router } from '@angular/router';
import { of, Observable } from 'rxjs';
import { mergeMap, map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { AuthService } from '../routes/auth/auth.service';
import * as fromStore from '../state';

@Injectable({
  providedIn: 'root'
})
export class HasSubscriptionGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private store: Store<fromStore.State>,
    private router: Router,
    private location: Location
  ) { }

  canActivate(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.isAgencyLimitedSponsor().subscribe((agencyLimitedSponsor) => {
        if (agencyLimitedSponsor) {
          this.router.navigate(['/account/platforms']);
          resolve(false);
        } else {
          this.checkStoreHasSubscription().subscribe((result) => {
            if (result) {
              resolve(true);
            } else {
              this.isSponsored().subscribe((sponsored) => {
                if (sponsored) {
                  resolve(false);
                } else {
                  this.router.navigate(['/account/settings']);
                  resolve(false);
                }
              })
            }
          });
        }
      });
    });
  }

  checkStoreHasSubscription() {
    return this.store.select(fromStore.selectHasSubscription).pipe(take(1));
  }

  isSponsored() {
    return this.store.select(fromStore.selectIsSponsored).pipe(take(1));
  }

  isAgencyLimitedSponsor() {
    return this.store.select(fromStore.selectIsAgencyLimitedSponsor).pipe(take(1));
  }
}
