import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/routes/auth/auth.service';
import { SnackbarService } from 'src/app/utilities/snackbar.service';
import { WebsocketService } from 'src/app/utilities/websocket.service';
import { FACEBOOK_ACCOUNT_STATUS_OPTIONS } from '../../ads/facebook/facebook.constants';
import { FacebookAdAccount } from '../../ads/facebook/models/FacebookAdAccount.model';
import { ReportsService } from '../../reports/reports.service';
import { User } from '../../user/user.model';
import { UserService } from '../../user/user.service';
import * as UaParser from 'ua-parser-js';
import { AdsService } from '../../ads/ads.service';
import { SharedAccessGrantRequest } from '../../shared-access/SharedAccess.model';
import { SharedAccessService } from '../../shared-access/shared-access.service';
declare var amazon: any;

@Component({
  selector: 'app-onboarding-ws',
  templateUrl: './onboarding-ws.component.html',
  styleUrls: ['./onboarding-ws.component.scss'],
  providers: [
    ReportsService
  ]
})
export class OnboardingWsComponent implements OnInit, OnDestroy {
  public browser: string;

  // UI Variables

  public showAgree: boolean; // controls if the agree button is visible
  public showFinish: boolean;
  public agreementConfirmed: boolean; // controls confirmation message about agreement
  public agreementConfirmedMessage: string; // copy shown after agrement is agreed to
  public proceedMessage: string; // copy shown after sync is detected

  public showOnboarding: boolean; // shows the onbaording page, used to hide onboarding if user is onboarded but hits onboarding page

  public emailVerified: boolean; // true if the users email is verified

  public percentComplete: number; // number representing percentage of onboarding that is done

  public bookmarkletDialogOpen: boolean;
  public hideBookmarkletDialogCloseButton: boolean;
  public hasViewedBookmarkletDialog: boolean;

  private user: User;
  public userSettings: any;
  public kenpRateForm: FormGroup;
  public verifyForm: FormGroup;

  public name: any;

  public baseKenpRate: number;
  public kenpRatePercentChange: string;
  public kenpRates: any;
  public ratesLoaded: boolean;

  public facebookLinked: boolean;
  public reauthenticationNeeded: boolean;
  public facebookMissingPermissions: boolean;
  public selectAdAccount: boolean;
  public adAccounts: Array<FacebookAdAccount>;
  public numSelectedAdAccount: number;
  public alreadyHasSelectedAdAccount: boolean;
  public FACEBOOK_ACCOUNT_STATUS_OPTIONS = FACEBOOK_ACCOUNT_STATUS_OPTIONS;

  public verifyCode: string;
  // END UI Variables

  public agencyInvite: SharedAccessGrantRequest;

  public appleProviderIds: Array<{
    contentTypes: Array<string>
    name: string;
    providerId: number;
    publicProviderId: string;
    subType: unknown;
    selected: boolean;
  }>;

  // Constants
  public readonly MAX_STEP: number = 8;
  public readonly MIN_STEP: number = 1;
  // END Constants

  private returnTo: string; // path that the users should be returned to after onboarding


  public onboardingPage = 1;

  public theme = 1;

  public clientPeers = {};

  public numSelectedSalesPlatforms = 0;
  public numAuthedSalesPlatforms = 0;
  public waitingToStartSync: boolean;

  public incomeSources: Array<any> = INCOME_SOURCES.filter((incomeSource) => {
    return incomeSource != 'manualIncome'
  });
  public expenseSources: Array<any> = EXPENSE_SOURCES.filter((expenseSource) => {
    return expenseSource != 'manualExpenses'
  });
  public onboardingIntent = {
    sales: {
      amazonKdp: false,
      appleBooks: false,
      barnesAndNoble: false,
      draft2digital: false,
      googleBooks: false,
      kobo: false,
    },
    expenses: {
      amazonAds: false,
      facebook: false,
    }
  };

  public loginStates: {
    sales: {
      amazonKdp?: LoginState,
      appleBooks?: LoginState,
      barnesAndNoble?: LoginState,
      draft2digital?: LoginState,
      googleBooks?: LoginState,
      kobo?: LoginState,
    },
    expenses: {
      amazonAds?: LoginState,
      facebook?: LoginState,
    }
  } = {
    sales: {},
    expenses: {}
  };

  public syncProgress = {
    amazonKdp: 0,
    appleBooks: 0,
    barnesAndNoble: 0,
    draft2digital: 0,
    googleBooks: 0,
    kobo: 0,
  };
  public syncSettings: {
    amazonKdp: boolean;
    appleBooks: boolean;
    barnesAndNoble: boolean;
    draft2digital: boolean;
    kobo: boolean;
  }


  private unsubscribe: Subject<void> = new Subject();
  ngOnDestroy() {
    this.unsub();
  }

  /**
   * Unsubscribe to subscriptions
   */
  private unsub() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private snackbarService: SnackbarService,
    private formBuilder: FormBuilder,
    private reportsService: ReportsService,
    private adsService: AdsService,
    private websocketService: WebsocketService,
    private sharedAccessService: SharedAccessService
  ) {
    this.onboardingPage = parseInt(localStorage.onboardingStep) || 1;
    if (this.onboardingPage == 11) {
      this.back();
    }
    this.emailVerified = false;

    this.bookmarkletDialogOpen = false;
    this.hideBookmarkletDialogCloseButton = true;
    this.hasViewedBookmarkletDialog = false;

    this.facebookLinked = false;

    this.verifyForm = this.formBuilder.group({
      confirmationCode: new FormControl('', [
        Validators.required,
        Validators.pattern(/^([0123456789bcdfghjklmnpqrstvwxzBCDFGHJKLMNPQRSTVWXZ]{6,6})$/)
      ])
    })

    this.kenpRateForm = this.formBuilder.group({
      estimatedKenpRateType: new FormControl('', [
        Validators.required
      ]),
      customGlobalRate: new FormControl('', [
        Validators.min(0),
        Validators.max(1)
      ]),
      customMarketplaceRateUS: new FormControl('', [
        Validators.min(0),
        Validators.max(1)
      ]),
      customMarketplaceRateGB: new FormControl('', [
        Validators.min(0),
        Validators.max(1)
      ]),
      customMarketplaceRateDE: new FormControl('', [
        Validators.min(0),
        Validators.max(1)
      ]),
      customMarketplaceRateFR: new FormControl('', [
        Validators.min(0),
        Validators.max(1)
      ]),
      customMarketplaceRateES: new FormControl('', [
        Validators.min(0),
        Validators.max(1)
      ]),
      customMarketplaceRateIT: new FormControl('', [
        Validators.min(0),
        Validators.max(1)
      ]),
      customMarketplaceRateNL: new FormControl('', [
        Validators.min(0),
        Validators.max(1)
      ]),
      customMarketplaceRateJP: new FormControl('', [
        Validators.min(0),
        Validators.max(1)
      ]),
      customMarketplaceRateIN: new FormControl('', [
        Validators.min(0),
        Validators.max(1)
      ]),
      customMarketplaceRateCA: new FormControl('', [
        Validators.min(0),
        Validators.max(1)
      ]),
      customMarketplaceRateBR: new FormControl('', [
        Validators.min(0),
        Validators.max(1)
      ]),
      customMarketplaceRateMX: new FormControl('', [
        Validators.min(0),
        Validators.max(1)
      ]),
      customMarketplaceRateAU: new FormControl('', [
        Validators.min(0),
        Validators.max(1)
      ]),
      kenpAssumeIncreaseRate: new FormControl('', [
        Validators.min(0),
        Validators.max(100)
      ]),
      kenpAssumeDecreaseRate: new FormControl('', [
        Validators.min(0),
        Validators.max(100)
      ])
    });

    this.kenpRateForm.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.kenpRateFormChange();
    });

    const parser = new UaParser();
    const parsedUa = parser.getResult();
    this.browser = parsedUa.browser.name;
  }

  ngOnInit(): void {
    this.sharedAccessService.getSharedAccessRequestsWaitingApproval(1,1).then((response) => {
      if (response.data && response.data.length > 0) {
        this.agencyInvite = response.data.filter((sharingRequest) => {
          return sharingRequest.invite;
        })[0];
        if (this.agencyInvite && this.onboardingPage == 1) {
          this.onboardingPage = 0;
        }
      }
    });

    this.route.queryParams.pipe(take(1)).subscribe(params => {
      this.returnTo = params.returnTo;
    });

    this.userService.getUserStoreObservable().pipe(takeUntil(this.unsubscribe)).subscribe((user) => {
      this.user = user;
      this.name = user.name;

      this.onboardingIntent = {
        sales: {
          amazonKdp: user.onboardingPlatforms.amazonKdp,
          appleBooks: user.onboardingPlatforms.appleBooks,
          draft2digital: user.onboardingPlatforms.draft2digital,
          barnesAndNoble: user.onboardingPlatforms.barnesAndNoble,
          googleBooks: user.onboardingPlatforms.googleBooks,
          kobo: user.onboardingPlatforms.kobo
        },
        expenses: {
          facebook: user.onboardingPlatforms.facebook,
          amazonAds: user.onboardingPlatforms.amazonAds
        }
      }
      this.doPageActions();

      this.userSettings = user.settings;
      // Initalize kenp rate form values
      this.kenpRateForm.controls['estimatedKenpRateType'].setValue(this.userSettings.kenpRate ? this.userSettings.kenpRate.estimatedKenpRateType || 'MOST_RECENT' : 'MOST_RECENT');
      this.kenpRateForm.controls['customGlobalRate'].setValue(this.userSettings.kenpRate ? this.userSettings.kenpRate.customGlobalRate : undefined);

      this.kenpRateForm.controls['customMarketplaceRateUS'].setValue(this.userSettings.kenpRate && this.userSettings.kenpRate.customMarketplaceRates ? this.userSettings.kenpRate.customMarketplaceRates.US : undefined);
      this.kenpRateForm.controls['customMarketplaceRateGB'].setValue(this.userSettings.kenpRate && this.userSettings.kenpRate.customMarketplaceRates ? this.userSettings.kenpRate.customMarketplaceRates.GB : undefined);
      this.kenpRateForm.controls['customMarketplaceRateDE'].setValue(this.userSettings.kenpRate && this.userSettings.kenpRate.customMarketplaceRates ? this.userSettings.kenpRate.customMarketplaceRates.DE : undefined);
      this.kenpRateForm.controls['customMarketplaceRateFR'].setValue(this.userSettings.kenpRate && this.userSettings.kenpRate.customMarketplaceRates ? this.userSettings.kenpRate.customMarketplaceRates.FR : undefined);
      this.kenpRateForm.controls['customMarketplaceRateES'].setValue(this.userSettings.kenpRate && this.userSettings.kenpRate.customMarketplaceRates ? this.userSettings.kenpRate.customMarketplaceRates.ES : undefined);
      this.kenpRateForm.controls['customMarketplaceRateIT'].setValue(this.userSettings.kenpRate && this.userSettings.kenpRate.customMarketplaceRates ? this.userSettings.kenpRate.customMarketplaceRates.IT : undefined);
      this.kenpRateForm.controls['customMarketplaceRateNL'].setValue(this.userSettings.kenpRate && this.userSettings.kenpRate.customMarketplaceRates ? this.userSettings.kenpRate.customMarketplaceRates.NL : undefined);
      this.kenpRateForm.controls['customMarketplaceRateJP'].setValue(this.userSettings.kenpRate && this.userSettings.kenpRate.customMarketplaceRates ? this.userSettings.kenpRate.customMarketplaceRates.JP : undefined);
      this.kenpRateForm.controls['customMarketplaceRateIN'].setValue(this.userSettings.kenpRate && this.userSettings.kenpRate.customMarketplaceRates ? this.userSettings.kenpRate.customMarketplaceRates.IN : undefined);
      this.kenpRateForm.controls['customMarketplaceRateCA'].setValue(this.userSettings.kenpRate && this.userSettings.kenpRate.customMarketplaceRates ? this.userSettings.kenpRate.customMarketplaceRates.CA : undefined);
      this.kenpRateForm.controls['customMarketplaceRateBR'].setValue(this.userSettings.kenpRate && this.userSettings.kenpRate.customMarketplaceRates ? this.userSettings.kenpRate.customMarketplaceRates.BR : undefined);
      this.kenpRateForm.controls['customMarketplaceRateMX'].setValue(this.userSettings.kenpRate && this.userSettings.kenpRate.customMarketplaceRates ? this.userSettings.kenpRate.customMarketplaceRates.MX : undefined);
      this.kenpRateForm.controls['customMarketplaceRateAU'].setValue(this.userSettings.kenpRate && this.userSettings.kenpRate.customMarketplaceRates ? this.userSettings.kenpRate.customMarketplaceRates.AU : undefined);

      this.kenpRateForm.controls['kenpAssumeIncreaseRate'].setValue(this.userSettings.kenpRate ? this.userSettings.kenpRate.kenpAssumeIncreaseRate || 10 : 10);
      this.kenpRateForm.controls['kenpAssumeDecreaseRate'].setValue(this.userSettings.kenpRate ? this.userSettings.kenpRate.kenpAssumeDecreaseRate || 10 : 10);
      // END Initalize kenp rate form values
      this.reportsService.getKenpRateFromAPI().then((kenpRates) => {
        this.kenpRates = kenpRates;
        this.ratesLoaded = true;
      });
      if (user.onboarded) {
        this.router.navigate(['/reports']);
      } else {
        this.showOnboarding = true;
      }

      if (!this.facebookLinked) {
        if (user && user.connectedPlatforms && user.connectedPlatforms.facebook) {
          this.facebookLinked = user.connectedPlatforms.facebook;
        }
      }

      if (user && user.connectedPlatforms && user.connectedPlatforms.facebook) {
        this.reauthenticationNeeded = false;
        this.pullAdAccounts().then(() => {
          if (user.facebookSelectedAdAccounts.length === 0) {
            this.selectAdAccount = true;
            this.adAccounts = this.adAccounts.map((adAccount) => {
              return {
                ...adAccount,
                selected: true
              };
            });
          } else {
            this.alreadyHasSelectedAdAccount = true;
            this.getAlreadySelectedAdAccounts(user);

            this.selectAdAccount = true;
          }
        }).catch((err) => {
          if (err && err.status === 400) {
            this.reauthenticationNeeded = true;
          } else if (err && err.status == 412) {
            this.facebookMissingPermissions = true;
          }
        });
      }
    });

    this.userService.getAndStoreProfile().then((user) => {
      this.userService.checkForEmailVerification().then((result) => {
        if (result) {
          this.emailVerified = true;
        }
      });
    });

    this.websocketService.listenForAction('PEERS').pipe(takeUntil(this.unsubscribe)).subscribe((message) => {
      this.clientPeers = message.data;
    });
    this.websocketService.listenForAction('SYNC_AUTH_STATUS').pipe(takeUntil(this.unsubscribe)).subscribe((message) => {
      if (this.loginStates.sales[message.data.platform]) {
        this.loginStates.sales[message.data.platform].state = message.data.state ? 'loggedIn' : 'loggedOut';
        this.loginStates.sales[message.data.platform].friendlyState = message.data.state ? 'Logged In' : 'Logged Out';
        this.setNumAuthedSalesPlatforms();

        if (message.data.platform == 'appleBooks' && message.data.state) {
          this.getAppleProviders();
        }
      }
    });
    this.websocketService.listenForAction('SYNC_UPDATE').pipe(takeUntil(this.unsubscribe)).subscribe((message) => {
      this.syncProgress.amazonKdp = message.data.percentage.amazonKdp;
      this.syncProgress.appleBooks = message.data.percentage.appleBooks;
      this.syncProgress.barnesAndNoble = message.data.percentage.barnesAndNoble;
      this.syncProgress.draft2digital = message.data.percentage.draft2digital;
      this.syncProgress.kobo = message.data.percentage.kobo;
    });
    this.websocketService.listenForAction('SYNC_SETTINGS').pipe(takeUntil(this.unsubscribe)).subscribe((message) => {
      this.syncSettings = message.data;
      this.syncSettings.appleBooks = !!message.data.iTunes;
      if (this.waitingToStartSync) {
        this.startSync();
      }
    });
    this.websocketService.listenForAction('AVAIL_ITUNES_PROVIDERS').pipe(takeUntil(this.unsubscribe)).subscribe((message) => {
      this.appleProviderIds = message.data;
    });
  }

  public continue(goToPage?: number) {
    let page = this.onboardingPage;

    // prevent continuing if has not selected any sales platforms
    if (page == 1 && !this.selectedASalesPlatform()) {
      return;
    }

    // prevent continuing if email not verified
    if (page == 2 && !this.user.emailVerified) {
      return;
    }

    if (goToPage != undefined) {
      page = goToPage;
    } else {
      page += 1;
    }

    // automatically continue if email is already verified
    if (page == 2 && this.user.emailVerified) {
      page += 1;
    }

    if (page == 4) {
      page += 1;
    }

    if (page == 6 && !this.onboardingIntent?.expenses?.facebook) {
      page +=1;
    }
    if (page == 7 && !this.onboardingIntent?.expenses?.amazonAds) {
      page += 1;
    }

    this.onboardingPage = page;
    this.doPageActions('forward');
  }

  public back() {
    let page = this.onboardingPage;
    page -= 1;

    if (page == 9) {
      page -= 1;
    }
    if (page == 7 && !this.onboardingIntent?.expenses?.amazonAds) {
      page -= 1;
    }
    if (page == 6 && !this.onboardingIntent?.expenses?.facebook) {
      page -= 1;
    }

    if (page == 4) {
      page -= 1;
    }

    if (page == 2 && this.user.emailVerified) {
      page -= 1;
    }

    this.onboardingPage = page;
    this.doPageActions('backward');
  }

  public verifyEmail() {
    const code = this.verifyForm.get('confirmationCode').value
    this.userService.verifyEmail(code).then(() => {
      this.user.emailVerified = true;
      this.continue();
      this.userService.getAndStoreProfile();
    }, () => {
      
    });
  }

  public setTheme(themeNumber: number) {
    this.theme = themeNumber;
  }

  public doPageActions(direction?: 'forward' | 'backward') {

    // if user has no selected sales platforms end back to step 1
    if (this.onboardingPage > 1 && !this.selectedASalesPlatform()) {
      this.onboardingPage = 1;
      return;
    }

    // if user email is not verified send back to step 2
    if (this.onboardingPage > 2 && !this.user.emailVerified) {
      this.onboardingPage = 2;
      return;
    }
    
    localStorage.onboardingStep = this.onboardingPage;
    
    if (this.onboardingPage == 2) {
      if (direction == 'forward') {
        this.sendSelectedPlatforms();
      }
    } else if (this.onboardingPage == 3 && this.user?.emailVerified) {
      if (direction == 'forward') {
        this.sendSelectedPlatforms();
      }
    } else if (this.onboardingPage == 8) {
      this.checkForExtension();
    } else if (this.onboardingPage == 9) {
      this.checkUserForFirstSalesPlatform();
    } else if (this.onboardingPage == 10) {
      this.checkForExtension();
      this.checkPlatformAuth();
    } else if (this.onboardingPage == 11) {
      this.getSyncStatus();
      this.checkUserForFirstSalesPlatform();
      this.checkForExtension();
      this.sendAppleProviders();
      this.sendPlatformsToExtension();
      this.waitingToStartSync = true;
    }
  }

  private sendAppleProviders() {
    if (this.appleProviderIds && this.appleProviderIds.length) {
      this.websocketService.sendMessage('EXTENSION', 'SET_ITUNES_PROVIDERS', this.appleProviderIds.filter((appleProvider) => {
        return appleProvider.selected;
      }).map((appleProvider) => {
        return appleProvider.providerId.toString();
      }));
    }
  }

  private getAppleProviders() {
    this.websocketService.sendMessage('EXTENSION', 'GET_AVAIL_ITUNES_PROVIDERS');
  }

  private getSyncStatus() {
    this.websocketService.sendMessage('EXTENSION', 'GET_SYNC_UPDATE');
  }

  private startSync() {
    this.waitingToStartSync = false;
    this.websocketService.sendMessage('EXTENSION', 'INIT_SYNC');
  }

  public selectedASalesPlatform() {
    for (let salesKey in this.onboardingIntent.sales) {
      if (this.onboardingIntent.sales[salesKey]) {
        return true;
      }
    }
    return false;
  }

  private sendSelectedPlatforms() {
    this.userService.sendOnboardingPlatforms(this.onboardingIntent);
  }

  public async installExtension() {
    this.continue(10);
  }

  public checkPlatformAuth() {
    if (this.clientPeers['EXTENSION']) {
      let salesPlatformsToSync = [];
      if (this.onboardingIntent.sales.amazonKdp) {
        this.loginStates.sales.amazonKdp = {
          state: 'checking',
          friendlyState: 'Checking'
        };
        salesPlatformsToSync.push('amazonKdp');
      } else {
        this.loginStates.sales.amazonKdp = undefined;
      }
      if (this.onboardingIntent.sales.appleBooks) {
        this.loginStates.sales.appleBooks = {
          state: 'checking',
          friendlyState: 'Checking'
        };
        salesPlatformsToSync.push('appleBooks');
      } else {
        this.loginStates.sales.appleBooks = undefined;
      }
      if (this.onboardingIntent.sales.barnesAndNoble) {
        this.loginStates.sales.barnesAndNoble = {
          state: 'checking',
          friendlyState: 'Checking'
        };
        salesPlatformsToSync.push('barnesAndNoble');
      } else {
        this.loginStates.sales.barnesAndNoble = undefined;
      }
      if (this.onboardingIntent.sales.draft2digital) {
        this.loginStates.sales.draft2digital = {
          state: 'checking',
          friendlyState: 'Checking'
        };
        salesPlatformsToSync.push('draft2digital');
      } else {
        this.loginStates.sales.draft2digital = undefined;
      }
      if (this.onboardingIntent.sales.kobo) {
        this.loginStates.sales.kobo = {
          state: 'checking',
          friendlyState: 'Checking'
        };
        salesPlatformsToSync.push('kobo');
      } else {
        this.loginStates.sales.kobo = undefined;
      }
      this.websocketService.sendMessage('EXTENSION', 'GET_SYNC_AUTH_STATUS', {
        platforms: salesPlatformsToSync
      });

      this.numSelectedSalesPlatforms = salesPlatformsToSync.length;
    } else {
      setTimeout(() => {
        this.checkPlatformAuth();
      }, 1000);
    }
  }

  private setNumAuthedSalesPlatforms() {
    let numAuthedSalesPlatforms = 0;
    if (this.loginStates.sales.amazonKdp && this.loginStates.sales.amazonKdp.state == 'loggedIn') {
      numAuthedSalesPlatforms++;
    }
    if (this.loginStates.sales.appleBooks && this.loginStates.sales.appleBooks.state == 'loggedIn') {
      numAuthedSalesPlatforms++;
    }
    if (this.loginStates.sales.barnesAndNoble && this.loginStates.sales.barnesAndNoble.state == 'loggedIn') {
      numAuthedSalesPlatforms++;
    }
    if (this.loginStates.sales.draft2digital && this.loginStates.sales.draft2digital.state == 'loggedIn') {
      numAuthedSalesPlatforms++;
    }
    if (this.loginStates.sales.googleBooks && this.loginStates.sales.googleBooks.state == 'loggedIn') {
      numAuthedSalesPlatforms++;
    }
    if (this.loginStates.sales.kobo && this.loginStates.sales.kobo.state == 'loggedIn') {
      numAuthedSalesPlatforms++;
    }
    this.numAuthedSalesPlatforms = numAuthedSalesPlatforms;
  }

  public checkForExtension() {
    this.websocketService.sendMessage('HOST', 'GET_PEERS');
  }

  private sendPlatformsToExtension() {
    if (this.clientPeers['EXTENSION']) {
      this.websocketService.sendMessage('EXTENSION', 'CHANGE_SYNC_SETTINGS', {
        amazonKdp: this.loginStates.sales.amazonKdp && this.loginStates.sales.amazonKdp.state == 'loggedIn',
        appleBooks: this.loginStates.sales.appleBooks && this.loginStates.sales.appleBooks.state == 'loggedIn',
        barnesAndNoble: this.loginStates.sales.barnesAndNoble && this.loginStates.sales.barnesAndNoble.state == 'loggedIn',
        draft2digital: this.loginStates.sales.draft2digital && this.loginStates.sales.draft2digital.state == 'loggedIn',
        kobo: this.loginStates.sales.kobo && this.loginStates.sales.kobo.state == 'loggedIn',
      });
    } else {
      setTimeout(() => {
        this.sendPlatformsToExtension();
      }, 1000);
    }
  }

  public installDesktop() {
    this.onboardingPage = 9;
  }

  public loginWithAmazon() {
    const options: any = {}
    options.scope = 'profile advertising::campaign_management';
    options.scope_data = {
      'profile': { 'essential': true },
      'advertising::campaign_management': { 'essential': true }
    };
    options.response_type = 'code';
    amazon.Login.authorize(options, (res: any) => {
      if (res.error) {
        console.error(res.error);
        this.snackbarService.openSnackBar('Could not connect Amazon.', null, 5000);
        return;
      }
      this.userService.completeAmazonAuth(res.code).then(() => {
        this.snackbarService.openSnackBar('Amazon Connected!', null, 5000);
        this.continue();
        return;
      }).catch(() => {
        this.snackbarService.openSnackBar('Could not connect Amazon.', null, 5000);
        return;
      })
    });
  }

  

  public initAuth(platform: string) {
    this.websocketService.sendMessage('EXTENSION', 'INIT_SYNC_EXTERNAL_AUTH', {
      platform: platform
    });
  }

  public kenpRateFormChange() {
    this.setDefaultChildInputValues();
  }

  /**
   * Sets default values for all inputs that
   * are controlled by another input
   */
  private setDefaultChildInputValues() {
    switch (this.kenpRateForm.controls.estimatedKenpRateType.value) {
      case 'MOST_RECENT':
        if (!this.kenpRateForm.controls['customGlobalRate'].valid) {
          this.kenpRateForm.controls['customGlobalRate'].setValue(this.userSettings.kenpRate.customGlobalRate);
        }
        if (!this.kenpRateForm.controls['kenpAssumeIncreaseRate'].valid) {
          this.kenpRateForm.controls['kenpAssumeIncreaseRate'].setValue(this.userSettings.kenpRate.kenpAssumeIncreaseRate || 10);
        }
        if (!this.kenpRateForm.controls['kenpAssumeDecreaseRate'].valid) {
          this.kenpRateForm.controls['kenpAssumeDecreaseRate'].setValue(this.userSettings.kenpRate.kenpAssumeDecreaseRate || 10);
        }
        break;
      case 'CUSTOM_GLOBAL_RATE':
        if (!this.kenpRateForm.controls['kenpAssumeIncreaseRate'].valid) {
          this.kenpRateForm.controls['kenpAssumeIncreaseRate'].setValue(this.userSettings.kenpRate.kenpAssumeIncreaseRate || 10);
        }
        if (!this.kenpRateForm.controls['kenpAssumeDecreaseRate'].valid) {
          this.kenpRateForm.controls['kenpAssumeDecreaseRate'].setValue(this.userSettings.kenpRate.kenpAssumeDecreaseRate || 10);
        }
        break;
      case 'CUSTOM_MARKETPLACE_RATES':
        if (!this.kenpRateForm.controls['customGlobalRate'].valid) {
          this.kenpRateForm.controls['customGlobalRate'].setValue(this.userSettings.kenpRate.customGlobalRate);
        }
        if (!this.kenpRateForm.controls['kenpAssumeIncreaseRate'].valid) {
          this.kenpRateForm.controls['kenpAssumeIncreaseRate'].setValue(this.userSettings.kenpRate.kenpAssumeIncreaseRate || 10);
        }
        if (!this.kenpRateForm.controls['kenpAssumeDecreaseRate'].valid) {
          this.kenpRateForm.controls['kenpAssumeDecreaseRate'].setValue(this.userSettings.kenpRate.kenpAssumeDecreaseRate || 10);
        }
        break;
      case 'ASSUME_INCREASE':
        if (!this.kenpRateForm.controls['customGlobalRate'].valid) {
          this.kenpRateForm.controls['customGlobalRate'].setValue(this.userSettings.kenpRate.customGlobalRate);
        }
        if (!this.kenpRateForm.controls['kenpAssumeDecreaseRate'].valid) {
          this.kenpRateForm.controls['kenpAssumeDecreaseRate'].setValue(this.userSettings.kenpRate.kenpAssumeDecreaseRate || 10);
        }
        break;
      case 'ASSUME_DECREASE':
        if (!this.kenpRateForm.controls['customGlobalRate'].valid) {
          this.kenpRateForm.controls['customGlobalRate'].setValue(this.userSettings.kenpRate.customGlobalRate);
        }
        if (!this.kenpRateForm.controls['kenpAssumeIncreaseRate'].valid) {
          this.kenpRateForm.controls['kenpAssumeIncreaseRate'].setValue(this.userSettings.kenpRate.kenpAssumeIncreaseRate || 10);
        }
        break;
    }
  }

  public saveKenpRateSetting() {
    if (this.kenpRateForm.valid) {

      this.userService.putSettings({
        kenpRate: {
          customGlobalRate: this.kenpRateForm.value.customGlobalRate,

          customMarketplaceRates: {
            US: this.kenpRateForm.value.customMarketplaceRateUS,
            GB: this.kenpRateForm.value.customMarketplaceRateGB,
            DE: this.kenpRateForm.value.customMarketplaceRateDE,
            FR: this.kenpRateForm.value.customMarketplaceRateFR,
            ES: this.kenpRateForm.value.customMarketplaceRateES,
            IT: this.kenpRateForm.value.customMarketplaceRateIT,
            NL: this.kenpRateForm.value.customMarketplaceRateNL,
            JP: this.kenpRateForm.value.customMarketplaceRateJP,
            IN: this.kenpRateForm.value.customMarketplaceRateIN,
            CA: this.kenpRateForm.value.customMarketplaceRateCA,
            BR: this.kenpRateForm.value.customMarketplaceRateBR,
            MX: this.kenpRateForm.value.customMarketplaceRateMX,
            AU: this.kenpRateForm.value.customMarketplaceRateAU,
          },

          estimatedKenpRateType: this.kenpRateForm.value.estimatedKenpRateType,
          kenpAssumeDecreaseRate: this.kenpRateForm.value.kenpAssumeDecreaseRate,
          kenpAssumeIncreaseRate: this.kenpRateForm.value.kenpAssumeIncreaseRate
        }
      }).then(() => { }, () => { });
    }
  }

  public setCurrency(currency: 'USD' | 'EUR' | 'CAD' | 'GBP' | 'INR' | 'JPY' | 'MXN' | 'BRL' | 'AUD' | 'PLN' | 'CNY' | 'EGP' | 'SAR' | 'SGD' | 'SEK' | 'TRY' | 'AED') {
    this.userService.putSettings({
      currency: currency
    }).then(() => { }, () => { });
  }

  public loginWithFacebook(rerequest?: boolean) {
    this.userService.checkForFacebookConnection().then((result) => {
      // console.log(result);
    });
    this.authService.connectFacebook(rerequest).then(() => {
      console.log('logged in');
      this.facebookMissingPermissions = false;
      this.userService.getAndStoreProfile();
    }).catch((err) => {
      if (err == 'MISSING_PERMISSIONS') {
        this.facebookMissingPermissions = true;
      } else {
        this.facebookMissingPermissions = false;
        this.snackbarService.openSnackBar('Error connecting Facebook', null, 3000);
      }
    });
  }

  private pullAdAccounts(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.adsService.pullFacebookAdAccounts().then((data) => {
        this.adAccounts = data;
        resolve();
      }, reject);
    })
  }

  public getAlreadySelectedAdAccounts(user: User) {
    let alreadySelectedAdAccounts = [];
    for (let selectedAdAccount of user.facebookSelectedAdAccounts) {
      alreadySelectedAdAccounts.push(selectedAdAccount.account_id);
    }

    this.adAccounts = this.adAccounts.map((adAccount) => {
      if (alreadySelectedAdAccounts.includes(adAccount.account_id)) {
        return {
          ...adAccount,
          selected: true
        };
      } else {
        return adAccount;
      }
    });

    this.countSelectedAdAccounts();
  }

  public toggleAdAccount(index) {
    this.adAccounts[index].selected = !this.adAccounts[index].selected;
    this.countSelectedAdAccounts();
  }

  private countSelectedAdAccounts(): number {
    let count = this.getSelectedAdAccounts().length;
    this.numSelectedAdAccount = count;
    return count;
  }

  private getSelectedAdAccounts(): Array<FacebookAdAccount> {
    if (this.adAccounts) {
      return this.adAccounts.filter((adAccount) => {
        return adAccount.selected;
      });
    } else {
      return [];
    }
  }

  public selectAccount() {
    let selectedAdAccounts = this.getSelectedAdAccounts();
    this.adsService.selectFacebookAdAccount(selectedAdAccounts).then(() => {
      this.continue();
    }, () => {
      this.continue();
    });
  }

  public scrollToSection(sectionSelector) {
    document.querySelector(sectionSelector).scrollIntoView();
  }

  private updateIntercomOnbaordingStep(finished?: boolean) {
    let stepText;

    let step = this.onboardingPage;
    if (finished) {
      step = 12;
    }

    switch (step) {
      case 1:
        stepText = 'SELECT_PLATFORMS';
        break;
      case 2:
        stepText = 'VERIFY_EMAIL';
        break;
      case 3:
        stepText = 'TERMS';
        break;
      case 5:
        stepText = 'SELECT_CURRENCY';
        break;
      case 6:
        stepText = 'CONNECT_FACEBOOK';
        break;
      case 7:
        stepText = 'CONNECT_AMAZON_ADS';
        break;
      case 8:
        stepText = 'INSTALL';
        break;
      case 9:
        stepText = 'DOWNLOAD_DESKTOP_APP';
        break;
      case 10:
        stepText = 'EXTENSION_AUTH';
        break;
      case 11:
        stepText = 'EXTENSION_SYNC';
        break;
      case 12:
        stepText = 'FINISHED';
        break;
    }

    if (stepText) {
      (window as any).Intercom("update", {
        email: this.user.email,
        user_id: this.user._id,
        name: this.user.name,

        'Onboarding Step': stepText
      });
    }
  }

  public sendVerificationEmail() {
    this.userService.sendVerificationEmail().then(() => {
      this.snackbarService.openSnackBar('Verification email sent!', null, 1000);
    }, () => {
      // this.sendingEmail = false;
    });
  }

  public onLoad($event) {
    this.showAgree = true;
  }

  public onError($event) {

  }

  private checkUserForFirstSalesPlatform() {
    this.userService.checkForFirstSalesSourceSync().then(() => {
      this.showFinish = true;
    });
  }

  public finishOnboarding() {
    if (this.showFinish) {
      this.userService.finishOnboarding(this.onboardingPage).then(() => {
        this.updateIntercomOnbaordingStep(true);
        if (this.returnTo) {
          this.router.navigate([this.returnTo]).then(() => { }, () => {
            this.router.navigate(['/', 'reports']);
          });
        } else {
          this.router.navigate(['/', 'reports']);
        }
      }, () => {
        this.onboardingPage = 1;
        this.doPageActions();
      });
    }
  }

  public acceptAgencyInvite() {
    this.sharedAccessService.respondToSharedAccess(this.agencyInvite._id, true, this.agencyInvite.requestedPermissions).then(() => {
      this.continue();
    });
  }

  public declineAgencyInvite() {
    this.sharedAccessService.respondToSharedAccess(this.agencyInvite._id, false, this.agencyInvite.requestedPermissions).then(() => {
      this.continue();
    });
  }
}

export type LoginState = {
  state: 'checking' | 'loggedIn' | 'loggedOut';
  friendlyState: 'Checking' | 'Logged In' | 'Logged Out';
};

const INCOME_SOURCES = ['amazonKdp', 'barnesAndNoble', 'appleBooks', 'draft2digital', 'kobo', 'manualIncome'] as const;
type IncomeSources = typeof INCOME_SOURCES[number];
const EXPENSE_SOURCES = ['amazonAds', 'facebook', 'manualExpenses'] as const;
type ExpenseSources = typeof EXPENSE_SOURCES[number];