import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpXsrfTokenExtractor, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import * as fromStore from '../state';
import { Logout } from '../routes/auth/actions/auth.actions';
import { Observable } from 'rxjs';
import { tap, take, mergeMap } from 'rxjs/operators';
import { AuthService } from '../routes/auth/auth.service';
import { SnackbarService } from '../utilities/snackbar.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private interceptorBypass: Array<string> = [
    '/auth/login'
  ]

  constructor(
    private router: Router,
    private store: Store<fromStore.State>,
    private authService: AuthService,
    private snackbarService: SnackbarService,
    private tokenExtractor: HttpXsrfTokenExtractor,
    private cookieService: CookieService,
    private http: HttpClient
  ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    let xsrfToken = this.tokenExtractor.getToken();
    const xsrfDateStr = localStorage.getItem('xsrfDate')
    let xsrfDate = parseInt(xsrfDateStr);

    if (!xsrfDateStr || (xsrfDate + 23*60*60*1000) < Date.now()) {
      xsrfToken = null;
    }
    request = request.clone({
      withCredentials: true
    });

    if (xsrfToken !== null) {
      request = request.clone({
        setHeaders: {
          'X-XSRF-TOKEN': xsrfToken
        }
      });
    }

    if (xsrfToken === null && !request.url.endsWith('handshake')) {
      return this.http.get(environment.apiUrl + 'handshake').pipe(take(1), mergeMap((res) => {
        localStorage.setItem('xsrfDate', new Date().getTime().toString());
        xsrfToken = this.tokenExtractor.getToken();
        request = request.clone({
          withCredentials: true
        });

        if (xsrfToken !== null) {
          request = request.clone({
            setHeaders: {
              'X-XSRF-TOKEN': xsrfToken
            }
          });
        }
        return this.handleRequest(request, next);
      }));
    } else {
      return this.handleRequest(request, next);
    }
  }

  private handleRequest(request, next): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {

    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.error && err.error.msg && err.error.msg.toLowerCase().includes('csrf')) {
          console.error('CSRF error');
          return;
        }
        if (!window.location.pathname.includes('login')) {
          switch (err.status) {
            case 516:
              break;
            case 412:
              break;
            case 402:
              this.router.navigate(['/account/my-subscription']);
              break;
            case 401:
              this.authService.logout();
              console.log(`API TRACE ID: ${err.headers.get('API-Trace-ID')}`);
              break;
            case 429:
              this.snackbarService.openSnackBar("You're trying that too many times.", 'Dismiss', 0, () => { });
              break;
            default:
              console.log(`API TRACE ID: ${err.headers.get('API-Trace-ID')}`);
              console.error(err);
              // if (!err.error.hideOnRoute || !err.error.hideOnRoute.includes(this.router.url)) {
              //   this.snackbarService.openSnackBar(err.error.msg || 'An unknown error has occurred.', err.error.linkCopy || 'Dismiss', 0, () => {
              //     if (err.error.link) {
              //       this.router.navigate([err.error.link]);
              //     }
              //   });
              // }
              break;
          }
        }
      }
    }));
  }
}
