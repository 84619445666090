import { QuickStatsActions, QuickStatsActionTypes } from '../actions/quick-stats.actions';
import { Action } from '@ngrx/store';

export interface State {
  syncDate: Date;
  today: {
    profit: number;
    royalty: number;
    spend: number;
    pages: number;
    units: number;
  }
  yesterday: {
    profit: number;
    royalty: number;
    spend: number;
    pages: number;
    units: number;
  }
}

export const initialState: State = {
  syncDate: null,
  today: {
    profit: null,
    royalty: null,
    spend: null,
    pages: null,
    units: null
  },
  yesterday: {
    profit: null,
    royalty: null,
    spend: null,
    pages: null,
    units: null
  }
};

export function reducer(state = initialState, action: QuickStatsActions): State {
  switch (action.type) {
    case QuickStatsActionTypes.UpdateQuickStats:
      return {
        ...state,
        syncDate: action.payload.syncDate,
        today: action.payload.today,
        yesterday: action.payload.yesterday
      };

    default:
      return state;
  }
}

export const selectQuickStats = (state: State) => state;