import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { delayWhen, filter, retryWhen, tap } from 'rxjs/operators';
import { Observable, timer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  private socket$: WebSocketSubject<Message>;

  constructor() { }

  public connect(cfg: { reconnect: boolean } = { reconnect: false }) {
    if (!this.socket$ || this.socket$.closed) {
      this.socket$ = webSocket({
        url: environment.wssHost,
        closeObserver: {
          next: () => {
          }
        },
      });
    }
  }

  private reconnect(observable: Observable<any>): Observable<any> {
    return observable.pipe(retryWhen(errors => errors.pipe(
      delayWhen(_ => timer(1000)))));
  }

  public listenForAction(messageAction: MessageAction): Observable<Message> {
    this.connect();
    return this.socket$.pipe(filter((message: WebsocketMessage) => { return message.action === messageAction }),tap(() => { }, (error) => { console.error(error) }, () => { }),this.reconnect);
  }

  public sendMessage(destination: ClientType, action: MessageAction, data?: any) {
    const message: Message = {
      src: 'WEB_APP',
      dst: destination,
      action: action,
      data: data
    };
    this.socket$.next(message);
  }
}

const MESSAGE_ACTIONS = [
  'INIT_SYNC',
  'GET_SYNC_UPDATE',
  'SYNC_UPDATE',
  'CHANGE_SYNC_SETTINGS',
  'GET_SYNC_SETTINGS',
  'SYNC_SETTINGS',
  'GET_SYNC_AUTH_STATUS',
  'SYNC_AUTH_STATUS',
  'INIT_SYNC_EXTERNAL_AUTH',
  'DISPLAY_MSG',
  'GET_PEERS',
  'PEERS',
  'GET_AVAIL_ITUNES_PROVIDERS',
  'AVAIL_ITUNES_PROVIDERS',
  'SET_ITUNES_PROVIDERS'
] as const;
type MessageAction = typeof MESSAGE_ACTIONS[number];

type WebsocketMessage = {
  action: MessageAction;
}

const CLIENT_TYPES = ['WEB_APP', 'EXTENSION', 'DA', 'MOBILE', 'HOST'] as const;
type ClientType = typeof CLIENT_TYPES[number];

interface Message {
  src: ClientType;
  dst: ClientType | 'ALL';
  action: MessageAction;
  data: any;
}