import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataLayerCustomDimensionsService {

  private currentSet: FullCustomDimensionsSet;

  constructor() {
    this.generateEmptyDimensionsSet();
  }

  set dimesnions(someDimensions: PartialCustomDimensionsSet) {
    Object.keys(DataLayerCustomDimensions).map(key => DataLayerCustomDimensions[key])
    .forEach(key => {
      this.currentSet[key] = someDimensions[key];
    });
  }

  public trigger() {
    (window as any).dataLayer.push(this.currentSet);
  }

  private generateEmptyDimensionsSet() {
    this.currentSet = {
      [DataLayerCustomDimensions.SubscriptionType]: undefined,
      [DataLayerCustomDimensions.FreeTrial]: undefined,
      [DataLayerCustomDimensions.HasSubscription]: undefined,
      [DataLayerCustomDimensions.SubscriptionEnd]: undefined,
      [DataLayerCustomDimensions.Cancelled]: undefined,
      [DataLayerCustomDimensions.InvitedByCode]: undefined,
      [DataLayerCustomDimensions.NumFacebookAdAccounts]: undefined,
      [DataLayerCustomDimensions.UserJoined]: undefined,
      [DataLayerCustomDimensions.KdpLastSync]: undefined,
      [DataLayerCustomDimensions.AmazonAdvertisingLastSync]: undefined,
      [DataLayerCustomDimensions.ConnectedKdp]: undefined,
      [DataLayerCustomDimensions.ConnectedAmazonAdvertising]: undefined,
      [DataLayerCustomDimensions.ConnectedFacebook]: undefined,
      [DataLayerCustomDimensions.NumBooks]: undefined
    }
  }
  
}

export enum DataLayerCustomDimensions {
  SubscriptionType = 'subscriptionType',
  FreeTrial = 'freeTrial',
  HasSubscription = 'hasSubscription',
  SubscriptionEnd = 'subscriptionEnd',
  Cancelled = 'cancelled',
  InvitedByCode = 'invitedByCode',
  NumFacebookAdAccounts = 'numFacebookAdAccounts',
  UserJoined = 'userJoined',
  KdpLastSync = 'kdpLastSync',
  AmazonAdvertisingLastSync = 'amazonAdvertisingLastSync',
  ConnectedKdp = 'connectedKdp',
  ConnectedAmazonAdvertising = 'connectedAmazonAdvertising',
  ConnectedFacebook = 'connectedFacebook',
  NumBooks = 'numBooks'
}

export type FullCustomDimensionsSet = {
  [key in DataLayerCustomDimensions]: string | undefined
};

export type PartialCustomDimensionsSet = { [key: string]: string };