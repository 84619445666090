import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromStore from './state';
import { CheckCachedAuth } from './routes/auth/actions/auth.actions';
import { filter, take, map, mergeMap, catchError, tap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import * as LogRocket from 'logrocket';
import { Observable, fromEvent, merge } from 'rxjs';
import { UniversalAnalyticsService } from './utilities/universal-analytics.service';
import { DateService } from './utilities/date.service';
import { WebsocketService } from './utilities/websocket.service';
import { SnackbarService } from './utilities/snackbar.service';


if (!document.cookie.includes('adminUser=true;')) {
  try {
    // LogRocket config
    if (environment.domain === 'publishwide.com') {
      LogRocket.init('lozz5m/publishwide-production', {
        network: {
          requestSanitizer: function (request: any) {
            request.headers['Authorization'] = null; // remove auth token

            if (request.body && request.body.indexOf('password') !== -1) {
              request.body = null; // if the word 'password' is in the body then don't log the body
            }
            return request;
          }
        }
      });

    } else if (environment.domain.indexOf('staging') !== -1) {
      LogRocket.init('lozz5m/publishwide-staging', {
        network: {
          requestSanitizer: function (request: any) {
            request.headers['Authorization'] = null; // remove auth token

            if (request.body && request.body.indexOf('password') !== -1) {
              request.body = null; // if the word 'password' is in the body then don't log the body
            }
            return request;
          }
        }
      });
    }
    // END LogRocket config
  } catch (err) {
    console.error(err);
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'publishWide';

  public lightMode: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<fromStore.State>,
    private titleService: Title,
    private universalAnalyticsService: UniversalAnalyticsService,
    private dateService: DateService,
    private websocketService: WebsocketService,
    private snackbarService: SnackbarService
  ) {
    this.lightMode = false;
  }

  ngOnInit() {
    this.bindKeypressEvent().subscribe(($event: KeyboardEvent) => this.onKeyPress($event));

    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        map(() => {
        let route = this.route.firstChild;
        let child = route;

        while (child) {
          if (child.firstChild) {
            child = child.firstChild;
            route = child;
          } else {
            child = null;
          }
        }

        return route;
      }),
      mergeMap(route => route.data))
      .subscribe((data) => {
        let title = data.title ? data.title + ' | PublishWide' : 'PublishWide';
        this.titleService.setTitle(title);

        // update Intercom
        (window as any).Intercom("update", {last_request_at: (new Date()).getTime()/1000});

        this.store.select(fromStore.selectUser).pipe(take(1)).subscribe((user) => {

          // send analytics pageview
          this.store.select(fromStore.selectBooks).pipe(take(1)).subscribe((books) => {
            let someDimensions = user ? {
              subscriptionType: user.subscription ? user.subscription.subscriptionType : undefined,
              freeTrial: user.subscription ? user.subscription.freeTrial.toString() : undefined,
              hasSubscription: user.subscription ? user.subscription.hasSubscription.toString() : undefined,
              subscriptionEnd: this.dateService.dateToAnalyticsString(user.subscription.periodEnd),
              cancelled: user.subscription && typeof user.subscription.cancelled !== 'undefined' ? user.subscription.cancelled.toString() : undefined,
              invitedByCode: user.invitedByCode,
              numFacebookAdAccounts: user.facebookSelectedAdAccounts ? user.facebookSelectedAdAccounts.length.toString() : undefined,
              userJoined: this.dateService.dateToAnalyticsString(user.createdAt),
              kdpLastSync: user.syncDates ? this.dateService.dateToAnalyticsString(user.syncDates.kdpStart) : undefined,
              amazonAdvertisingLastSync: user.syncDates ? this.dateService.dateToAnalyticsString(user.syncDates.amazonAdvertisingStart) : undefined,
              connectedKdp: user.connectedPlatforms && typeof user.connectedPlatforms.amazonKdp !== 'undefined' ? user.connectedPlatforms.amazonKdp.toString() : undefined,
              connectedAmazonAdvertising: user.connectedPlatforms && typeof user.connectedPlatforms.amazonAds !== 'undefined' ? user.connectedPlatforms.amazonAds.toString() : undefined,
              connectedFacebook: user.connectedPlatforms && typeof user.connectedPlatforms.facebook !== 'undefined' ? user.connectedPlatforms.facebook.toString() : undefined,
              numBooks: books && books.books ? books.books.length.toString() : undefined
            } : {};
            this.universalAnalyticsService.pageView(title, someDimensions);
          })
        });
      });

    setTimeout(() => {
      this.store.dispatch(new CheckCachedAuth());
    }, 0);

    const onMobile = function () {
      let width = window.innerWidth;
      return width < 600;
    };

    this.store.select(fromStore.selectUser).subscribe((user) => {
      if (user) {
        this.websocketService.connect();

        this.websocketService.listenForAction('DISPLAY_MSG').subscribe((message) => {
          this.snackbarService.openSnackBar(message.data.message, null, 5000);
        });

        if (!document.cookie.includes('adminUser=true;')) {
          LogRocket.identify(user._id, {
            email: user.email
          });
          try {
            // Intercom Config
            (window as any).Intercom("boot", {
              horizontal_padding: 20,
              vertical_padding: 80,
              hide_default_launcher: onMobile() || user.subscription && user.subscription.subscriptionType == '1002',

              app_id: "q5k4aags",
              email: user.email,
              user_id: user._id,
              user_hash: user.intercomVerification,
              name: user.name,
              created_at: user.createdAt,
              'Email Verified': user.emailVerified,
              'Onboarded': user.onboarded,
              'Connected KDP': user.connectedPlatforms ? user.connectedPlatforms.amazonKdp : undefined,
              'Connected Amazon Ads': user.connectedPlatforms ? user.connectedPlatforms.amazonAds : undefined,
              'Connected Facebook': user.connectedPlatforms ? user.connectedPlatforms.facebook : undefined,
              'Connected Barnes & Noble': user.connectedPlatforms ? user.connectedPlatforms.barnesAndNoble : undefined,
              'Connected Draft2Digital': user.connectedPlatforms ? user.connectedPlatforms.draft2digital : undefined,
              'Has Subscription': user.subscription ? user.subscription.hasSubscription : undefined,
              'Free Trial': user.subscription ? user.subscription.freeTrial : undefined,
              'Subscription Ends': user.subscription ? new Date(user.subscription.periodEnd) : undefined,
              'Invited By Code': user.invitedByCode,
            });
            // END Intercom Config
          } catch(err) {
            console.error(err);
          }
        }

        this.lightMode = user.settings.theme === 1;
      } else {
        if (!document.cookie.includes('adminUser=true;')) {
          try {
            // Intercom Config
            (window as any).Intercom("boot", {
              horizontal_padding: 20,
              vertical_padding: 80,
              hide_default_launcher: onMobile || user.subscription && user.subscription.subscriptionType == '1002',
              

              app_id: "q5k4aags"
            });
            // END Intercom Config
          } catch (err) {
            console.error(err);
          }
        }
      }
    });
  }

  // light mode dark mode shortcut
  onKeyPress($event: KeyboardEvent) {
    if (($event.ctrlKey || $event.metaKey) && $event.keyCode == 77) {
      if (localStorage.themeShortcut && localStorage.themeShortcut === 'true') {
        this.lightMode = !this.lightMode;
      }
    }
  }

  private bindKeypressEvent(): Observable<KeyboardEvent> {
    const eventsType$ = [
      fromEvent(window, 'keypress'),
      fromEvent(window, 'keydown')
    ];
    // we merge all kind of event as one observable.
    return merge(...eventsType$)
      .pipe(
        // We map answer to KeyboardEvent, typescript strong typing...
        map(state => (state as KeyboardEvent))
      );
  }
}
