import { BooksActions, BooksActionTypes } from '../actions/books.actions';
import { Action } from '@ngrx/store';
import { Book } from '../models/Book.model';

export interface State {
  pullDate: Date,
  books: Array<Book>
}

export const initialState: State = {
  pullDate: null,
  books: null
};

export function reducer(state = initialState, action: BooksActions): State {
  switch (action.type) {
    case BooksActionTypes.PullBooksSuccessfull:
      return {
        ...state,
        pullDate: new Date(),
        books: action.payload
      };
    default:
      return state;
  }
}

export const selectBooks = (state: State) => state;