export const FACEBOOK_ACCOUNT_STATUS_OPTIONS = {
  '1': 'Active',
  '2': 'Disabled',
  '3': 'Unsettled',
  '7': 'Pending Risk Review',
  '8': 'Pending Settlement',
  '9': 'In Grace Period',
  '100': 'Pending Closure',
  '101': 'Closed',
  '201': 'Any Advice',
  '202': 'Any Closed'
}