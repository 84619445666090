import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'app-onboarding-nav',
  templateUrl: './onboarding-nav.component.html',
  styleUrls: ['./onboarding-nav.component.scss']
})
export class OnboardingNavComponent implements OnInit {

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit() {
  }

  public logout() {
    this.authService.logout();
  }

}
